import * as React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  OutlinedInput,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Radio,
  TextField,
  FormControl,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Moment from "moment";
// Sbackbar
import { Snackbar } from "@mui/material";
import * as myConstClass from "Constants";
import BasicDatePickerName from "componenets/Elements/BasicDatePicker/BasicDatePickerName";
import BasicSelectSifarnik from "componenets/Elements/BasicSelect/BasicSelectSifarnik";
import { useNavigate } from "react-router-dom";

import FileUploader from "componenets/Elements/FileUploader/FileUploader";
import { useUser } from "auth/useUser";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function NovaTemaForm() {
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  const user = useUser();
  const { id, korisnickoIme, ulogaID, lozinka_status } = user;

  let navigate = useNavigate();

  function snackbarClose(event) {
    setSnackbar(false);
  }

  const [valid, setValid] = useState(true);

  const [files, setFiles] = useState([]);

  const [inputValidation, setInputValidation] = useState({
    naslov: "",
    sifarnik_id: "",
    trajanje_teme: "",
  });

  const [data, setData] = useState({
    naslov: "",
    opis: "",
    datum_kreiranja: "",
    trajanje_teme: "",
    sifarnik_id: "",
    kategorijaIme: "",
    korisnik_id: "",
    dokumneti: "",
    realtime_podaci: false,
  });
  function checkRequiered() {
    let allGood = true;

    const newdata = { ...inputValidation };

    if (data.sifarnik_id == "") {
      allGood = false;
      newdata["sifarnik_id"] = "Ovo polje je obavezno";
    }
    if (data.naslov == "") {
      allGood = false;
      newdata["naslov"] = "Ovo polje je obavezno";
    }
    if (data.trajanje_teme == "" || data.trajanje_teme == null) {
      allGood = false;
      newdata["trajanje_teme"] = "Ovo polje je obavezno";
    }
    setInputValidation(newdata);
    return allGood;
  }

  function submit() {
    data.datum_kreiranja = Moment().format("YYYY-MM-DD");

    //TODO: provjeri jesu li sva obavezna polja popunjena

    if (checkRequiered()) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      fetch(myConstClass.SERVER_URL + "theme?user=" + id, requestOptions)
        .then((response) => {
          if (response.status != 200) {
            setSnackbarMessage("Doslo je do greske");
            setSeverity("error");
            setSnackbar(true);
          }
          return response.json();
        })
        .then(function (myJson) {
          let id = myJson[0].tema_id;
          uplodaFiles("tema", id);
        });
    }
  }

  const navigateTo = (pageLink: string, id: String) => {
    // Replacing " " (space) to "" empty space
    const res = pageLink.replace(/ /g, "");
    const themeLocationData = {
      urlName: data.kategorijaIme,
      subscription: "",
      theemID: id,
      icon: "",
    };

    navigate("../" + res, {
      replace: true,
      state: { themeID: id, themeLocationData: themeLocationData },
    });
  };

  function handleChange(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  function handleSelectChange(id, e, categoryName) {
    const newdata = data;
    newdata["sifarnik_id"] = id;
    newdata["kategorijaIme"] = categoryName;
    setData(newdata);
  }

  function handleValueChange(name, value) {
    const newdata = { ...data };
    newdata[name] = Moment(value).format("YYYY-MM-DD HH:MM:SS");
    setData(newdata);
  }

  function uplodaFiles(type, id) {
    if (files.length == 0) {
      return;
    }

    const fileData = new FormData();
    // adding all files that will be uploaded to server
    // for (var x = 0; x < files.length; x++) {
    //   fileData.append("myFiles", files[x]);
    // }
    for (let file of files) {
      fileData.append("myFiles", file);
    }

    const boundary = "----WebKitFormBoundaryAAAA";
    const requestOptions = {
      method: "POST", // HTTP method (GET, POST, etc.)
      mode: "no-cors" as RequestMode, // Request mode ('cors', 'no-cors', 'same-origin')

      body: fileData,
    };

    fetch(
      myConstClass.SERVER_URL +
        "fileManager/uploadmultiple?type=" +
        type +
        "&id=" +
        id,
      requestOptions
    ).then(function (response) {
      if (response.status == 200) {
        setSnackbarMessage("Nova tema je dodana");
        setSeverity("success");
        setSnackbar(true);

        setTimeout(() => {
          navigateTo(`../Tema`, `${id}`);
        }, 2000);
      } else {
        setSnackbarMessage("Doslo je do greske");
        setSeverity("error");
        setSnackbar(true);

        // Wait for 2 seconds

        //navigate to new theme
        // navi
      }
    });
    //TODO: Navigate na temu
  }

  function fileChangeUpdate(changedFiles) {

    setFiles(changedFiles);

    let documentNames = "";

    for (var x = 0; x < changedFiles.length; x++) {
      documentNames += changedFiles[x].name + ",";
    }
    const newdata = data;
    // newdata["files"] = changedFiles
    newdata["dokumenti"] = documentNames;
    setData(newdata);
  }

  const handleFileUploadError = (error) => {
    setSnackbarMessage("Doslo je do greske pri uploadu filova.");
    setSeverity("error");
    setSnackbar(true);
  };

  function validateRequieredText(e) {
    if (e.target.value == "") {
      const newdata = { ...inputValidation };
      newdata[e.target.id] = "Ovo polje je obavezno";
      setInputValidation(newdata);
    } else {
      const newdata = { ...inputValidation };
      newdata[e.target.id] = "";
      setInputValidation(newdata);
    }
  }

  function validateRequieredDatePicker(value, id) {
    if (value == "" || value == "Invalid Date" || value == null) {
      const newdata = { ...inputValidation };
      newdata[id] = "Ovo polje je obavezno";
      setInputValidation(newdata);
    } else {
      const newdata = { ...inputValidation };
      newdata[id] = "";
      setInputValidation(newdata);
    }
  }

  function validateRequieredSelect(value, id) {
    if (value == "" || value == null) {
      const newdata = { ...inputValidation };
      newdata[id] = "Ovo polje je obavezno";
      setInputValidation(newdata);
    } else {
      const newdata = { ...inputValidation };
      newdata[id] = "";
      setInputValidation(newdata);
    }
  }

  return (
    <>
      <Snackbar open={snackbar} autoHideDuration={3000} onClose={snackbarClose}>
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>

      {/* TODO: uncomnet BasicSelectSifarnik */}
      <Box
        // component="form"
        sx={{
          "& .MuiTextField-root": { m: 0, mb: 1, width: "100%" },
          p: 4,
        }}
        // noValidate
        // autoComplete="off"
      >
        <div>
          <Grid container spacing={15}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="naslov"
                label="Naslov"
                type="text"
                helperText={inputValidation.naslov}
                error={inputValidation.naslov != ""}
                value={data.naslov}
                onChange={(e) => {
                  handleChange(e);
                  validateRequieredText(e);
                }}
                onBlur={validateRequieredText}
              />

              {/* Third column */}
              {/* <Grid item xs={12} sx={{ m: 1 }}> */}
              <BasicSelectSifarnik
                id="sifarnik_id"
                onChange={handleSelectChange}
                name="Kategorija"
                tableName="codebook/name"
                dedaultValue={""}
                helperText={inputValidation.sifarnik_id}
                error={inputValidation.sifarnik_id != ""}
                onClose={validateRequieredSelect}
              />
              {/* </Grid> */}

              <Grid item xs={12} sx={{ mt: 2 }}>
                {/* <Item sx={{ m: 1 }}> */}
                <FormControl required fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Opis
                  </InputLabel>
                  <OutlinedInput
                    id="opis"
                    multiline
                    rows={5}
                    value={data.opis}
                    onChange={handleChange}
                    label="opis"
                  />
                </FormControl>
                {/* </Item> */}
              </Grid>
            </Grid>

            {/* Right part of the form */}
            <Grid item xs={6}>
              <Grid container>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="realtime_podaci"
                      value={data.realtime_podaci}
                      onChange={handleChange}
                    />
                  }
                  label="Live rezultati"
                />

                <Item>
                  <FormControl required fullWidth sx={{ mt: 1 }}>
                    <BasicDatePickerName
                      id="trajanje_teme"
                      onChange={handleValueChange}
                      name="aktivan do"
                      defaultValue={new Date()}
                      error={inputValidation.trajanje_teme != ""}
                      helperText={inputValidation.trajanje_teme}
                      onClose={validateRequieredDatePicker}
                    />
                  </FormControl>
                </Item>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={10} sx={{ mt: 4, ml: "-120px" }}>
                  <Item>
                    <Box sx={{ p: 2, border: "1px dashed grey" }}>
                      <FileUploader
                        fileChange={(e) => fileChangeUpdate(e)}
                        handleFileUploadError={handleFileUploadError}
                      />
                    </Box>
                  </Item>
                </Grid>
              </Grid>
              <Grid item sx={{ mt: 6, ml: 10 }}>
                <Item>
                  <Button onClick={submit} variant="contained">
                    Dodaj Temu
                  </Button>
                </Item>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
}
