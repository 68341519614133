import React from 'react';
import { Container, Card, CardHeader } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import BasicBreadcrumbs from 'componenets/Navigation/BasicBreadcrumbs/BasicBreadcrumbs';
import NovaTemaForm from 'componenets/Elements/Forms/NoviKorisnikForm/NovaTemaForm';


function DodajTemu() {

  return (
    <>
      <Container maxWidth="lg">
        <Card 
          sx={{
            '& .MuiTextField-root': { m: 1.5, width: '25ch'},
            // '& .MuiBox-root': { m: 1, width: '25ch' },
         
          }}
        // noValidate
        // autoComplete="off"
        >
          <CardHeader sx={{ bgcolor: '#086AE2', color: "white" }} title={"Dodavanje nove teme"} />


          <NovaTemaForm />
        </Card>
      </Container>
    </>
  );
}

export default DodajTemu;