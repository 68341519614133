import { useState, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Container from "@mui/material/Container";
import RepresentativeCard from "componenets/Elements/Card/RepresentativeCard";
import BasicBreadcrumbs from "componenets/Navigation/BasicBreadcrumbs/BasicBreadcrumbs";
import Grid from "@mui/material/Grid";
import {
  Box,
  IconButton,
  TextField,
  FormControl,
  Paper,
  styled,
} from "@mui/material";
import * as myConstClass from "Constants";
import { useNavigate } from "react-router-dom";
import { useUser } from "auth/useUser";
import RepresentativeTabs from "componenets/Elements/Tabs/RepresentativeTabs";
import PredstavnikTable from "componenets/Elements/Tables/PredstavnikTable/PredstavnikTable";
import PredstavnikTable2 from "componenets/Elements/Tables/PredstavnikTable/PredstavnikTable2";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

function OdobravanjePredstavnika() {
  const [representatives, setRepresentatives] = useState<any[]>([]);
  const user = useUser();
  const { id, korisnickoIme, ulogaID, lozinka_status } = user;

  let navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);

  const [representativeData, setRepresentativeData] = useState({
    userID: id,
    tabValue: 0,
    repSearch: "",
  });

  function updateRepresentatives() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(representativeData),
    };
    fetch(
      myConstClass.SERVER_URL + "representative/getPendingRepresentatives",
      requestOptions
    )
      .then((response) => response.json())
      .then(setRepresentatives);
  }

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(representativeData),
    };
    fetch(
      myConstClass.SERVER_URL + "representative/getPendingRepresentatives",
      requestOptions
    )
      .then((response) => response.json())
      .then(setRepresentatives);

    // TODO: pozovi query za aktivne ili odbijene zahtjeve
  }, [tabValue]);

  function handleTabChange(newValue) {
    const newdata = { ...representativeData };
    newdata["tabValue"] = newValue;
    setRepresentativeData(newdata);

    setTabValue(newValue);
  }

  return (
    <Container maxWidth="lg">
      <div className="wrapper">
        <h1>Odobravanje Predstavnika</h1>
        <BasicBreadcrumbs currentPage="Odobravanje Predstavnika" />

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <RepresentativeTabs tabChange={handleTabChange} />

          {/* <PredstavnikTable representativeData={representativeData} updateRepresentiveData={updateRepresentatives}/> */}

          <PredstavnikTable2
            representativeData={representativeData}
            updateRepresentiveData={updateRepresentatives}
          />

          {/* {representatives.map(representative =>
                        <Grid item xs={8}>
                            <RepresentativeCard representative={representative} updateRepresentiveData={updateRepresentatives} />
                        </Grid>
                    )} */}
        </Grid>
      </div>
    </Container>
  );
}

export default OdobravanjePredstavnika;
