import React, { useState, useEffect } from 'react'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import * as myConstClass from 'Constants';
import { CardActions, CardContent, TextField, Typography, Button, Box, Card, Grid, Divider, Switch, Container } from '@mui/material';

// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,

      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};




export function VerticalBarChart({ title, pitanje_id }) {

  const [labels, setLables] = useState(["January", "February", "March", "April", "May", "June", "July"]);
  const [values, setValues] = useState([20, 59, 80, 81, 56, 55, 40]);
  const [numberOfVotes, setNumberOfVotes] = useState(0);

  const data = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: [
          'rgba(255, 99, 132, 0.3)',
          'rgba(54, 162, 235, 0.3)',
          'rgba(255, 206, 86, 0.3)',
          'rgba(255, 127, 81, 0.3)',
          'rgba(75, 192, 192, 0.3)',
          'rgba(208, 0, 0, 0.3)',
          'rgba(153, 102, 255, 0.3)',

        ]
      }

    ],
  };

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + 'poll/results/multipleChoice?pitanje=' + pitanje_id)
      .then((response) => response.json())
      .then(data => {
        // setPitanja(data)

        // map naziv_odgovora u labels
        let newLables = data.map((user) => {
          return user.naziv_odgovora.trim();
        });
        setLables(newLables);

        let numVotes = 0;

        let newData = data.map((user) => {
          numVotes += parseInt(user.num)

          return user.num;
        });

        setNumberOfVotes(numVotes)

        setValues(newData)

      })

  }, []);
  return (
    <>
      {title}
      <br />
      Broj odgovora: {numberOfVotes}
      <Container maxWidth="sm">
        <Bar options={options} data={data} />
      </Container>
    </>
  );
}

export default VerticalBarChart;

