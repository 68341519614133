import * as React from "react";
import { useEffect, useState, forwardRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import Modal from "@mui/material/Modal";
import { PredstavnikZahtjev } from "model/PredstavnikZahtjev";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import * as myConstClass from "Constants";
import {
  TextField,
  IconButton,
  Grid,
  Box,
  Button,
  Avatar,
  Divider,
  FormControl,
} from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import SearchIcon from "@mui/icons-material/Search";
import { Snackbar, styled } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useUser } from "auth/useUser";

import { Buffer } from "buffer";
Buffer.from("anything", "base64");

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  //   numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  //   onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const user = useUser();

  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          key="tema_naslov"
          sortDirection={orderBy === "tema_naslov" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "tema_naslov"}
            direction={orderBy === "tema_naslov" ? order : "asc"}
            onClick={createSortHandler("tema_naslov")}
          >
            Tema
            {orderBy === "tema_naslov" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>

        <TableCell
          key="prezime"
          align="right"
          sortDirection={orderBy === "prezime" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "prezime"}
            direction={orderBy === "prezime" ? order : "asc"}
            onClick={createSortHandler("prezime")}
          >
            Predstavnik
            {orderBy === "prezime" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>

        <TableCell align="right">Datum zahtjeva</TableCell>
        <TableCell align="right">Status</TableCell>
        <TableCell align="right">Pregled prijave</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function PredstavnikTable2({
  representativeData,
  updateRepresentiveData,
}) {
  const [rows, setSifarnici] = useState<any[]>([]);
  const [update, setUpdate] = useState(true);

  // Snack bar states
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  const user = useUser();
  const { id, korisnickoIme, ulogaID, lozinka_status } = user;

  function snackbarClose(event) {
    setSnackbar(false);
  }
  const [searchRepData, setSearchRepData] = useState<any[]>([]);

  const [representatives, setRepresentatives] = useState<any[]>([]);
  const [representative, setRepresentative] = useState<PredstavnikZahtjev>();

  const [confirmationData, setConfirmationData] = useState({
    id: representative?.id,
    themeID: representative?.tema_naslov,
    value: "null",
    description: "",
    adminID: id,
    representative_mail: representative?.predstavnik_mail,
  });

  // sort functions and states

  // Modal states
  const [open, setOpen] = useState(false);
  const handleOpen = (row) => {
    setRepresentative(row);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [filterData, setFilterData] = useState({
    filteredValue: "",
  });

  const handleInputChange = (e) => {
    const newdata = { ...representativeData };
    newdata["repSearch"] = e.target.value;
    setSearchRepData(newdata);

    setFilterData({
      filteredValue: e.target.value,
    });
  };

  const getImage = (buffer) => {
    if (buffer != null) {
      const image_base64 = Buffer.from(buffer).toString();
      return image_base64;
    } else return "";
  };

  //myConstClass
  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(representativeData),
    };
    fetch(
      myConstClass.SERVER_URL + "representative/getPendingRepresentatives",
      requestOptions
    )
      .then((response) => response.json())
      .then(setRepresentatives);
    //   setConfirmationData(representativeData)
    // ispisi confirmation message
  }, [representativeData]);

  const [data, setData] = useState({
    id: 0,
    opis: "",
  });

  // TODO update it
  function searchFilter() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(searchRepData),
    };
    fetch(
      myConstClass.SERVER_URL + "representative/getPendingRepresentatives",
      requestOptions
    )
      .then((response) => response.json())
      .then(setRepresentatives);
  }

  function handleDate(date) {
    if (date == null) {
      return "";
    } else {
      let dateFormat = new Date(date);
      return `${dateFormat.getDate()}.${dateFormat.getMonth()}.${dateFormat.getFullYear()}`;
    }
  }

  function handleStatus(status) {
    if (status == null) {
      return "U obradi";
    } else if (status == 0) {
      return "Odbijen";
    } else {
      return "Prihvaćen";
    }
  }

  function handleConfirm() {
    updateRepresentative("1", "");
  }

  function handleReject() {
    // setRepresentativeData({
    //     id: representative?.id,
    //     themeID: representative?.tema_naslov,
    //     value: '1',
    //     description: '',
    //     adminID: id,
    //     representative_mail: representative?.predstavnik_mail
    // })
    // setRepresentativeData.toString();
    // OPEN MODULE FOR Rejection description
    updateRepresentative("0", representativeData.description);
  }

  function updateRepresentative(value, description) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: representative?.id,
        themeID: representative?.tema_naslov,
        value: value,
        description: description,
        adminID: id,
        representative_mail: representative?.predstavnik_mail,
      }),
    };

    fetch(
      myConstClass.SERVER_URL + "representative/updateRepresentativeSatus",
      requestOptions
    ).then((response) => {
      response.json();
      if (response.status == 200) {
        setSnackbarMessage("Satus zahtjeva je promijenjen.");
        setSeverity("success");
        setSnackbar(true);
        updateRepresentiveData();
      } else {
        setSnackbarMessage("Doslo je do greske");
        setSeverity("error");
        setSnackbar(true);
      }
    });
  }

  const avatarStyle = {
    width: "150px",
    height: "150px",
  };

  // Modal style
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "65%",
    height: "75%",
    bgcolor: "background.paper",
    border: "none !important",
    boxShadow: 24,
    p: 4,
  };

  //////////

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("tema");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  function getFile() {
    // uploads/representative_98/Status Report Example 1.pdf

    fetch(
      myConstClass.SERVER_URL +
        "fileManager/download?url=uploads/representative_98/Status Report Example 1.pdf"
    ).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "Example.pdf";
        a.click();
      });
    });
  }

  return (
    <>
      <Snackbar open={snackbar} autoHideDuration={3000} onClose={snackbarClose}>
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 1 }}
        spacing={2}
      >
        <Grid item>{/* <Item></Item> */}</Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 1 }}
            spacing={2}
          >
            <Grid item>
              {/* <Item> */}
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1.5, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <FormControl required fullWidth sx={{ m: 1 }}>
                  <TextField
                    id="filteredValue"
                    value={filterData.filteredValue}
                    onChange={handleInputChange}
                    label="Traži"
                    size="small"
                  />
                </FormControl>
              </Box>
              {/* </Item> */}
            </Grid>
            <IconButton
              sx={{ ml: 1, mt: 2 }}
              aria-label="search"
              onClick={searchFilter}
            >
              <SearchIcon style={{ fill: "black" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                //   numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {
                  //stableSort(representatives, getComparator(order, orderBy))

                  representatives
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      //   const isItemSelected = isSelected(row.ime.toString());
                      //   const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ minWidth: 90 }}
                          >
                            {row.tema_naslov}
                          </TableCell>
                          <TableCell align="right">
                            {row.ime} {row.prezime}
                          </TableCell>
                          <TableCell align="right">
                            {handleDate(row.datum_kreiranja)}
                          </TableCell>
                          <TableCell align="right">
                            {handleStatus(row.status)}
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            <Button
                              variant="contained"
                              color="success"
                              onClick={() => {
                                handleOpen(row);
                              }}
                            >
                              Pregled
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                }
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider></Divider>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ mb: 1 }}
              >
                Pregled predstavnika
              </Typography>
              <Divider />

              <Grid container direction="column" justifyContent="space-between">
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                    sx={{ mt: 2 }}
                  >
                    <Grid item sx={{ mb: 3 }} xs={6}>
                      <Avatar
                        sx={avatarStyle}
                        alt="representative image"
                        src={`data:image/png;base64,${getImage(
                          representative?.slika
                        )}`}
                      />
                      <br />
                      <Typography variant="body1" component="div">
                        {/* {theme.naslov} */}
                        Predstavnik: {representative?.ime}{" "}
                        {representative?.prezime}
                      </Typography>
                      <br />
                      <LinkedInIcon /> {representative?.linkedin_url}
                      <Typography sx={{ mb: 3, mt: 2 }}>
                        Biografija: Test
                        <br />
                        {representative?.biografija}
                      </Typography>
                      <Typography sx={{ mb: 3, mt: 2 }}>
                        Uplodani dokumenti
                        <br />
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: 360,
                            bgcolor: "background.paper",
                          }}
                        >
                          <List>
                            <ListItem disablePadding>
                              <ListItemButton onClick={getFile}>
                                <ListItemIcon>
                                  <AttachFileIcon />
                                </ListItemIcon>
                                <ListItemText primary="Inbox" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemButton>
                                <ListItemIcon>
                                  <AttachFileIcon />
                                </ListItemIcon>
                                <ListItemText primary="Inbox" />
                              </ListItemButton>
                            </ListItem>
                          </List>
                        </Box>
                      </Typography>
                    </Grid>

                    <Grid item sx={{ mb: 4 }} xs={5}>
                      Kategorija: {representative?.sifarnik_naziv}
                      <br />
                      Tema: {representative?.tema_naslov}
                      <br />
                      <br />
                      Datum odbijanja: {representative?.datum_odobrenja}
                      <br />
                      <br />
                      Razlog odbijanja: {representative?.razlog_odbijanja}
                      <br />
                      <TextField
                        disabled={!user.adminWrite}
                        sx={{ mt: 4 }}
                        fullWidth
                        id="description"
                        label="Razlog odbijanja"
                        multiline
                        onChange={handleInputChange}
                        rows={4}
                        helperText="U slučaju odbijanja zahtjeva predstavnika, molimo vas da upišete razlog odbijanja."
                        // defaultValue="Default Value"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ mt: 6 }}
                    >
                      <Grid item>
                        <Button
                          variant="outlined"
                          onClick={handleClose}
                          color="error"
                        >
                          Zatvori
                        </Button>
                      </Grid>
                      {user.adminWrite && (
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={handleConfirm}
                            sx={{ mr: 3 }}
                            color="success"
                          >
                            Potvrdi
                          </Button>
                          <Button
                            variant="outlined"
                            disabled={confirmationData.description.length == 0}
                            onClick={handleReject}
                            color="error"
                          >
                            Odbij
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Paper>
      </Box>
    </>
  );
}

// function useState<T>(arg0: never[]): [any, any] {
//     throw new Error('Function not implemented.');
// }
