import { useState, useEffect } from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select, SelectChangeEvent } from '@mui/material';
import * as myConstClass from 'Constants';

export default function BesicSelectZupanija({ name, tableName, setZupanija }) {
  const [zupanijaValue, setZupanijaValue] = useState('');
  const [selectData, setSelectData] = useState<any[]>([])

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + tableName)
      .then((response) => response.json())
      .then(setSelectData);
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setZupanijaValue(event.target.value as string);
    setZupanija(event.target.value as string);
  };

  return (
    <Box >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{name}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={zupanijaValue}
          label={name}
          onChange={handleChange}>
          {
            selectData.map((selectOption) => (
              <MenuItem key={selectOption.opis} value={selectOption.opis}>{selectOption.opis}</MenuItem>

            ))}

        </Select>
      </FormControl>
    </Box>
  );
}
