import React, { useState, useEffect, forwardRef } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import { Grid, Button, TextField, Input, Alert } from "@mui/material";
import Box from "@mui/material/Box";
import GenderSelectMini from "componenets/Elements/BasicSelect/GenderSelectMini";
import * as myConstClass from "Constants";
import BasicSelectMini from "componenets/Elements/BasicSelect/BasicSelectMini";
import DatePickerDefault from "componenets/Elements/BasicDatePicker/DatePickerDefault";
import BesicSelectZupanijaMini from "componenets/Elements/BasicSelect/BesicSelectZupanijaMini";
import CustomAutocompleteMini from "componenets/Elements/Autocomplete/AutocompleteMini";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useLocation } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  // textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const AlertSnackbar = forwardRef<HTMLDivElement, AlertProps>(
  function AlertSnackbar(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }
);

export default function UserPage(props) {
  const [isLoad, setIsLoad] = useState(true);
  const [confirm, setConfirm] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const user = useUser();
  const { token, setToken } = useToken();
  const { id, korisnickoIme, ulogaID, lozinka_status } = user;

  const [zupanija, setZupanija] = useState("");

  // Snack bar states
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  function snackbarClose(event) {
    setSnackbar(false);
  }
  const location = useLocation();

  const [redirect, setRedirect] = useState(location.state);

  const [userData, setUserData] = useState<any[]>([]);

  const [data, setData] = useState({
    id: id,
    ime: "",
    prezime: "",
    mail: "",
    spol: "",
    datume_rodjenja: "",
    bracni_status_id: "",
    obrazovanje_id: "",
    broj_osoba_kucanstvo: 0,
    zupanija: "",
    naselje: "",
    lokacija_id: "",
    zanimanje_id: "",
    vjeroispovjest_id: "",
    status: "TRUE",
    lozinka: "",
  });

  const [passData, setPassData] = useState({
    id: id,
    value: "",
  });

  const [pass, setPass] = useState({
    filedNew1: "",
    filedNew2: "",
  });

  function handleValueChange(name, value) {
    const newdata = { ...data };
    newdata[name] = value;
    setData(newdata);
  }

  function handleChange(e) {
    const newdata = { ...pass };
    newdata[e.target.id] = e.target.value;
    setPass(newdata);
  }

  function handleChangeTextField(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  function handleSelectChange(name, e) {
    const newdata = { ...data };
    newdata[name] = e.target.value;
    setData(newdata);
  }

  function submit() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(passData),
    };
    fetch(myConstClass.SERVER_URL + "user/pass", requestOptions).then(
      (response) => {
        response.json();
        if (response.status == 200) {
          setSnackbarMessage("Lozinka je uspješno promijenjena");
          setSeverity("success");
          setSnackbar(true);
        } else {
          setSnackbarMessage("Doslo je do greske");
          setSeverity("error");
          setSnackbar(true);
        }
      }
    );
  }

  function handleUpdateUserData() {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    fetch(myConstClass.SERVER_URL + "user/updateData", requestOptions).then(
      (response) => {
        response.json();
        if (response.status == 200) {
          setSnackbarMessage("Vaši podaci su ažurirani.");
          setSeverity("success");
          setSnackbar(true);
        } else {
          setSnackbarMessage("Doslo je do greske");
          setSeverity("error");
          setSnackbar(true);
        }
      }
    );
  }

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };

    fetch(myConstClass.SERVER_URL + "user/profile", requestOptions)
      .then((response) => response.json())
      .then(setData);
  }, []);

  useEffect(() => {
    if (isLoad) {
      // setData

      setIsLoad(false);
    } else {
      var pattern = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
      );
      if (pass.filedNew1.length >= 7) {
        if (pattern.test(pass.filedNew1)) {
          if (pass.filedNew1 === pass.filedNew2 && pass.filedNew1 !== "") {
            setPassData({
              id: id,
              value: pass.filedNew1,
            });

            setConfirm(false);

            setErrorMessage("");
          } else {
            setErrorMessage("Lozinke moraju biti jednake");
            // message: Lozinke moraju biti jednake
            //

            setConfirm(true);
          }
        } else {
          setErrorMessage("Lozinka ne zadovoljava kompleksnost lozinke");
          // message: Lozinka nije sigurna
          setConfirm(true);
        }
      } else {
        setErrorMessage("Lozinka mora biti duza od 7 znakova");

        // lozinka mora biti duza od 7 znakova
      }
    }
  }, [pass]);

  return (
    <>
      <Snackbar
        // anchorOrigin={{vertical: 'center', horizontal: 'center'}}
        open={snackbar}
        autoHideDuration={3000}
        onClose={snackbarClose}

        // message = { <span> {snackbarMessage} </span>}
      >
        <AlertSnackbar
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </AlertSnackbar>
      </Snackbar>

      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={6}>
            <Item>
              <div className="wrapper">
                <h2>Osnovne informacije</h2>

                <br />
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={4}>
                    <Item> Liquid democracy uloga:</Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      <TextField
                        id="uloga"
                        defaultValue="Korisnik"
                        disabled={true}
                        size="small"
                        onChange={handleChangeTextField}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item> Ime:</Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      <TextField
                        id="ime"
                        value={data.ime}
                        disabled={lozinka_status == 0}
                        size="small"
                        onChange={handleChangeTextField}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item>Prezime: </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      <TextField
                        id="prezime"
                        value={data.prezime}
                        size="small"
                        disabled={lozinka_status == 0}
                        onChange={handleChangeTextField}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item>email dresa: </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      <TextField
                        id="mail"
                        value={data.mail}
                        size="small"
                        disabled={lozinka_status == 0}
                        onChange={handleChangeTextField}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item>Spol: </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      <GenderSelectMini
                        id="spol"
                        onChange={handleSelectChange}
                        defaultValue={data.spol.replace(/\s/g, "")}
                        disabled={lozinka_status == 0}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item> Datum rođenja: </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      <DatePickerDefault
                        id="datume_rodjenja"
                        onChange={handleChange}
                        defaultValue={data.datume_rodjenja}
                        disabled={lozinka_status == 0}
                      />
                    </Item>
                  </Grid>
                </Grid>
              </div>

              <div className="wrapper">
                <h2>Proširene informacije o vama: </h2>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={4}>
                    <Item> Obrazovanje:</Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      {" "}
                      <BasicSelectMini
                        id="obrazovanje_id"
                        onChange={handleSelectChange}
                        name="Obrazovanje"
                        tableName="education"
                        defaultValue={data.obrazovanje_id}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item> Vjeroispovijest:</Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      {" "}
                      <BasicSelectMini
                        id="vjeroispovjest_id"
                        onChange={handleSelectChange}
                        name="Vjeroispovjest"
                        tableName="religion"
                        defaultValue={data.vjeroispovjest_id}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item> Županija:</Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      <BesicSelectZupanijaMini
                        name="Županija"
                        tableName="location/region"
                        setZupanija={setZupanija}
                        defaultValue={data.zupanija}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item> Naselje:</Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      <CustomAutocompleteMini
                        id="lokacija_id"
                        onChange={handleValueChange}
                        name="Mjesto stanovanja"
                        tableName="location/settlements"
                        zupanija={zupanija}
                        defaultID={data.lokacija_id}
                        defaultValue={data.naselje}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item> Bračni status: </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      {" "}
                      <BasicSelectMini
                        id="bracni_status_id"
                        onChange={handleSelectChange}
                        name="Bračni status"
                        tableName="maritalStatus"
                        defaultValue={data.bracni_status_id}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item>Broj osoba u kučanstvu:</Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      {" "}
                      <TextField
                        id="broj_osoba_kucanstvo"
                        value={data.broj_osoba_kucanstvo}
                        onChange={handleChangeTextField}
                        type="number"
                        size="small"
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item>Zanimanje:</Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      {" "}
                      <BasicSelectMini
                        id="zanimanje_id"
                        onChange={handleSelectChange}
                        name="Zanimanje"
                        tableName="ocupation"
                        defaultValue={data.zanimanje_id}
                      />
                    </Item>
                  </Grid>
                </Grid>
              </div>

              <Button
                variant="contained"
                color="success"
                sx={{ mt: 3 }}
                onClick={handleUpdateUserData}
              >
                Spremi
              </Button>
            </Item>
          </Grid>
          <Grid item xs={6}>
            {lozinka_status != 0 ? (
              <Item>
                <div className="wrapper">
                  <h2>Kreiraj novu lozinku</h2>

                  <Box sx={{ mt: 2, width: 0.8 }}>
                    <TextField
                      id="filedNew1"
                      type="password"
                      value={pass.filedNew1}
                      onChange={handleChange}
                      fullWidth
                      label="Nova lozinka"
                      variant="outlined"
                      sx={{ mb: 1 }}
                    />
                    <TextField
                      id="filedNew2"
                      type="password"
                      value={pass.filedNew2}
                      onChange={handleChange}
                      fullWidth
                      label="Ponoviti novu lozinku"
                      variant="outlined"
                      sx={{ mb: 2 }}
                    />
                    {errorMessage != "" ? (
                      <Alert sx={{ m: 1 }} severity="error">
                        {errorMessage}
                      </Alert>
                    ) : (
                      <></>
                    )}
                    <br />
                    {`Kompleksnost lozinke - Lozinka mora sadržavati:`} <br />
                    {`- minimalno 7 znakova,`} <br />
                    {`- velika slova (ABCDEFGH…)`} <br />
                    {`- mala slova (abcdefgh…)`} <br />
                    {`- brojke (123456…)`} <br />
                    {`- simbole (@#$%{ } [ ] ( ) /  ' " , ; : . < >…)`} <br />
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ mt: 3 }}
                      disabled={confirm}
                      onClick={submit}
                    >
                      Spremi
                    </Button>
                  </Box>
                </div>
              </Item>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
