import { useState, useEffect } from "react";


export function useFetch(uri){

    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()

    useEffect(() => {
        if(!uri) return; // if the uri is empty exit

        fetch(uri)
        .then((response) => {
            if (response.ok) {
              return response.json();
            }
            setLoading(false)
            throw new Error('Something went wrong');
          })
        .then(setData)
        .then(() => setLoading(false))
        .catch(setError)

    }, [uri])

    return {loading, data, error}
}