import { useState, useEffect, forwardRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { TextField, IconButton, Grid, Box, Button, Card } from "@mui/material";
import * as myConstClass from "Constants";
import Switch from "componenets/Elements/Switch/Switch";
import {
  FormControl,
  OutlinedInput,
  InputLabel,
  TablePagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Snackbar, styled } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import MaterialUiIconPicker from "componenets/Elements/IconPicker/MaterialUiIconPicker";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import * as MuiIcons from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import { useUser } from "auth/useUser";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));
const DinamicIcon = ({ name, ...rest }) => {
  if (name != null) {
    let cleanName = name.split(" ");
    const IconComponent = MuiIcons[cleanName[0]];
    return IconComponent ? (
      <IconComponent fontSize="large" {...rest} />
    ) : (
      <HowToVoteIcon />
    );
  } else {
    return <HowToVoteIcon fontSize="large" />;
  }
};

export default function SifarnikAnketaIGlasovanjaTable() {
  const user = useUser();

  const [rows, setSifarnici] = useState<any[]>([]);
  const [update, setUpdate] = useState(true);

  // Snack bar states
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  const [filterData, setFilterData] = useState({
    filteredValue: "",
  });

  const [iconName, setIconName] = useState("home");

  const showPickedIcon = (icon) => {
    console.info(icon); // prints {name: "access_alarm", code: "e190"}
    let string = icon.name;
    let formatedName = string.charAt(0).toUpperCase() + string.slice(1);

    let removingExtra = string.split("_");

    let finalName = "";
    for (let i = 0; i < removingExtra.length; i++) {
      finalName +=
        removingExtra[i].charAt(0).toUpperCase() + removingExtra[i].slice(1);
    }

    setIconName(finalName);
  };

  const handleInputChange = (e) => {
    const newdata = { ...filterData };
    newdata[e.target.id] = e.target.value;
    setFilterData(newdata);
  };

  function snackbarClose(event) {
    setSnackbar(false);
  }

  //myConstClass
  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "codebook")
      .then((response) => response.json())
      .then(setSifarnici);
  }, [update]);

  const [data, setData] = useState({
    id: 0,
    ime: "",
    opis: "",
    ikona: "",
  });

  function searchFilter() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(filterData),
    };
    fetch(myConstClass.SERVER_URL + "codebook/filter", requestOptions)
      .then((response) => response.json())
      .then(setSifarnici);
  }

  function submit() {
    const newdata = { ...data };
    newdata["ikona"] = iconName;
    setData(newdata);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newdata),
    };
    fetch(myConstClass.SERVER_URL + "codebook", requestOptions).then(
      (response) => {
        response.json();
        if (response.status === 200) {
          setSnackbarMessage("Novi šifarnik je dodan");
          setSeverity("success");
          setUpdate(!update);
          setSnackbar(true);
          //  alert("Novi korisnik je dodan")
        } else {
          setSnackbarMessage("Doslo je do greske");
          setSeverity("error");
          setSnackbar(true);
        }
      }
    );
    // window.location.reload();

    // Refresh table component
  }

  function handleChange(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    newdata.id = rows.length + 1;
    setData(newdata);
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission behavior - reseting the page
      searchFilter();
    }
  };

  return (
    <>
      <Snackbar
        // anchorOrigin={{vertical: 'center', horizontal: 'center'}}
        open={snackbar}
        autoHideDuration={3000}
        onClose={snackbarClose}

        // message = { <span> {snackbarMessage} </span>}
      >
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 1 }}
        spacing={2}
      >
        <Grid item>
          <Item></Item>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 1 }}
            spacing={2}
          >
            <Grid item>
              <Item>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1.5, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl required fullWidth sx={{ m: 1 }}>
                    <TextField
                      id="filteredValue"
                      value={filterData.filteredValue}
                      onChange={handleInputChange}
                      label="Traži"
                      size="small"
                      onKeyPress={handleKeyPress}
                    />
                  </FormControl>
                </Box>
              </Item>
            </Grid>
            <IconButton
              sx={{ ml: 1, mt: 2 }}
              aria-label="search"
              onClick={searchFilter}
            >
              <SearchIcon style={{ fill: "black" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 450 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600, color: "#91A3B0" }}>
                  UID
                </TableCell>
                <TableCell
                  sx={{ fontWeight: 600, color: "#91A3B0" }}
                  align="left"
                >
                  Ime
                </TableCell>
                <TableCell
                  sx={{ fontWeight: 600, color: "#91A3B0" }}
                  align="left"
                >
                  Opis
                </TableCell>
                <TableCell
                  sx={{ fontWeight: 600, color: "#91A3B0" }}
                  align="left"
                >
                  Status
                </TableCell>
                {user.AdminWrite && <TableCell
                  sx={{ fontWeight: 600, color: "#91A3B0" }}
                  align="center"
                >
                  Aktiviraj/Deaktiviraj
                </TableCell>}

                
              </TableRow>
            </TableHead>
            <TableBody>
              {rows

                .sort(function (a, b) {
                  return a.sifarnik_id - b.sifarnik_id;
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    key={row.sifarnik_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{ minWidth: 90 }}>
                      {row.sifarnik_id}
                    </TableCell>
                    <TableCell align="left">{row.naziv}</TableCell>
                    <TableCell align="left">{row.opis}</TableCell>
                    <TableCell align="left">
                      {row.aktivan ? "Aktivan" : "Neaktivan"}{" "}
                    </TableCell>

                    {user.adminWrite && (
                      <TableCell align="center">
                        {" "}
                        <Switch
                          value={row.aktivan}
                          id={row.sifarnik_id}
                          url="codebook/activation"
                          chnageState={update}
                        />{" "}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      {user.AdminWrite &&  <Card sx={{ maxWidth: "60%", padding: 3 }}>
          <Box mt={2}>
            <h4>Dodavanje sifarnika</h4>
            <Grid container spacing={3}>
              <Grid item xs={7}>
                <FormControl required fullWidth sx={{ m: 1 }}>
                  {/* <InputLabel htmlFor="outlined-adornment-amount">Sifarnik ime</InputLabel>
                <OutlinedInput
                  id="ime"
                  value={data.opis}
                  onChange={handleChange}
                  label="Ime"
                /> */}
                  <TextField
                    id="ime"
                    label="Ime sifarnika"
                    // placeholder="Ime sifarnika"
                    onChange={handleChange}
                    multiline
                    sx={{ mb: 3 }}
                  />

                  <TextField
                    id="opis"
                    label="Opis sifarnika"
                    onChange={handleChange}
                    // placeholder="Opis sifarnika"
                    rows={4}
                    multiline
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} sx={{ mt: 2 }}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ ml: 4 }}
                >
                  <Grid item>
                    <Avatar sx={{ p: 2 }}>
                      <DinamicIcon name={iconName} />
                    </Avatar>
                  </Grid>
                  <Grid item sx={{ mt: 2 }}>
                    <Item sx={{ mt: 1 }}>Odabrana ikone: {iconName}</Item>
                  </Grid>
                  <Grid item sx={{ mt: 2 }}>
                    <Item>
                      <MaterialUiIconPicker onPick={showPickedIcon} />
                    </Item>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" color="success" onClick={submit}>
                Dodaj
              </Button>
            </Grid>
          </Box>
        </Card>}

       
      </Box>
    </>
  );
}
