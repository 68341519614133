import { Breadcrumbs, Container, Grid, Box, Link } from '@mui/material';
import SifarnikZanimanjaTable from 'componenets/Elements/Tables/SifarnikZanimanjaTable/SifarnikZanimanjaTable';


function SifarnikZanimanja() {
  return (
    <div className="wrapper">
      <Container maxWidth="lg">

        <h2>Administracija šifarnika zanimanja</h2>
      
        <SifarnikZanimanjaTable/>
       
      </Container>
    </div>
  );
}

export default SifarnikZanimanja;