import { Breadcrumbs, Container, Grid, Box, Link } from '@mui/material';
import SifarnikObrazovanjaTable from 'componenets/Elements/Tables/SifarnikObrazovanjaTable/SifarnikObrazovanjaTable';


function SifarnikObrazovanja() {
  return (
    <div className="wrapper">
      <Container maxWidth="lg">

        <h2>Administracija šifarnika obrazovanja</h2>
      
        <SifarnikObrazovanjaTable/>
       
      </Container>
    </div>
  );
}

export default SifarnikObrazovanja;