
import React, { useState, useEffect } from 'react'
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import * as myConstClass from 'Constants';
import { CardActions, CardContent, TextField, Typography, Button, Box, Card, Grid, Divider, Switch, Container } from '@mui/material';

import { LineController, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';



ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale);
// const labels = ["January", "February", "March"];
ChartJS.register(ArcElement, Tooltip, Legend);

const BarChart = ({ title, pitanje_id }) => {

  const [labels, setLables] = useState(["January", "February", "March", "April", "May", "June", "July"]);
  const [values, setValues] = useState([20, 59, 80, 81, 56, 55, 40]);

  const [pitanja, setPitanja] = useState([{
    opis_odgovor: ""
  }]);
  // const labels = ["January", "February", "March", "April", "May", "June"];
  const data = {
    labels: labels,
    datasets: [
      {
        label: "My First dataset",

        backgroundColor: [
          'rgba(255, 99, 132, 0.3)',
          'rgba(54, 162, 235, 0.3)',
          'rgba(255, 206, 86, 0.3)',
          'rgba(255, 127, 81, 0.3)',

          'rgba(75, 192, 192, 0.3)',
          'rgba(208, 0, 0, 0.3)',
          'rgba(153, 102, 255, 0.3)',

        ],
        data: values,
      },
    ],
  };

  const options = {

    responsive: true,
    plugins: {
      legend: {
        display: false,

        position: 'right' as const,
      },
      title: {
        display: false,
        text: 'Chart.js Horizontal Bar Chart',
      },
    },
  };

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + 'poll/results/linear?pitanje=' + pitanje_id)
      .then((response) => response.json())
      .then(data => {

        setPitanja(data)

        // map naziv_odgovora u labels
        let newLables = data.map((user) => {
          return user.opis_odgovor.trim();
        });
        setLables(newLables);

        let newData = data.map((user) => {
          return user.num;
        });

        setValues(newData)

      })

  }, []);

  return (
    <div>

      {title}
      <br />
      Broj odgovora: 5

      <Container maxWidth="md">

        <Bar data={data} options={options} />
      </Container>
    </div>
  );
};

export default BarChart;