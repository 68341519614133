import {
  Breadcrumbs,
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  CardHeader,
  List,
  ListItemButton,
  ListItemText,
  ListItem,
  Divider,
  Step,
  Stepper,
  StepLabel,
  FormControl,
  TextField,
  IconButton,
} from "@mui/material";
import { useState, useEffect } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SearchIcon from "@mui/icons-material/Search";
import { useUser } from "auth/useUser";

import * as myConstClass from "Constants";
import BasicSelectSifarnik from "componenets/Elements/BasicSelect/BasicSelectSifarnik";

function BlockchainVerifikacija() {
  const user = useUser();

  const [selectedIndexTheme, setSelectedIndexTheme] = useState(1);
  const [selectedIndexPerson, setSelectedIndexPerson] = useState(2);
  const [history, setHistory] = useState<any[]>([]);
  const [alignment, setAlignment] = useState("THEME");
  const [listOfThemes, setListOfThemes] = useState<any[]>([]);
  const [listOfVoters, setListOfVoters] = useState<any[]>([]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
    setHistory([]);
    setListOfVoters([]);
    setListOfThemes([]);
  };

  useEffect(() => {
    //TODO: promijeni id u odabranog usera
    handleSelectChange(filterData.theme);
  }, [alignment]);

  function handleSelectChange(id) {
    const newdata = { ...filterData };
    newdata["theme"] = id;
    setFilterData(newdata);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newdata),
    };

    if (alignment == "THEME") {
      fetch(myConstClass.SERVER_URL + "theme/filter", requestOptions)
        .then((response) => response.json())
        .then(setListOfThemes);

      setHistory([]);
      setListOfVoters([]);
    } else {
      fetch(myConstClass.SERVER_URL + "poll/filter", requestOptions)
        .then((response) => response.json())
        .then(setListOfThemes);

      setHistory([]);
      setListOfVoters([]);
    }
  }

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndexTheme(index);

    //TODO: povuci listu glasaca za tu temu
    if (alignment == "THEME") {
      fetch(myConstClass.SERVER_URL + "theme/votersBC?tema=" + index)
        .then((response) => response.json())
        .then(setListOfVoters);
      setHistory([]);
    } else {
      fetch(myConstClass.SERVER_URL + "poll/votersBC?tema=" + index)
        .then((response) => response.json())
        .then(setListOfVoters);
      setHistory([]);
    }
  };

  const handleListItemClickPerson = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndexPerson(index);
    //TODO: povuci podatke o glesacu za tu temu
    fetch(
      myConstClass.SERVER_URL +
        "hyperledgerConnector?id=" +
        alignment +
        selectedIndexTheme +
        "&userID=" +
        index
    )
      .then((response) => response.json())
      .then(setHistory);
  };

  const [filterData, setFilterData] = useState({
    filteredValue: "",
    theme: 0,
    status: 3,
    korisnik_id: 0,
  });

  const handleInputChange = (e) => {
    const newdata = { ...filterData };
    newdata["filteredValue"] = e.target.value;
    setFilterData(newdata);
    setHistory([]);
    setListOfVoters([]);
  };

  function parseDate(date) {
    const inputDate = new Date(date);

    // Get the individual date components
    const day = inputDate.getDate().toString().padStart(2, "0");
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const year = inputDate.getFullYear().toString().slice(-4); // Get the last two digits of the year

    // Get the time components
    const hours = inputDate.getHours().toString().padStart(2, "0");
    const minutes = inputDate.getMinutes().toString().padStart(2, "0");

    // Format the date and time
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  function descriptionGenerator(value) {
    if (value == 0) {
      return "Korisnik je povukao glas";
    } else if (value == 1) {
      return "Korisnik je glasao za";
    } else if (value == 2) {
      return "Korisnik je glasao protiv";
    }
    if (value == 3) {
      return "Korisnik je suzdrzan";
    }
    if (Array.isArray(value)) {
      return "Korisnik je odgovorio na anketu";
    } else {
      return "Korisnik je delegirao glas";
    }
  }

  function searchFilter() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(filterData),
    };
    if (alignment == "THEME") {
      fetch(myConstClass.SERVER_URL + "theme/filter", requestOptions)
        .then((response) => response.json())
        .then(setListOfThemes);

      setHistory([]);
      setListOfVoters([]);
    } else {
      fetch(myConstClass.SERVER_URL + "poll/filter", requestOptions)
        .then((response) => response.json())
        .then(setListOfThemes);

      setHistory([]);
      setListOfVoters([]);
    }
  }

  return (
    <div className="wrapper">
      <Container maxWidth="lg">
        <Card
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1.5 },
            // '& .MuiBox-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <CardHeader
            sx={{ bgcolor: "#086AE2", color: "white" }}
            title={"Blockchain Verifikacija"}
          />

          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <BasicSelectSifarnik
                  id="sifarnik_id"
                  onChange={handleSelectChange}
                  name="Kategorija *"
                  tableName="codebook/name"
                  dedaultValue={""}
                  helperText={""}
                  error={""}
                  onClose={() => {}}
                />
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="THEME">Tema</ToggleButton>
                  <ToggleButton value="POLL">Anketa</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                  spacing={2}
                >
                  <Grid item>
                    <Box
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1.5, width: "25ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <FormControl required fullWidth sx={{ m: 1 }}>
                        <TextField
                          id="filteredValue"
                          value={filterData.filteredValue}
                          onChange={handleInputChange}
                          label="Pretraži po imenu teme"
                          size="small"
                        />
                      </FormControl>
                    </Box>
                  </Grid>

                  <IconButton
                    sx={{ ml: 1, mt: 2 }}
                    aria-label="search"
                    onClick={searchFilter}
                  >
                    <SearchIcon style={{ fill: "black" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={2} sx={{ mt: "2em" }}>
          <Grid item xs={5}>
            <Card sx={{ minWidth: 275 }}>
              <CardHeader
                sx={{ bgcolor: "#086AE2", color: "white" }}
                title={"Lista Tema"}
              />
              <CardContent>
                <List>
                  {listOfThemes.map((theme, index) => (
                    <>
                      <ListItem disablePadding>
                        <ListItemButton
                          selected={selectedIndexTheme === theme.tema_id}
                          onClick={(event) =>
                            handleListItemClick(event, theme.tema_id)
                          }
                        >
                          <ListItemText primary={theme.naslov} />
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }}>
              <CardHeader
                sx={{ bgcolor: "#086AE2", color: "white" }}
                title={"Lista Sudionika"}
              />
              <CardContent>
                <List>
                  {listOfVoters.map((voter, index) => (
                    <>
                      <ListItem disablePadding>
                        <ListItemButton
                          selected={selectedIndexTheme === voter.korisnik_id}
                          onClick={(event) =>
                            handleListItemClickPerson(event, voter.korisnik_id)
                          }
                        >
                          <ListItemText
                            primary={voter.ime + " " + voter.prezime}
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card sx={{ minWidth: 275 }}>
              <CardHeader
                sx={{ bgcolor: "#086AE2", color: "white" }}
                title={"Povijest glasanja"}
              />
              <CardContent>
                {history.length == 0 ? (
                  <Typography>
                    Odaberite temu/anketu i glasaca kako bi vidjeli povijest
                    glasanja
                  </Typography>
                ) : (
                  <Stepper activeStep={0} orientation="vertical">
                    {history.map((step, index) => (
                      <Step key={step.user}>
                        <StepLabel
                          optional={
                            <Typography variant="caption">
                              {parseDate(step.Time)}
                            </Typography>
                          }
                        >
                          {descriptionGenerator(step.Value)}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default BlockchainVerifikacija;
