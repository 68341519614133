import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CardPoll from 'componenets/Elements/Card/CardPoll';
import { Poll } from 'model/Poll'

type FolderProps = {
  data: Poll[]
}

export default function FolderListPoll({ data }: FolderProps) {



  return (
    <List sx={{ width: '100%', maxWidth: 1, bgcolor: 'background.paper' }}>

      {data.map(poll =>
        <ListItem >
          <CardPoll poll={poll} />

        </ListItem>
      )}

    </List>
  );
}
