import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import { styled, alpha } from "@mui/material/styles";
import NovaAnketaForm from "componenets/Elements/Forms/NoviKorisnikForm/NovaAnketaForm";
import {
  Chip,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Button,
  Box,
  Card,
  Grid,
  Snackbar,
  Alert,
  Container,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadIcon from "@mui/icons-material/Download";
import KratkiOdgovor from "componenets/Elements/VrsteOdgovora/KratkiOdgovor";
import VisestrukiOdgovor from "componenets/Elements/VrsteOdgovora/VisestrukiOdgovor";
import PotvrdniOkvir from "componenets/Elements/VrsteOdgovora/PotvrdniOkvir";
import LinearnoMjerilo from "componenets/Elements/VrsteOdgovora/LinearnoMjerilo";
import * as myConstClass from "Constants";
import { useUser } from "auth/useUser";
import { exportToExcel } from "react-json-to-excel";
import { useNavigate } from "react-router-dom";

type LocationState = { anketaID: string; anketaNaslov: any };

function Anketa() {
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  let navigate = useNavigate();

  function snackbarClose(event) {
    setSnackbar(false);
  }

  const user = useUser();
  const { id, korisnickoIme, ulogaID, lozinka_status } = user;
  const [themeData, setThemeData] = useState({});

  const location = useLocation();
  const { anketaID, anketaNaslov } = location.state as LocationState;

  const [tokens, setTokens] = useState(0);
  const [totalLeftTokens, setTotalLeftTokens] = useState(0);

  const [anketa, setAnketa] = useState({
    naslov: "naslov",
    opis: "",
    anketa_id: "",
    tokenizirana: 0,
  });

  const navigateTo = () => {
    // Replacing " " (space) to "" empty space

    navigate("/AktivneAnkete", {
      replace: true,
    });
  };

  const [pitanja, setPitanja] = useState([
    {
      naziv_pitanja: "naslov",
      opis: "",
      anketa_id: "",
      vrstapitanja_id: 0,
      anketa_pitanja_id: "",
      vrijednost_odgovora: "",
      korisnik_id: id,
      vrijednost_tokena: 0,
      dokument: [],
    },
  ]);

  function handleTokenChange(index, value) {
    //checks if the value is less then avaiable tokens

    value = value || 0;
    // zbroji sve vrijednosti tokena osim pitanja koje je iniciralo promjenu
    let totalUsedTokens = Number(0);
    for (let i = 0; i < pitanja.length; i++) {
      if (i != index)
        totalUsedTokens += Number(pitanja[i]["vrijednost_tokena"]);
    }

    let leftToUseTokens = tokens - Number(totalUsedTokens);
    let currentTotal = Number(totalUsedTokens) + parseInt(value);

    if (currentTotal > tokens) {
      const newdata = [...pitanja];
      newdata[index]["vrijednost_tokena"] = leftToUseTokens;

      setTotalLeftTokens(0);
      setPitanja(newdata);
    } else {
      const newdata = [...pitanja];
      newdata[index]["vrijednost_tokena"] = value;

      setPitanja(newdata);
      let left = tokens - currentTotal;
      setTotalLeftTokens(left);
    }
    // updateQuestions(newdata)
  }

  function handleAnswerChange(index, value) {
    const newdata = [...pitanja];
    newdata[index]["vrijednost_odgovora"] = value;
    newdata[index]["korisnik_id"] = id;

    setPitanja(newdata);
    // updateQuestions(newdata)
  }

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "poll?anketa=" + anketaID)
      .then((response) => response.json())
      .then((data) => {
        setAnketa(data[0]);
        setTokens(data[0].broj_kredita);
        setTotalLeftTokens(data[0].broj_kredita);
        // dohvati pitanja od te ankete
        fetch(myConstClass.SERVER_URL + "poll/pitanja?anketa=" + anketaID)
          .then((response) => response.json())
          .then((pitanjaData) => {
            const newdata = pitanjaData;

            for (let i = 0; i < pitanjaData.length; i++) {
              if (
                pitanjaData[i].dokument == "" ||
                pitanjaData[i].dokument == undefined
              ) {
                newdata[i]["dokument"] = [];
              } else {
                let dokumnetiArray = pitanjaData[i].dokument.split(",");
                dokumnetiArray.pop();
                newdata[i]["dokument"] = dokumnetiArray;
              }
              newdata[i]["vrijednost_tokena"] = 0;
            }
            setPitanja(newdata);
          });
      });
  }, []);

  function isAnswered() {
    // checks if all questions are answered for tocenized
    if (anketa.tokenizirana == 1) {
      for (let i = 0; i < pitanja.length; i++) {
        if (pitanja[i]["vrijednost_odgovora"] == undefined) {
          setSnackbarMessage("Morate odgovoriti na sve pitanja");
          return false;
        }
      }
    } else {
      //TODO: checks if all requiered questions are answered
      for (let i = 0; i < pitanja.length; i++) {
        // Provjera za obavezna pitnaja

        if (pitanja[i]["obavezan_odgovor"] == "1") {
          if (pitanja[i].vrijednost_odgovora == undefined) {
            setSnackbarMessage("Morate odgovoriti na sve obavezna pitanja");
            return false;
          }
        }
      }
    }

    return true;
  }

  function handleAnswerSubmit() {
    if (totalLeftTokens != 0 && anketa.tokenizirana == 1) {
      setSnackbarMessage("Morate potrošitisve tokene");
      setSeverity("error");
      setSnackbar(true);
      //TODO: dodaj morate odgovoriti na sva pitanja

      //provjeri imaju li svi vrijednost odgovora
    }
    if (!isAnswered()) {
      setSeverity("error");
      setSnackbar(true);
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(pitanja),
      };
      fetch(myConstClass.SERVER_URL + "poll/submit", requestOptions).then(
        (response) => {
          response.json();

          if (response.status == 200) {
            setSnackbarMessage("Vaš odgovori su uspjesno spremljeni.");
            setSeverity("success");
            setSnackbar(true);

            setTimeout(() => {
              navigateTo();
            }, 2000);

            //Redirect na kategorije
          } else {
            setSnackbarMessage("Doslo je do greske");
            setSeverity("error");
            setSnackbar(true);
          }
        }
      );
    }
  }

  function getFile(fileName, pitanjeID) {
    // uploads/representative_98/Status Report Example 1.pdf

    fetch(
      myConstClass.SERVER_URL +
        `fileManager/download?url=uploads/pitanje_${pitanjeID}/${fileName}`
    ).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      });
    });
  }

  function getExcel() {
    fetch(
      myConstClass.SERVER_URL +
        "poll/getPollData?anketa=" +
        anketaID +
        "korisnik_id=" +
        id
    )
      .then((response) => response.json())
      .then((data) => {
        setThemeData(data);
        exportToExcel(data, "downloadfilename", true);
      });
  }

  return (
    <>
      <Snackbar
        // anchorOrigin={{vertical: 'center', horizontal: 'center'}}
        open={snackbar}
        autoHideDuration={3000}
        onClose={snackbarClose}

        // message = { <span> {snackbarMessage} </span>}
      >
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>
      <div className="wrapper">
        <Container maxWidth="md">
          <Card sx={{ mt: 5, p: 3 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h4" gutterBottom>
                  {anketa.naslov}
                </Typography>
              </Grid>
              <Grid item>
                {anketa.tokenizirana == 1 ? (
                  <>
                    <Chip label={`Broj tokena ${tokens}`} />

                    <Chip label="Ova anketa je tokenizirana" color="success" />
                    <Tooltip title="Trenutno su vam na raspolaganju bodovi kojima možete vrednovati pitanje na koje odgovarate. Vrijednost može i ne mora biti ravnomjerno raspoređen. Vrijednost pitanja određujete sami upisujući vrijednost boda koji želite dodijeliti pitanju na koje odgovarate, odnosno koliko je pitanje za vas značajno.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>

            <Typography variant="subtitle1" gutterBottom>
              {anketa.opis}
            </Typography>

            <Box sx={{ mt: 5 }}></Box>
          </Card>

          {pitanja.map((pitanje, index) => (
            <>
              <Card sx={{ mt: 3 }}>
                <CardHeader sx={{ bgcolor: "#086AE2" }} />

                <CardContent>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-around"
                    spacing={2}
                  >
                    <Grid item xs={6} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          {" "}
                          <Typography variant="h5" component="h2">
                            <p>{pitanje.naziv_pitanja}</p>
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          {anketa.tokenizirana == 1 ? (
                            <TextField
                              id="outlined-basic"
                              label="Broj tokena"
                              variant="outlined"
                              helperText={`Preostalo tokena: ${totalLeftTokens}`}
                              type="number"
                              value={pitanje.vrijednost_tokena}
                              onChange={(e) =>
                                handleTokenChange(index, e.target.value)
                              }
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                            />
                          ) : (
                            <>
                              {pitanje["obavezan_odgovor"] == 1 ? (
                                <Chip label="Obavezno pitanje" />
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    {pitanje.dokument.length != 0 ? (
                      <Grid item xs={6} md={4}>
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: 360,
                            bgcolor: "background.paper",
                          }}
                        >
                          <List>
                            {pitanje.dokument.map((file, index) => (
                              // { file == '' ? <></> : <></>}

                              <ListItem disablePadding>
                                <ListItemButton
                                  onClick={() =>
                                    getFile(file, pitanje.anketa_pitanja_id)
                                  }
                                >
                                  <ListItemIcon>
                                    <AttachFileIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={file} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>

                  <br />

                  {pitanje.vrstapitanja_id == 1 ? (
                    <VisestrukiOdgovor
                      answerChange={handleAnswerChange}
                      pitanjeID={pitanje.anketa_pitanja_id}
                      index={index}
                    />
                  ) : (
                    <></>
                  )}
                  {pitanje.vrstapitanja_id == 2 ? (
                    <PotvrdniOkvir
                      answerChange={handleAnswerChange}
                      index={index}
                      pitanjeID={pitanje.anketa_pitanja_id}
                    />
                  ) : (
                    <></>
                  )}
                  {pitanje.vrstapitanja_id == 3 ? (
                    <KratkiOdgovor
                      answerChange={handleAnswerChange}
                      index={index}
                    />
                  ) : (
                    <></>
                  )}
                  {pitanje.vrstapitanja_id == 4 ? (
                    <LinearnoMjerilo
                      pitanjeID={pitanje.anketa_pitanja_id}
                      answerChange={handleAnswerChange}
                      index={index}
                    />
                  ) : (
                    <></>
                  )}
                </CardContent>
              </Card>
            </>
          ))}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 4, mb: 4 }}
          >
            <Grid item></Grid>
            <Grid item>
              <Button
                variant="contained"
                color="success"
                size="large"
                onClick={handleAnswerSubmit}
              >
                Pošalji
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default Anketa;
