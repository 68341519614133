import { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Button,
  Paper,
  styled,
  IconButton,
  TextField,
  Box,
  FormControl,
} from "@mui/material";

import "./Korisnici.css";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import KorisniciTable from "componenets/Elements/Tables/KorisniciTable/KorisniciTable";
import BasicBreadcrumbs from "componenets/Navigation/BasicBreadcrumbs/BasicBreadcrumbs";
import { useFetch } from "util/useFetch";
import * as myConstClass from "Constants";
import { useUser } from "auth/useUser";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

export default function Korisnici() {
  const user = useUser();

  const [filterData, setFilterData] = useState({
    filteredValue: "",
  });

  const { loading, data, error } = useFetch(myConstClass.SERVER_URL + "user");

  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleInputChange = (e) => {
    const newdata = { ...filterData };
    newdata[e.target.id] = e.target.value;
    setFilterData(newdata);
  };

  function searchFilter() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(filterData),
    };
    fetch(myConstClass.SERVER_URL + "user/filter", requestOptions)
      .then((response) => response.json())
      .then(setTableData);
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission behavior - reseting the page
      searchFilter();
    }
  };

  return (
    <>
      <div className="wrapper">
        <Container maxWidth="lg">
          <h2>Administracija Korisnika</h2>
          <BasicBreadcrumbs currentPage="Korisnici" />

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 1 }}
            spacing={2}
          >
            <Grid item>
            {user.adminWrite &&  <Item>
                <Button component={Link} to="/NoviKorisnik" variant="contained">
                  Dodaj Korisnika
                </Button>
              </Item>}

             
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
                spacing={2}
              >
                <Grid item>
                  <Item>
                    <Box
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1.5, width: "25ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <FormControl required fullWidth sx={{ m: 1 }}>
                        <TextField
                          id="filteredValue"
                          value={filterData.filteredValue}
                          onChange={handleInputChange}
                          label="Traži"
                          size="small"
                          onKeyPress={handleKeyPress}
                        />
                      </FormControl>
                    </Box>
                  </Item>
                </Grid>
                <IconButton
                  sx={{ ml: 1, mt: 2 }}
                  aria-label="search"
                  onClick={searchFilter}
                >
                  <SearchIcon style={{ fill: "black" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <KorisniciTable filteredData={tableData} />
        </Container>
      </div>
    </>
  );
}
