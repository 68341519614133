import { useState, useEffect, forwardRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { TextField, IconButton, Grid, Box, Button } from "@mui/material";
import {
  FormControl,
  OutlinedInput,
  InputLabel,
  TablePagination,
} from "@mui/material";
import * as myConstClass from "Constants";
import { Snackbar, styled } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useUser } from "auth/useUser";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface Column {
  id:
    | "name"
    | "theme-read"
    | "theme-write"
    | "poll-read"
    | "poll-write"
    | "admin-read"
    | "admin-write";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "name", label: "", minWidth: 100 },
  { id: "theme-read", label: "read", minWidth: 100 },
  { id: "theme-write", label: "write", minWidth: 100 },
  { id: "poll-read", label: "read", minWidth: 100 },
  { id: "poll-write", label: "write", minWidth: 100 },
  { id: "admin-read", label: "read", minWidth: 100 },
  { id: "admin-write", label: "write", minWidth: 100 },
];

interface Data {
  name: string;
  themeRead: boolean;
  themeWrite: boolean;
  pollRead: boolean;
  pollWrite: boolean;
  adminRead: boolean;
  adminWrite: boolean;
}

function createData(
  name: string,
  themeRead: boolean,
  themeWrite: boolean,
  pollRead: boolean,
  pollWrite: boolean,
  adminRead: boolean,
  adminWrite: boolean
): Data {
  return {
    name,
    themeRead,
    themeWrite,
    pollRead,
    pollWrite,
    adminRead,
    adminWrite,
  };
}

const rows = [
  createData("Korisnik", true, true, true, true, false, false),
  createData("Administrator", true, true, true, true, true, true),
  createData("Predstavnik", true, true, true, true, false, false),
];

export default function UlogeTable() {
  const user = useUser();

  const [update, setUpdate] = useState(true);
  const [roles, setRoles] = useState<any[]>([]);

  // Snack bar states
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  function snackbarClose(event) {
    setSnackbar(false);
  }

  const [data, setData] = useState({
    id: 0,
    opis: "",
  });

  //myConstClass
  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "roles")
      .then((response) => response.json())
      .then((response) => {
        let adaptedRoles: any[] = [];

        response.map((item, index) => {
          const containsObjectWithAttribute = adaptedRoles.some(
            (obj) => obj["opis"] === item.opis
          );

          if (containsObjectWithAttribute) {
            // update object param for this permission
            if (item.naziv_odgovornosti == "Ankete") {
              for (let obj of adaptedRoles) {
                if (obj["opis"] === item.opis) {
                  obj["pollRead"] = item.ovlastenje_pregled == 1; // Change the target attribute in the object
                  obj["pollWrite"] = item.ovlastenje_kreiranje == 1; // Change the target attribute in the object
                }
              }
            } else {
              for (let obj of adaptedRoles) {
                if (obj["opis"] === item.opis) {
                  obj["adminRead"] = item.ovlastenje_pregled == 1; // Change the target attribute in the object
                  obj["adminWrite"] = item.ovlastenje_kreiranje == 1; // Change the target attribute in the object
                }
              }
            }
          } else {
            // create new object and give him this param
            adaptedRoles.push({
              uloga_id: item.ulogaid,
              opis: item.opis,
              themeRead: item.ovlastenje_pregled == 1,
              themeWrite: item.ovlastenje_kreiranje == 1,
            });
          }
        });
        setRoles(adaptedRoles);
      });

    //TODO: add real data from db
  }, [update]);

  function handleChange(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    newdata.id = rows.length + 1;
    setData(newdata);
  }

  function handleCheckboxChange(uloga_id, permissionType) {
    const newdata = [...roles];
    newdata[uloga_id][permissionType] = !newdata[uloga_id][permissionType];
    setRoles(newdata);
    return newdata[uloga_id][permissionType];
  }

  function updatePermission(uloga_id, vrsta, naziv_odgovornosti) {
    let requestData = {};

    for (let obj of roles) {
      // Checks the role
      if (obj["uloga_id"] == uloga_id) {
        // check which permission
        if (vrsta == "read") {
          if (naziv_odgovornosti == "Teme") {
            let newValue = handleCheckboxChange(uloga_id, "themeRead");
            requestData = {
              uloga_id: uloga_id,
              naziv_odgovornosti: "Teme",
              type: "read",
              value: newValue,
            };
            break;
          } else if (naziv_odgovornosti == "Admin") {
            let newValue = handleCheckboxChange(uloga_id, "adminRead");
            requestData = {
              uloga_id: uloga_id,
              naziv_odgovornosti: "Admin",
              type: "read",
              value: newValue,
            };
            break;
          } else {
            let newValue = handleCheckboxChange(uloga_id, "pollRead");
            requestData = {
              uloga_id: uloga_id,
              naziv_odgovornosti: "Ankete",
              type: "read",
              value: newValue,
            };
            break;
          }
        } else {
          if (naziv_odgovornosti == "Teme") {
            let newValue = handleCheckboxChange(uloga_id, "themeWrite");
            requestData = {
              uloga_id: uloga_id,
              naziv_odgovornosti: "Teme",
              type: "write",
              value: newValue,
            };
            break;
          } else if (naziv_odgovornosti == "Admin") {
            let newValue = handleCheckboxChange(uloga_id, "adminWrite");
            requestData = {
              uloga_id: uloga_id,
              naziv_odgovornosti: "Admin",
              type: "write",
              value: newValue,
            };
            break;
          } else {
            let newValue = handleCheckboxChange(uloga_id, "pollWrite");
            requestData = {
              uloga_id: uloga_id,
              naziv_odgovornosti: "Ankete",
              type: "write",
              value: newValue,
            };
            break;
          }
        }
      }
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestData),
    };
    fetch(myConstClass.SERVER_URL + "roles/update", requestOptions).then(
      (response) => {
        response.json();
        if (response.status == 200) {
          setSnackbarMessage("Dopuštenje ažurirano");
          setSeverity("success");
          setUpdate(!update);
          setSnackbar(true);
          //  alert("Novi korisnik je dodan")
        } else {
          setSnackbarMessage("Doslo je do greske");
          setSeverity("error");
          setSnackbar(true);
        }
      }
    );
  }

  function submit() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(myConstClass.SERVER_URL + "roles/create", requestOptions).then(
      (response) => {
        response.json();
        if (response.status == 200) {
          setSnackbarMessage("Novi šifarnik je dodan");
          setSeverity("success");
          setUpdate(!update);
          setSnackbar(true);
          //  alert("Novi korisnik je dodan")
        } else {
          setSnackbarMessage("Doslo je do greske");
          setSeverity("error");
          setSnackbar(true);
        }
      }
    );
    // window.location.reload();

    // Refresh table component
  }

  return (
    <>
      <Snackbar open={snackbar} autoHideDuration={3000} onClose={snackbarClose}>
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>

      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow key="row1">
                <TableCell align="center" colSpan={1}>
                  Uloga
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  Teme
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  Ankete
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  Admin sučelje
                </TableCell>
              </TableRow>
              <TableRow key="row2">
                {columns.map((column, index) => (
                  <TableCell key={"header" + index} align={"center"}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" key={row.opis}>
                    <TableCell key={row.opis + "-naslov"} align={"left"}>
                      {row.opis}
                    </TableCell>
                    <TableCell key={row.opis + "-themeRead"} align={"center"}>
                      <Checkbox
                        disabled={!user.adminWrite}
                        checked={row.themeRead}
                        onChange={() =>
                          updatePermission(row.uloga_id, "read", "Teme")
                        }
                      />
                    </TableCell>
                    <TableCell key={row.opis + "-themWrite"} align={"center"}>
                      <Checkbox
                        disabled={!user.adminWrite}
                        checked={row.themeWrite}
                        onChange={() =>
                          updatePermission(row.uloga_id, "write", "Teme")
                        }
                      />
                    </TableCell>
                    <TableCell key={row.opis + "-pollRead"} align={"center"}>
                      <Checkbox
                        disabled={!user.adminWrite}
                        checked={row.pollRead}
                        onChange={() =>
                          updatePermission(row.uloga_id, "read", "Ankete")
                        }
                      />
                    </TableCell>
                    <TableCell key={row.opis + "-pollWrite"} align={"center"}>
                      <Checkbox
                        disabled={!user.adminWrite}
                        checked={row.pollWrite}
                        onChange={() =>
                          updatePermission(row.uloga_id, "write", "Ankete")
                        }
                      />
                    </TableCell>
                    <TableCell key={row.opis + "-adminRead"} align={"center"}>
                      <Checkbox
                        disabled={!user.adminWrite}
                        checked={row.adminRead}
                        onChange={() =>
                          updatePermission(row.uloga_id, "read", "Admin")
                        }
                      />
                    </TableCell>
                    <TableCell key={row.opis + "-adminWrite"} align={"center"}>
                      <Checkbox
                        disabled={!user.adminWrite}
                        checked={row.adminWrite}
                        onChange={() =>
                          updatePermission(row.uloga_id, "write", "Admin")
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {user.adminWrite && (
        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item>
              <FormControl required fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount">
                  Naziv uloge
                </InputLabel>
                <OutlinedInput
                  id="opis"
                  value={data.opis}
                  onChange={handleChange}
                  label="Opis"
                />
              </FormControl>
            </Grid>
            <Grid item sx={{ m: 3 }}>
              <Button variant="contained" onClick={submit}>
                Dodaj
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
