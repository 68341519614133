import React from 'react';
import { Container } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import BasicBreadcrumbs from 'componenets/Navigation/BasicBreadcrumbs/BasicBreadcrumbs';
import NoviKorisnikForm from 'componenets/Elements/Forms/NoviKorisnikForm/NoviKorisnikForm';

//


function NoviKorisnik() {
  const Item = styled(Paper)(({ theme }) => ({

    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none'
  }));

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

  return (
    <div className="wrapper">
      <Container maxWidth="lg">

        <h2>Dodavanje novog korisnika</h2>
        <BasicBreadcrumbs currentPage="Novi Korisnik"/>
        <NoviKorisnikForm />
     

      </Container>

      
    </div>
  );
}

export default NoviKorisnik;