import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import {
  TextField,
  CardContent,
  Typography,
  Button,
  Box,
  Card,
  Grid,
  Snackbar,
  Container,
} from "@mui/material";

function KratkiOdgovor({ answerChange, index }) {
  function onAnswerChange(value) {
    answerChange(index, value);
  }

  return (
    <div className="wrapper">
      <Container maxWidth="lg">
        <TextField
          fullWidth
          id="standard-basic"
          onChange={(e) => onAnswerChange(e.target.value)}
          label="Unesite svoj odgovor"
          variant="standard"
          multiline={true}
        />
      </Container>
    </div>
  );
}

export default KratkiOdgovor;
