import {useState, useEffect} from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import CancelIcon from "@material-ui/icons/Cancel";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import _without from "lodash/without";

import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import * as myConstClass from 'Constants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip({disabled, id, onChange}) {
  const theme = useTheme();
  const [personName, setPersonName] = useState<string[]>([]);
  const [names, setNames] = useState<any[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    onChange(typeof value === 'string' ? value.split(',') : value)
  };

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + 'codebook/name')
    .then((response) => response.json())
    .then(setNames);

  }, []);

  useEffect(() => {
    // clear all selected values
    if(disabled) {
      setPersonName([])
    }

  }, [disabled]);

  // const handleDelete = () => {
  //   console.info('You clicked the delete icon.');
  // };

  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    setPersonName((current) => _without(current, value));
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">Pretplate</InputLabel>
        <Select
          disabled={disabled}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onOpen={() => {}}

          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Pretplate" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip 
                key={value} 
                label={value} 
                clickable
                deleteIcon={
                  <HighlightOffIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
                onDelete={(e) => handleDelete(e, value)}
                onClick={() => {}}/>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name.id}
              value={name.naziv}
              style={getStyles(name.naziv, personName, theme)}>
              {name.naziv}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
