import React from "react";
import { useState, useEffect } from "react";

import { BrowserRouter, Route } from "react-router-dom";
import {
  TextField,
  Checkbox,
  Typography,
  Button,
  Box,
  Card,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";

import * as myConstClass from "Constants";

function PotvrdniOkvir({ pitanjeID, answerChange, index }) {
  const [list, setList] = useState<any[]>([false]);
  const [choosen, setChoseen] = useState<any[]>([]);

  const [odgovori, setOdgovori] = useState([
    {
      anketa_odgovori_id: "",
      naziv_odgovora: "naslov",
    },
  ]);

  function onAnswerChange(i, e) {
    const newdata = [...list];
    newdata[i] = e.target.checked;
    setList(newdata);

    if (e.target.checked == false) {
      // remove item
      let choiceChange = choosen.filter((item, index) => item != e.target.id);

      setChoseen(choiceChange);
      answerChange(index, choiceChange);
    } else {
      let choiceChange = [...choosen, e.target.id];
      setChoseen(choiceChange);
      answerChange(index, choiceChange);
    }
    // create a list with checked answers - thier ids
  }

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "poll/odgovori?pitanje=" + pitanjeID)
      .then((response) => response.json())
      .then((data) => {
        setOdgovori(data);
      });

    setList(Array(odgovori.length).fill(false));
  }, []);

  return (
    <div className="wrapper">
      <Grid container direction="column">
        {odgovori.map((item, index) => (
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Checkbox
                  id={item.anketa_odgovori_id}
                  checked={list[index]}
                  onChange={(e) => onAnswerChange(index, e)}
                />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body1" component="h2">
                  {item.naziv_odgovora}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default PotvrdniOkvir;
