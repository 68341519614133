import React from 'react';
import { Container } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import NovaAnketaForm from 'componenets/Elements/Forms/NoviKorisnikForm/NovaAnketaForm';

function DodajAnketu() {
  const Item = styled(Paper)(({ theme }) => ({

    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none'
  }));


  return (
    <div className="wrapper">
      <Container maxWidth="lg">

        {/* <h2>Kreiranje nove ankete</h2> */}
        <NovaAnketaForm />
     
      </Container>

      
    </div>
  );
}

export default DodajAnketu;