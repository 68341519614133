import { Breadcrumbs, Container, Grid, Box, Link } from '@mui/material';
import SifarnikLokacijaTable from 'componenets/Elements/Tables/SifarnikLokacijaTable/SifarnikLokacijaTable';


function SifarnikLokacija() {
  return (
    <div className="wrapper">
      <Container maxWidth="lg">

        <h2>Administracija šifarnika lokacija</h2>
      
        <SifarnikLokacijaTable/>
       
      </Container>
    </div>
  );
}

export default SifarnikLokacija;