import React, { useState, useEffect } from "react";
import FolderList from "componenets/Elements/FolderList/FolderList";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  Box,
  IconButton,
  TextField,
  FormControl,
  Paper,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import * as myConstClass from "Constants";
import { useLocation } from "react-router-dom";
import { useUser } from "auth/useUser";
import CustomTabsPoll from "componenets/Elements/Tabs/CustomTabsPoll";
import FolderListPoll from "componenets/Elements/FolderList/FolderListPoll";
import CustomTabsPollCreated from "componenets/Elements/Tabs/CustomTabsPollCreated";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

// type LocationState = { urlName: string, subscription: any, id: string, icon : string};

function MojeAnkete() {
  // const { state } : { state: LocationState } = useLocation();
  // // const { title } = state;
  // const {urlName, subscription} = state
  const user = useUser();

  const { id, korisnickoIme, ulogaID } = user;

  const location = useLocation();
  //   const { urlName, subscription, id, icon } = location.state as LocationState;

  const [name, setName] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const [poll, setPoll] = useState("Sve");
  const [data, setData] = useState<any[]>([]);

  const [filterData, setFilterData] = useState({
    filteredValue: "",
    id: `${id}`,
    status: tabValue,
  });

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(filterData),
    };
    fetch(myConstClass.SERVER_URL + "poll/filterCreated", requestOptions)
      .then((response) => response.json())
      .then(setData);
  }, [tabValue]);

  function searchFilter() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(filterData),
    };
    fetch(myConstClass.SERVER_URL + "poll/filterCreated", requestOptions)
      .then((response) => response.json())
      .then(setData);
  }

  // on theme state change update the data in Folder List
  const handleInputChange = (e) => {
    const newdata = { ...filterData };
    newdata[e.target.id] = e.target.value;
    setFilterData(newdata);
  };

  function handleTabChange(newValue) {
    const newdata = { ...filterData };
    newdata["status"] = newValue;
    setFilterData(newdata);

    setTabValue(newValue);
  }

  return (
    <Container maxWidth="lg">
      <div className="wrapper">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} sx={{ m: 2 }}>
            <Grid item xs={6}>
              <h1>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item></Grid>
                  <Grid item>Moje Ankete</Grid>
                </Grid>
              </h1>
              {/* <PollBreadcrumbs currentPage={urlName} /> */}
            </Grid>
            <Grid item xs={2} sx={{ mt: 1.5 }}></Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
                spacing={2}
              >
                <Grid item>
                  <Item>
                    <Box
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1.5, width: "25ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <FormControl required fullWidth sx={{ m: 1 }}>
                        <TextField
                          id="filteredValue"
                          value={filterData.filteredValue}
                          onChange={handleInputChange}
                          label="Traži"
                          size="small"
                        />
                      </FormControl>
                    </Box>
                  </Item>
                </Grid>

                <IconButton
                  sx={{ ml: 1, mt: 2 }}
                  aria-label="search"
                  onClick={searchFilter}
                >
                  <SearchIcon style={{ fill: "black" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Container maxWidth="md">
          <CustomTabsPollCreated tabChange={handleTabChange} />

          <FolderListPoll data={data} />
        </Container>
      </div>
    </Container>
  );
}

export default MojeAnkete;
