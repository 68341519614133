import * as React from 'react';
import { useEffect, useState, forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as myConstClass from 'Constants';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const Item = styled(Paper)(({ theme }) => ({

  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none'
}));

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogPrivola({ alertOpen, korisnik_id }) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(alertOpen)

  }, [alertOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  const [checkbox, setCheckbox] = useState(false);

  // Snack bar states
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState('warning');

  const handleChange = () => {
    setCheckbox(!checkbox);
  };

  function snackbarClose(event) {
    setSnackbar(false)
  }

  const [data, setData] = useState({
    id: korisnik_id
  })

  const handleAgree = () => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(myConstClass.SERVER_URL + 'user/updatePrivola', requestOptions)
      .then(response => {
        response.json()
        if (response.status == 200) {
          setSnackbarMessage("Privola je prihvaćena")
          setSeverity("success")
          setSnackbar(true)
          //  alert("Novi korisnik je dodan")
        } else {
          setSnackbarMessage("Doslo je do greske")
          setSeverity("error")
          setSnackbar(true)

        }
      })
    // window.location.reload();  
    handleClose()

    // Refresh table component 
  };

  return (
    <div>

      <Snackbar
        // anchorOrigin={{vertical: 'center', horizontal: 'center'}}
        open={snackbar}
        autoHideDuration={3000}
        onClose={snackbarClose}

      // message = { <span> {snackbarMessage} </span>}

      >
        <Alert onClose={snackbarClose} severity={severity == "success" ? "success" : severity == "error" ? "error" : severity == "info" ? "info" : "warning"} sx={{ width: '100%' }}>
          <span> {snackbarMessage} </span>
        </Alert>

      </Snackbar>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Privola"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Korištenjem Internet stranice Liquid Democracy koja pripada domeni www.liquid-democracy.info, smatra se da su korisnici u svakom trenutku upoznati s ovim uvjetima korištenja te da ih u potpunosti razumiju i prihvaćaju.

            Prespectus zadržava pravo izmjene sadržaja i uvjeta korištenja Internet stranice Liquid Democracy bez obveze prethodne najave te nije odgovorno za bilo kakvu štetu nastalu tim promjenama. Ujedno, ne snosi odgovornost za izravnu ili neizravnu štetu koja je nastala upotrebom ove stranice.

            Zalažemo se za zaštitu vaših osobnih podataka i vašeg prava na privatnost. Ako imate bilo kakvih pitanja ili nedoumica u vezi s našim pravilima ili našom praksom u vezi s obradom vaših osobnih podataka, obratite nam se na adresu e-pošte info@liquid-democracy.info.

            Određene podatke kada posjetite, koristite ili pregledavate web mjesta. Ove informacije ne otkrivaju vaš identitet (poput vašeg imena ili podataka o kontaktima), ali mogu sadržavati podatke o uređaju i upotrebi, kao što su vaša IP adresa, karakteristike preglednika i uređaja, operativni sustav, jezične postavke, referentni URL-ovi, naziv uređaja, država, lokacija, informacije o tome kako i kada koristite našu stranicu i ostale tehničke informacije. Te su informacije prije svega potrebne za održavanje sigurnosti i rada naših web lokacija te u svrhu interne analitike i izvješćivanja.

            Proveli smo odgovarajuće tehničke, organizacijske mjere sigurnosti namijenjene zaštiti sigurnosti svih osobnih podataka koje obrađujemo. Međutim, imajte na umu da ne možemo jamčiti da je Internet 100% siguran.
            <br />
            Pristanak:
            Vaše podatke možemo obraditi samo ako ste nam dali privolu da koristimo vaše osobne podatke u točno određene svrhe.
            <br />
            Pravne obveze:
            Možemo otkriti vaše podatke tamo gdje se to od nas zakonski zahtijeva kako bismo postupili u skladu s važećim propisima ili po nalogu suda ili drugog tijela, a u sklopu sudskog, upravnog ili drugog pravnog postupka (uključujući reakciju državnih tijela da ispune zahtjeve nacionalne sigurnosti ili provođenja zakona).
            <br />
            Legitimni interesi:
            Možemo otkriti vaše podatke tamo gdje smatramo da je potrebno istražiti, spriječiti ili poduzeti mjere u vezi s potencijalnim kršenjima naših politika, sumnjama na prijevare, situacijama koje uključuju potencijalne prijetnje sigurnosti bilo koje osobe i nezakonitim aktivnostima, ili kao dokaz u parnicama u koje smo uključeni.

            Slanja administrativnih podataka. Vaše osobne podatke možemo koristiti da bismo vam poslali podatke o vašem upitu, usluzi i novim značajkama ili podatke o promjenama naših uvjeta i pravila i to isključivo ukoliko smo za navedeno dobili vašu privolu i to za vrijeme trajanja dobivene privole.
            <br />
            <br />
            Pravilnika o prikupljanju, obradi i zaštiti osobnih podatka možete preuzeti <a href="https://production.liquide-democracy.info/GDPR.liquid.democracy.docx">ovdje</a>. 

          </DialogContentText>
          <FormGroup sx={{mt : 2}}>

            <FormControlLabel control={<Checkbox checked={checkbox} onChange={handleChange}/>} label="Ovim potvrđujem da sam pročitao/la, da razumijem i pristajem na uvjete iz Privole, a koji se odnose iz Pravila zaštite privatnosti Liquid Democracy." />

          </FormGroup>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleAgree}>Prihvati</Button>
        */}
          <Box sx={{ width: '100%' }}>
            <Grid container
              direction="row"
              justifyContent="space-between"
              alignItems="center" >
              <Grid item >
                <Item>

                </Item>
              </Grid>
              <Grid item >
                <Item>
                  <Button onClick={handleAgree} disabled={!checkbox} variant="contained" color="success">Prihvati</Button>
                </Item>
              </Grid>

            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
