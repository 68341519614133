import React from 'react';
import { useState, useEffect } from 'react';

import { BrowserRouter, Route } from 'react-router-dom';
import { TextField, CardContent, Typography, Button, Box, Card, Grid, Snackbar, Alert } from '@mui/material';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CloseIcon from '@mui/icons-material/Close';

function VisestrukiOdgovor({ answerChange, idPitanja, tokenizirano }) {
    const [id, setID] = useState(idPitanja);

    const [list, setList] = useState<any[]>([{ naziv_odgovora: "Prvi odgovor", vrijednost_odgovora: 0 }]);

    const handleAdd = () => {
        const items = list;
        setList([...items, { naziv_odgovora: "Opcija odgovor", vrijednost_odgovora: 0 }]);
        answerChange(list, id);

    };

    function handleRemove(selectedItem) {
        const items = list;
        if (items.length > 0) {
            const lastIndex = selectedItem;
            setList(items.filter((item, index) => index !== lastIndex));
        }
        answerChange(list, id);

    };

    function handleAnswerChange(value, index) {
        // get item by index 
        // update value
        const newdata = [...list]
        newdata[index].naziv_odgovora = value

        setList(newdata)
        answerChange(newdata, id);

    }

    function handleValueChange(value, index) {
        // get item by index 
        // update valueaddNewItem
        const newdata = [...list]
        newdata[index].vrijednost_odgovora = value

        setList(newdata)
        answerChange(newdata, id);

    }

    useEffect(() => {

        answerChange(list, id);
    }, [list]);

    return (
        <div className="wrapper">

            <Grid
                container
                direction="column"
            >
                {list.map((item, index) => (
                    //   <li key={index}>{item}</li>
                    <Grid item key={index}>
                        <Grid container
                            direction="row"
                            alignItems="center">
                            <Grid item >
                                <RadioButtonUncheckedIcon />
                            </Grid>
                            <Grid item xs={7} sx={{ p: 2 }} >
                                <TextField id="standard-basic" value={item.naziv_odgovora} onChange={(event) => handleAnswerChange(event.target.value, index)} fullWidth variant="standard" />
                            </Grid>
                            {tokenizirano == "tokenizirana" ? <>

                                <Grid item sx={{ pl: 2 }} >
                                    <Typography variant='body2'> Broj tokena: </Typography >
                                </Grid>

                                <Grid item xs={2} sx={{ pl: 2, pr: 2 }} >
                                    <TextField id="standard-basic" type="number" value={item.vrijednost_odgovora} onChange={(event) => handleValueChange(event.target.value, index)} variant="standard" />

                                </Grid>
                            </ >
                                : <></>}

                            <Grid item alignContent="right" >
                                <CloseIcon onClick={() => handleRemove(index)} />
                            </Grid>
                        </Grid>
                    </Grid>

                ))}

                <Grid item>
                    <Grid container
                        direction="row"
                        alignItems="center">

                        <Grid item >
                            <RadioButtonUncheckedIcon />
                        </Grid>
                        <Grid item xs={3} sx={{ p: 2 }} onClick={() => handleAdd()}>
                            <TextField id="standard-basic" defaultValue="Dodaj opciju" disabled fullWidth variant="standard" />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default VisestrukiOdgovor;