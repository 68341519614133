import React from "react";
import { useState, useEffect } from "react";

import { BrowserRouter, Route } from "react-router-dom";
import {
  TextField,
  CardContent,
  Typography,
  Button,
  Box,
  Card,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CloseIcon from "@mui/icons-material/Close";
import * as myConstClass from "Constants";

function VisestrukiOdgovor({ pitanjeID, answerChange, index }) {
  // function VisestrukiOdgovor({answerChange, idPitanja}) {

  //  const [id, setID] = useState(idPitanja);

  const [list, setList] = useState<any[]>([
    "Prvi odgovor",
    "Drugi Odgovor",
    "Treci odgovor",
  ]);

  const [odgovori, setOdgovori] = useState([
    {
      anketa_odgovori_id: "0",
      naziv_odgovora: "naslov",
    },
  ]);
  // Dodaj funkciju koja povlaci sve odgovore i njihove tokenizirane vrijednsoti

  function onAnswerChange(value) {
    answerChange(index, value);
  }

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "poll/odgovori?pitanje=" + pitanjeID)
      .then((response) => response.json())
      .then((data) => {
        setOdgovori(data);
        // dohvati pitanja od te ankete
      });
  }, []);

  return (
    <div className="wrapper">
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
      >
        <Grid container direction="column">
          {odgovori.map((item, index) => (
            //   <li key={index}>{item}</li>
            <Grid item key={index} sx={{ mb: 2 }}>
              <FormControlLabel
                value={index}
                control={<Radio />}
                label={item.naziv_odgovora}
                onChange={() => onAnswerChange(item.anketa_odgovori_id)}
                sx={{ m: 0 }}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </div>
  );
}

export default VisestrukiOdgovor;
