import React from 'react';
import {
    MultipleFileUpload,
    MultipleFileUploadMain,
    MultipleFileUploadStatus,
    MultipleFileUploadStatusItem,
    Checkbox,
    HelperText,
    HelperTextItem
} from '@patternfly/react-core';
import UploadIcon from '@patternfly/react-icons/dist/esm/icons/upload-icon';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import * as myConstClass from 'Constants';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';


interface readFile {
    fileName: string;
    data?: string;
    loadResult?: 'danger' | 'success';
    loadError?: DOMException;
}

export default function FileUploader({ fileChange, handleFileUploadError}) {
    const [isHorizontal, setIsHorizontal] = React.useState(false);
    const [fileUploadShouldFail, setFileUploadShouldFail] = React.useState(false);
    const [currentFiles, setCurrentFiles] = React.useState<File[]>([]);

    const [readFileData, setReadFileData] = React.useState<readFile[]>([]);
    const [showStatus, setShowStatus] = React.useState(false);
    const [statusIcon, setStatusIcon] = React.useState('inProgress');

    // only show the status component once a file has been uploaded, but keep the status list component itself even if all files are removed
    if (!showStatus && currentFiles.length > 0) {
        setShowStatus(true);
    }

   

    React.useEffect(() => {
        // setCurrentFiles(previousFiles)
        fileChange(currentFiles)

    }, [currentFiles]);

    // determine the icon that should be shown for the overall status list
    React.useEffect(() => {

        if (readFileData.length < currentFiles.length) {
            setStatusIcon('inProgress');
        } else if (readFileData.every(file => file.loadResult === 'success')) {
            setStatusIcon('success');
        } else {
            setStatusIcon('danger');
        }
    }, [readFileData, currentFiles]);

    function getFileNewUpload(fileName, file) {

        let url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName
        a.click();


    }

    // remove files from both state arrays based on their name
    const removeFiles = (namesOfFilesToRemove: string[]) => {
        const newCurrentFiles = currentFiles.filter(
            currentFile => !namesOfFilesToRemove.some(fileName => fileName === currentFile.name)
        );

        setCurrentFiles(newCurrentFiles);

        const newReadFiles = readFileData.filter(
            readFile => !namesOfFilesToRemove.some(fileName => fileName === readFile.fileName)
        );

        setReadFileData(newReadFiles);
    };

    /** Forces uploaded files to become corrupted if "Demonstrate error reporting by forcing uploads to fail" is selected in the example,
     * only used in this example for demonstration purposes */
    const updateCurrentFiles = (files: File[]) => {
        if (fileUploadShouldFail) {
            const corruptedFiles = files.map(file => ({ ...file, lastModified: ('foo' as unknown) as number }));
            setCurrentFiles(prevFiles => [...prevFiles, ...corruptedFiles]);
        } else {
            setCurrentFiles(prevFiles => [...prevFiles, ...files]);
        }
    };

    // callback that will be called by the react dropzone with the newly dropped file objects
    const handleFileDrop = (droppedFiles: File[]) => {
        // identify what, if any, files are re-uploads of already uploaded files
        const currentFileNames = currentFiles.map(file => file.name);
        const reUploads = droppedFiles.filter(droppedFile => currentFileNames.includes(droppedFile.name));

        /** this promise chain is needed because if the file removal is done at the same time as the file adding react
         * won't realize that the status items for the re-uploaded files needs to be re-rendered */
        Promise.resolve()
            .then(() => removeFiles(reUploads.map(file => file.name)))
            .then(() => updateCurrentFiles(droppedFiles));
    };

    return (
        <>
            <MultipleFileUpload
                onFileDrop={handleFileDrop}
                dropzoneProps={{
                    accept: 'image/jpeg, application/msword, application/pdf, image/png'
                }}
                isHorizontal={isHorizontal}
            >
                <MultipleFileUploadMain
                    titleIcon={<UploadIcon />}
                    titleText="Drag and drop files here"
                    titleTextSeparator="or"
                    infoText="Accepted file types: JPEG, Doc, PDF, PNG"
                />

                {currentFiles.map((file, index) => (

                    <ListItem disablePadding key={index}>
                        <ListItemButton >
                            <ListItemIcon onClick={() => getFileNewUpload(file.name, file)}>
                                <AttachFileIcon />
                            </ListItemIcon>
                            <ListItemText primary={file.name} onClick={() => getFileNewUpload(file.name, file)} />

                            <IconButton onClick={() => removeFiles([file.name])}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemButton>
                    </ListItem>
                ))}

            </MultipleFileUpload>
        </>
    );
};
