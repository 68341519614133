import React from 'react';
import { useState, useEffect } from 'react';

import { BrowserRouter, Route } from 'react-router-dom';
import { TextField, FormControl, InputLabel, MenuItem, Typography, Button, Box, Card, Grid, Snackbar, Alert } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as myConstClass from 'Constants';


function LinearnoMjerilo({ pitanjeID, data }) {

    const [from, setFrom] = React.useState('1');

    const [to, setTo] = React.useState('2');

    const [odgovori, setOdgovori] = useState([{
        naziv_odgovora: "start"
    }, {
        naziv_odgovora: "end"
    }])

    useEffect(() => {

        setTo(String(data[1].vrijednost_odgovora))
        setFrom(String(data[0].vrijednost_odgovora))
        setOdgovori(data)

    }, [data]);

    return (
        <div className="wrapper">
            <Grid
                container
                direction="column">
                <Grid item >
                        <Grid container
                            direction="row"
                            alignItems="center">
                            <Grid item >
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={from}
                                    defaultValue={'1'}
                                    onChange={(e) =>  setFrom(e.target.value as string)}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item sx={{ p: 2 }} >
                                do
                            </Grid>
                            <Grid item alignContent="right" >
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={to}
                                    defaultValue={'2'}
                                    onChange={(e) =>  setTo(e.target.value as string)}
                                >
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                </Grid>

                <Grid item >
                    <Grid container
                        direction="row"
                        alignItems="center">

                        <Grid item >
                            {from}
                        </Grid>
                        <Grid item xs={3} sx={{ p: 2 }} >
                            <TextField id="standard-basic" value={odgovori[0].naziv_odgovora} fullWidth variant="standard" />
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item >
                    <Grid container
                        direction="row"
                        alignItems="center">

                        <Grid item >
                            {to}
                        </Grid>
                        <Grid item xs={3} sx={{ p: 2 }} >
                            <TextField id="standard-basic" value={odgovori[1].naziv_odgovora} placeholder="Drugi Label" fullWidth variant="standard" />
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>


        </div>
    );
}

export default LinearnoMjerilo;