import { useEffect, useState } from "react";
import * as myConstClass from "Constants";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  Container,
  Grid,
  Button,
  styled,
  IconButton,
  Paper,
  Avatar,
  Divider,
} from "@mui/material";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import Stack from "@mui/material/Stack";

import { BrowserRouter, Route, Navigate, useParams } from "react-router-dom";
import BallotIcon from "@mui/icons-material/Ballot";
import { useNavigate } from "react-router-dom";

import DialogPrivola from "componenets/Elements/Dialog/DialogPrivola/DialogPrivola";
import DialogFirstLogin from "componenets/Elements/Dialog/DialogFirstLogin/DialogFirstLogin";
import DialogOdlPass from "componenets/Elements/Dialog/DialogOldPass/DialogOldPass";
import { useUser } from "auth/useUser";
import { idText } from "typescript";

function Home() {
  const user = useUser();
  const { id, korisnickoIme, ulogaID, lozinka_status } = user;

  let navigate = useNavigate();

  const navigateTo = (pageLink: string) => {
    // Replacing " " (space) to "" empty space
    const res = pageLink.replace(/ /g, "");

    navigate("../" + res, { replace: false });
  };

  const [firstLogin, setFirstLogin] = useState(false);
  const [oldPass, setOldPass] = useState(false);
  const [alertPrivola, setAlertPrivola] = useState(false);
  const [countData, setCountData] = useState<any[]>([]);

  const { selectedRole } = useParams();

  const [login, setLogin] = useState({
    korisnickoIme: korisnickoIme,
  });

  const [userData, setUserData] = useState({
    korisnik_id: 0,
    korisnicko_ime: 0,
    uloga_id: "",
    privola: "",
    datum_lozinke: "",
    lozinka_status: "",
    datum_obavijesti: "",
  });

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "theme/home")
      .then((response) => response.json())
      .then(setCountData);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(login),
    };

    fetch(myConstClass.SERVER_URL + "user/user", requestOptions)
      .then(function (response) {
        if (response.status == 200) {
        } else {
          // setSnackbarMessage("Doslo je do greske u komunikaciji s serverom")
          // setSeverity("error")
          // setSnackbar(true)
        }

        return response.json();
      })
      .then(function (loginData) {
        //TODO: add the login to the login history for this user

        const items = loginData;
        setUserData(items[0]);
        // check if this is the first login for the manualy added user

        if (items[0].privola !== "1") {
          setAlertPrivola(true);
        }

        if (items[0].lozinka_status == null) {
          setFirstLogin(true);
        } else {
          var lastChange = new Date(items[0].datum_lozinke);
          var lastWarning = new Date(items[0].datum_obavijesti);
          var currentTime = new Date();

          const passChange = currentTime.valueOf() - lastChange.valueOf();
          const warningApproval = currentTime.valueOf() - lastWarning.valueOf();

          // password age
          var pass = Math.floor(passChange / (1000 * 60 * 60 * 24));
          var warning = Math.floor(warningApproval / (1000 * 60 * 60 * 24));

          if (pass > 150 && warning > 120) {
            setOldPass(true);
          }
        }
      });
  }, []);

  return (
    <div className="wrapper">
      {/* <AlertDialog message={message} alertOpen={alert} korisnik_id={userData.korisnik_id} giveUp={giveUp} /> */}

      {/* <AlertDialog message={message} alertOpen={alert} korisnik_id={userData.korisnik_id} /> */}
      <DialogPrivola alertOpen={alertPrivola} korisnik_id={id} />
      <DialogOdlPass show={oldPass} korisnik_id={id} />

      <DialogFirstLogin show={firstLogin} korisnik_id={id} />
      {/* <Button variant="outlined" onClick={() => setAlert(true)}>
        Open alert dialog
      </Button> */}
      <Container maxWidth="md">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={5}>
            <Card
              sx={{ minWidth: 275 }}
              onClick={() => {
                navigateTo(`../AktivneTeme`);
              }}
              style={{ cursor: "pointer" }}
            >
              <CardContent>
                <Divider sx={{ mt: 1.5 }}>
                  <Avatar sx={{ p: 4.5 }}>
                    <HowToVoteIcon sx={{ fontSize: 50 }} />
                  </Avatar>
                </Divider>

                <Stack direction="row" justifyContent="center" sx={{ mt: 1 }}>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ align: "center" }}
                  >
                    Teme
                  </Typography>
                </Stack>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  elit justo, blandit in diam sit amet, suscipit convallis felis
                </Typography>
              </CardContent>

              <CardActions>
                <Avatar sx={{ m: 1, ml: 2 }}>{countData[1]?.aktivne}</Avatar> Otvorene
                <Divider
                  sx={{ ml: 3, mr: 2 }}
                  orientation="vertical"
                  flexItem
                />
                <Avatar sx={{ m: 1 }}>{countData[1]?.zatvorene}</Avatar> Zatvorene
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={5}>
            <Card
              sx={{ minWidth: 275 }}
              onClick={() => {
                navigateTo(`../AktivneAnkete`);
              }}
              style={{ cursor: "pointer" }}
            >
              <CardContent>
                <Divider sx={{ mt: 1.5 }}>
                  <Avatar sx={{ p: 4.5 }}>
                    <BallotIcon sx={{ fontSize: 50 }} />
                  </Avatar>
                </Divider>

                <Stack direction="row" justifyContent="center" sx={{ mt: 1 }}>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ align: "center" }}
                  >
                    Ankete
                  </Typography>
                </Stack>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  elit justo, blandit in diam sit amet, suscipit convallis felis
                </Typography>
              </CardContent>

              <CardActions>
                <Avatar sx={{ m: 1, ml: 2 }}>{countData[0]?.aktivne}</Avatar> Otvorene
                <Divider
                  sx={{ ml: 3, mr: 2 }}
                  orientation="vertical"
                  flexItem
                />
                <Avatar sx={{ m: 1 }}>{countData[0]?.zatvorene}</Avatar> Zatvorene
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Home;
