import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import {
  CardActions,
  CardContent,
  Typography,
  Button,
  Box,
  Card,
  Grid,
  Snackbar,
  Alert,
  Container,
} from "@mui/material";
import * as myConstClass from "Constants";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useUser } from "auth/useUser";
import Moment from "moment";
import DialogDelegacijaGlasa from "componenets/Elements/Dialog/DialogDelegacijaGlasa/DialogDelegacijaGlasa";
import DynamicChart from "componenets/Elements/DynamicChart/DynamicChart";
import TemaBreadcrumbs from "componenets/Navigation/TemaBreadcrumbs/TemaBreadcrumbs";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadIcon from "@mui/icons-material/Download";

import GetAppIcon from "@mui/icons-material/GetApp";
import { exportToExcel } from "react-json-to-excel";
import { JsonToExcel } from "react-json-to-excel";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

type LocationState = { themeID: string; themeLocationData: any };

function Tema() {
  const location = useLocation();
  const { themeID, themeLocationData } = location.state as LocationState;
  const [liveData, setLiveData] = useState(false);
  const [themeDatePassed, setThemeDatePassed] = useState(false);

  const user = useUser();
  const { id, korisnickoIme, ulogaID } = user;

  const [checkVoting, setCheckVoting] = useState(true);

  const [themeData, setThemeData] = useState({});

  const [isRep, setIsRep] = useState(false);

  const [answerData, setAnswerData] = useState({
    odgovor: "",
    korisnik_id: `${id}`,
    datum_odgovora: "",
    odgovor_povucen: "",
    datum_povlacenja: "",
    ovlasten_id: "",
    datum_ovlastenja: "",
    tema_id: "",
    ovlastenje_povuceno: "",
    predstavnik_ovlastenja_id: "",
  });

  const [predstavnik, setPredstavnik] = useState({
    ime: "",
    prezime: "",
    korisnik_id: "",
    predstavnik_id: "",
    prenositelj: "",
    odgovor: "",
    predstavnik_ovlastenja_id: "",
  });

  const [voteRecallData, setVoteRecallData] = useState({
    datum_povlacenja: "",
    temaodgovor_id: "",
    korisnik_id: `${id}`,
    tema_id: themeID,
  });

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  const [data, setData] = useState<any[]>([]);

  // OK DO OVDJE
  const [votingData, setVotingData] = useState({
    korisnik_id: id,
    tema_id: themeID,
    ovlasten_id: "",
  });

  const [modalIsOpen, setIsOpen] = useState(false);

  function openFromParent() {
    setIsOpen(true);
  }

  function handleCloseModalSuccess() {
    setCheckVoting(false);
    setIsOpen(false);
  }

  function handleCloseModal() {
    setIsOpen(false);
  }

  function getFile(fileName) {
    fetch(
      myConstClass.SERVER_URL +
        `fileManager/download?url=uploads/tema_${data[0].tema_id}/${fileName}`
    ).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      });
    });
  }

  const [updateVotingData, setUpdateVotingData] = useState({
    odgovor: "",
    datum_odgovora: "",
    temaodgovor_id: "",
    tema_id: themeID,
    korisnik_id: `${id}`,
  });

  const [predstavniciData, setPredstavniciData] = useState<any[]>([]);

  const [odgovorValue, setOdgovorValue] = useState({
    odg: "",
  });

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "theme/tema?tema=" + themeID)
      .then((response) => response.json())
      .then((responsedata) => {
        const newdata = responsedata;
        let dokumnetiArray = responsedata[0].dokument.split(",");
        dokumnetiArray.pop();
        newdata[0]["dokument"] = dokumnetiArray;
        setData(newdata);

        let temaDate = new Date(responsedata[0].trajanje_teme);
        let now = new Date(Date.now());
        if (temaDate < now) {
          setThemeDatePassed(false);
        } else {
          setThemeDatePassed(true);
        }
      });
  }, []);

  // CHECK THIS METHOD
  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "theme/temaLiveData?tema=" + themeID)
      .then((response) => response.json())
      .then((data) => {
        let temaDate = new Date(data[0].trajanje_teme);
        let now = new Date(Date.now());

        if (data[0].realtime_podaci === "1" || temaDate < now) {
          setLiveData(true);
        } else {
          setLiveData(false);
        }
      });

    fetch(myConstClass.SERVER_URL + "theme/getThemeData?tema=" + themeID)
      .then((response) => response.json())
      .then((data) => {
        let object = [
          {
            sheetName: "podaci o temi",
            details: data[0],
          },
          {
            sheetName: "glasovi",
            details: data[1],
          },
          {
            sheetName: "predstavnici",
            details: data[0],
          },
        ];

        setThemeData(object);
      });
  }, []);

  // CHECK THIS METHOD
  useEffect(() => {
    const requestOptionsRep = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(votingData),
    };
    fetch(myConstClass.SERVER_URL + "representative/", requestOptionsRep)
      .then((response) => response.json())
      .then((data) => {
        setPredstavniciData(data);
        if (!isRepresentative(data)) {
          setIsRep(false);
          checkVote();
        } else {
          setIsRep(true);
          checkVoteRep();
        }
      });
  }, []);

  function checkVote() {
    const requestOptions2 = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(votingData),
    };
    fetch(myConstClass.SERVER_URL + "voteTheme/checkVote", requestOptions2)
      .then((response) => response.json())
      .then((data) => {
        // korisnik nije glasao niti glas nije dodijeljen
        if (data == false) {
          setCheckVoting(true);
        } else {
          setCheckVoting(false);

          // set podatke ako je korinik odgovorio

          if (typeof data[0].temaodgovor_id != "undefined") {
            //if korisnik odgovorio

            if (data[0].odgovor == 0) {
              // korisnik je povukao glas
              setCheckVoting(true);
            }

            var newdata = { ...odgovorValue };
            newdata["odg"] = data[0].odgovor[0];
            setOdgovorValue(newdata);

            const newdataVote = { ...voteRecallData };
            newdataVote["temaodgovor_id"] = data[0].temaodgovor_id;
            setVoteRecallData(newdataVote);
          } else {
            setPredstavnik(data[0]);
          }
        }
      });
  }

  // CHECK THIS METOD
  function checkVoteRep() {
    const requestOptionsCheckVoteRep = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(votingData),
    };
    fetch(
      myConstClass.SERVER_URL + "voteTheme/checkVoteRep",
      requestOptionsCheckVoteRep
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length != 0) {
          var newdata = { ...odgovorValue };
          newdata["odg"] = data[0].odgovor[0];
          setOdgovorValue(newdata);

          const newdataVote = { ...voteRecallData };
          newdataVote["temaodgovor_id"] = data[0].temaodgovor_id;
          setVoteRecallData(newdataVote);

          setCheckVoting(false);
          //  voteRecallData.temaodgovor_id = data.map(dataOdgovor => dataOdgovor.temaodgovor_id)
        } else {
          setCheckVoting(true);
        }
      });
  }

  function snackbarClose(event) {
    setSnackbar(false);
  }

  function isRepresentative(dataPredstavniciProv) {
    var check = false;
    dataPredstavniciProv.map((dataCheck) => {
      if (dataCheck.korisnik_id == id) {
        check = true;
        const newdata = { ...answerData };
        newdata["ovlasten_id"] = dataCheck.id;
        setAnswerData(newdata);
      }
    });
    setIsRep(check);
    return check;
  }

  function handleVote(e) {
    if (isRep) {
      if (odgovorValue.odg.length == 1) {
        const newdataVote = { ...updateVotingData };
        newdataVote["temaodgovor_id"] = voteRecallData.temaodgovor_id;
        newdataVote["datum_odgovora"] = Moment().format(
          "YYYY-MM-DD HH:mm:ss"
        ) as string;
        newdataVote["odgovor"] = e.target.value;
        setUpdateVotingData(newdataVote);

        const requestOptions8 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newdataVote),
        };

        fetch(
          myConstClass.SERVER_URL + "voteTheme/voteUpdateRep",
          requestOptions8
        ).then((response) => {
          response.json();
          if (response.status == 200) {
            setSnackbarMessage("Uspješno ste glasali kao predstavnik");
            setSeverity("success");
            setSnackbar(true);
            setCheckVoting(false);
          } else {
            setSnackbarMessage("Doslo je do greske");
            setSeverity("error");
            setSnackbar(true);
            setCheckVoting(true);
          }
        });
      } else if (odgovorValue.odg.length == 0) {
        const newdata = { ...answerData };
        newdata["datum_odgovora"] = Moment().format(
          "YYYY-MM-DD HH:mm:ss"
        ) as string;
        newdata["odgovor"] = e.target.value;
        newdata["tema_id"] = themeID as string;
        setAnswerData(newdata);

        const requestOptions5 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newdata),
        };
        fetch(
          myConstClass.SERVER_URL + "voteTheme/themeAnswerRep",
          requestOptions5
        ).then((response) => {
          response.json();
          if (response.status == 200) {
            setSnackbarMessage("Uspješno ste glasali kao predstavnik");
            setSeverity("success");
            setSnackbar(true);
            setCheckVoting(false);
          } else {
            setSnackbarMessage("Doslo je do greske");
            setSeverity("error");
            setSnackbar(true);
            setCheckVoting(true);
          }
        });
      }
    } else {
      // checkVote()
      if (odgovorValue.odg.length == 1) {
        const newdata = { ...updateVotingData };
        newdata["temaodgovor_id"] = voteRecallData.temaodgovor_id;
        newdata["datum_odgovora"] = Moment().format(
          "YYYY-MM-DD HH:mm:ss"
        ) as string;
        newdata["odgovor"] = e.target.value;
        setUpdateVotingData(newdata);

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newdata),
        };
        fetch(
          myConstClass.SERVER_URL + "voteTheme/voteUpdate",
          requestOptions
        ).then((response) => {
          response.json();
          if (response.status == 200) {
            setSnackbarMessage("Uspješno ste glasali");
            setSeverity("success");
            setSnackbar(true);
            setCheckVoting(false);
          } else {
            setSnackbarMessage("Doslo je do greske");
            setSeverity("error");
            setSnackbar(true);
            setCheckVoting(true);
          }
        });
      } else {
        const newdata = { ...answerData };
        newdata["datum_odgovora"] = Moment().format(
          "YYYY-MM-DD HH:mm:ss"
        ) as string;
        newdata["odgovor"] = e.target.value;
        newdata["tema_id"] = themeID as string;
        setAnswerData(newdata);

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newdata),
        };
        fetch(
          myConstClass.SERVER_URL + "voteTheme/themeAnswer",
          requestOptions
        ).then((response) => {
          response.json();
          if (response.status == 200) {
            setSnackbarMessage("Uspješno ste glasali");
            setSeverity("success");
            setSnackbar(true);
            setCheckVoting(false);
          } else {
            setSnackbarMessage("Doslo je do greske");
            setSeverity("error");
            setSnackbar(true);
            setCheckVoting(true);
          }
        });
      }
    }
  }

  function handleVoteRecall() {
    const newdata = { ...voteRecallData };
    newdata["datum_povlacenja"] = Moment().format(
      "YYYY-MM-DD HH:mm:ss"
    ) as string;
    setVoteRecallData(newdata);

    if (isRep) {
      const requestOptionRecallRep = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newdata),
      };
      fetch(
        myConstClass.SERVER_URL + "voteTheme/voteRecallRep",
        requestOptionRecallRep
      ).then((response) => {
        response.json();
        if (response.status == 200) {
          setSnackbarMessage("Uspješno ste opozvali glas");
          setSeverity("success");
          setSnackbar(true);
          setCheckVoting(true);
        } else {
          setSnackbarMessage("Doslo je do greske");
          setSeverity("error");
          setSnackbar(true);
        }
      });
    } else {
      // provjera opoziva li se glas ili dodjela glasa

      if (predstavnik.ime == "") {
        // za opozivanje glasa
        const requestOptions3 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newdata),
        };
        fetch(myConstClass.SERVER_URL + "voteTheme/voteRecall", requestOptions3)
          .then((response) => response.json())
          .then(setCheckVoting);
      } else {
        // opozivanje dodjele glesa
        const requestOptions3 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(predstavnik),
        };
        fetch(
          myConstClass.SERVER_URL + "representative/recallDelegation",
          requestOptions3
        )
          .then((response) => response.json())
          .then(setCheckVoting);
      }
    }
  }

  return (
    <>
      <Snackbar open={snackbar} autoHideDuration={3000} onClose={snackbarClose}>
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>

      <Container maxWidth="md" sx={{ mt: 10 }}>
        <Item>
          {data.map((row) => (
            <>
              <TemaBreadcrumbs
                currentPage={row.naslov}
                themeLocationData={themeLocationData}
              />
              <Card variant="outlined" sx={{ width: 1 }}>
                <CardContent sx={{ mb: 2.5 }}>
                  <Grid container spacing={2}>
                    {liveData ? (
                      <>
                        <Grid item xs={7}>
                          <Item>
                            <Typography
                              sx={{ fontSize: 14, mb: 1.5 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              Glasovanje
                            </Typography>

                            <Typography
                              sx={{ mb: 1.5, fontSize: 25 }}
                              variant="h5"
                              component="div"
                            >
                              {row.naslov}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                              Kategorija teme <br /> 
                              {row.naziv}
                            </Typography>
                            <Typography variant="body2" sx={{padding: '50px'}}>
                              Opis teme <br /> <br /> 
                              {row.opis}
                            </Typography>

                            <Box sx={{ mt: 5 }}>
                              {/* <button onClick={() => exportToExcel(themeData, 'downloadfilename', true)}>
                              Download using method
                            </button> */}
                              {/* <Button
                                variant="contained"
                                color="success"
                                onClick={() =>
                                  exportToExcel(
                                    themeData,
                                    "downloadfilename",
                                    true
                                  )
                                }
                                startIcon={<DownloadIcon />}
                              >
                                Rezultati excel
                              </Button> */}
                            </Box>
                          </Item>
                        </Grid>

                        <Grid item xs={5}>
                          <Item>
                            <DynamicChart
                              tema_id={themeID}
                              update={updateVotingData}
                              updateRecal={voteRecallData}
                            />
                          </Item>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12}>
                          <Item>
                            <Typography
                              sx={{ fontSize: 14, mb: 1.5 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              Glasovanje
                            </Typography>

                            <Typography
                              sx={{ mb: 1.5, fontSize: 25 }}
                              variant="h5"
                              component="div"
                            >
                              {row.naslov}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                              Kategorija teme:
                              {row.naziv}
                            </Typography>

                            <Typography variant="body2" sx={{padding: '50px'}}>
                              Opis teme <br /> <br /> <br />
                              {row.opis}
                            </Typography>

                            <Box
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                              }}
                            >
                              <List>
                                {data[0].dokument.map((file, index) => (
                                  // { file == '' ? <></> : <></>}

                                  <ListItem disablePadding>
                                    <ListItemButton
                                      onClick={() => getFile(file)}
                                    >
                                      <ListItemIcon>
                                        <AttachFileIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={file} />
                                    </ListItemButton>
                                  </ListItem>
                                ))}
                              </List>
                            </Box>
                          </Item>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>

                {themeDatePassed && (
                  <CardActions sx={{ mb: 1.5, justifyContent: "center" }}>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={handleVote}
                      value="1"
                      disabled={checkVoting ? false : true}
                    >
                      Za
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleVote}
                      value="2"
                      disabled={checkVoting ? false : true}
                    >
                      Protiv
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleVote}
                      value="3"
                      disabled={checkVoting ? false : true}
                    >
                      Suzdržan
                    </Button>
                  </CardActions>
                )}

                {predstavniciData.length > 0 && !isRep ? (
                  <CardActions sx={{ mb: 1.5, justifyContent: "center" }}>
                    <Button
                      disabled={checkVoting ? false : true}
                      variant="contained"
                      color="success"
                      onClick={openFromParent}
                    >
                      Odaberi svog predstavnika
                    </Button>
                  </CardActions>
                ) : (
                  <></>
                )}

                {themeDatePassed && (
                  <CardActions sx={{ mb: 1.5, justifyContent: "center" }}>
                    <Button
                      disabled={checkVoting ? true : false}
                      variant="contained"
                      color="error"
                      onClick={handleVoteRecall}
                    >
                      Opozovi
                    </Button>
                  </CardActions>
                )}
                {predstavnik.ime != "" && (
                  <CardActions sx={{ mb: 1.5, justifyContent: "center" }}>
                    <Typography variant="h6" component="div">
                      Vaš glas je delegiran predstavniku: {predstavnik.ime}{" "}
                      {predstavnik.prezime}
                    </Typography>
                  </CardActions>
                )}
              </Card>
            </>

            // prikaz rezultata kao graf
          ))}

          <DialogDelegacijaGlasa
            dataPredstavnici={predstavniciData}
            IsModalOpened={modalIsOpen}
            handleCloseModalSuccess={handleCloseModalSuccess}
            onCloseModal={handleCloseModal}
            temaID={themeID}
          />
        </Item>
      </Container>
    </>
  );
}

export default Tema;
