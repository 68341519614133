import {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatePickerDefault({id, onChange, defaultValue, disabled}) {
  const [value, setValue] = useState<Date | null>(null);
 
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue]); 

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {/* <DatePicker
        
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          onChange(id, newValue)
        }}
        disabled={disabled}
        inputFormat="dd/MM/yyyy"
        renderInput={(params) => <TextField   size="small" {...params} />}
      /> */}
    </LocalizationProvider>
  );
}
