import * as React from "react";
import { useEffect, useState, forwardRef } from "react";
import { styled } from "@mui/material/styles";
import { Button, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as myConstClass from "Constants";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import PredstavniciList from "componenets/Elements/Lists/PredstavnciList/PredstavniciList";
import { Predstavnik } from "model/Predstavnik";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Moment from "moment";
import { useUser } from "auth/useUser";

import { Buffer } from "buffer";
Buffer.from("anything", "base64");

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const avatarStyle = {
  width: "150px",
  height: "150px",
};

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function DialogDelegacijaGlasa({
  dataPredstavnici,
  IsModalOpened,
  handleCloseModalSuccess,
  onCloseModal,
  temaID,
}) {
  const user = useUser();
  const { id, korisnickoIme, ulogaID } = user;

  const getImage = (buffer) => {
    if (buffer != null) {
      const image_base64 = Buffer.from(buffer).toString();
      return image_base64;
    } else return "";
  };

  const [selectedRepresentive, setSelectedRepresentive] =
    useState<Predstavnik>();

  function setSelection(representativeData) {
    setSelectedRepresentive(representativeData);
  }

  useEffect(() => {
  }, [selectedRepresentive]);

  function onModalClose() {
    onCloseModal();
  }

  const [checkbox, setCheckbox] = useState(false);

  // Snack bar states
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  const handleChange = () => {
    setCheckbox(!checkbox);
  };

  function snackbarClose(event) {
    setSnackbar(false);
  }

  const [data, setData] = useState({
    id: 1,
  });

  const [represetativeData, setRepresentativeData] = useState({
    ovlastenje_id: "",
    datum_ovlastenja: "",
    korisnik_id: `${id}`,
    glas: "",
  });

  function handleChoice(event) {
    const newdata = { ...represetativeData };
    newdata["ovlastenje_id"] = selectedRepresentive?.id.toString() || "";
    newdata["datum_ovlastenja"] = Moment().format(
      "YYYY-MM-DD HH:mm:ss"
    ) as string;
    newdata["glas"] = "1";
    newdata["tema_id"] = temaID;
    setRepresentativeData(newdata);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newdata),
    };
    fetch(
      myConstClass.SERVER_URL + "representative/voteRepresentative",
      requestOptions
    ).then((response) => {
      response.json();
      if (response.status == 200) {
        setSnackbarMessage("Uspješno ste dodjeli glas predstavniku!");
        setSeverity("success");
        setSnackbar(true);
        handleCloseModalSuccess();
      } else {
        setSnackbarMessage("Doslo je do greske");
        setSeverity("error");
        setSnackbar(true);
      }
    });
  }

  return (
    <div>
      <Snackbar open={snackbar} autoHideDuration={3000} onClose={snackbarClose}>
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>

      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={IsModalOpened}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Odabir predstavnika"}</DialogTitle>
        <Divider />

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Item>
                  <PredstavniciList
                    data={dataPredstavnici}
                    setSelectedRepresentive={setSelection}
                  />
                </Item>
              </Grid>

              <Grid item xs={8}>
                <Item>
                  <Box sx={{ height: "60vh", width: "80%" }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        <Item>
                          {" "}
                          {selectedRepresentive?.ime}{" "}
                          {selectedRepresentive?.prezime}
                          <br />
                          LinkedIn: {selectedRepresentive?.linkedIn}
                        </Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>
                          {" "}
                          <Avatar
                            sx={avatarStyle}
                            alt="representative image"
                            src={`data:image/png;base64,${getImage(
                              selectedRepresentive?.slika
                            )}`}
                          />
                        </Item>
                      </Grid>
                    </Grid>
                    <br />
                    {selectedRepresentive?.biografija}
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ width: "50%" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Item></Item>
              </Grid>
              <Grid item>
                <Item>
                  <Button
                    onClick={(e) => onModalClose()}
                    variant="contained"
                    color="error"
                  >
                    Odustani
                  </Button>
                  <Button
                    onClick={(e) => handleChoice(e)}
                    variant="contained"
                    color="success"
                  >
                    Potvrdi
                  </Button>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
