import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Switch from 'componenets/Elements/Switch/Switch';
import { convertToRaw } from 'draft-js'
import draftToHtml from "draftjs-to-html";
import { styled } from '@mui/material/styles';


import Modal from '@mui/material/Modal';
import EmailIcon from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { TablePagination } from '@mui/material';
import * as myConstClass from 'Constants';
import { useFetch } from 'util/useFetch';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useUser } from "auth/useUser";

import { Snackbar } from '@mui/material';

import MUIRichTextEditor from 'mui-rte'

// Modal style
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 400,
  bgcolor: 'background.paper',
  border: 'none !important',
  boxShadow: 24,
  p: 4,
};

const defaultTheme = createTheme();

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        // border: '1px solid black',
        marginTop: 2,
        width: "100%",
        marginBottom: 20,
        backgroundColor: "white",
        paddingBottom: 24,

      },
      toolbar: {
        // border: '1px solid black',
        marginTop: 2,
        width: "100%",
        marginBottom: 20,
        backgroundColor: "white",

      },
      editor: {
        borderBottom: "1px solid white",
        width: "100%",
        height: 180,
        overflow: 'auto'
      }
    }
  }
});

const Item = styled(Paper)(({ theme }) => ({

  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none'
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function KorisniciTable({ filteredData }) {
  const user = useUser();

  // return loading compoennt
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [update, setUpdate] = useState(true)

  const [korisniciData, setKorisniciData] = useState<any[]>([])

  const [emailContent, setEmailContent] = useState({
    content: ""
  })
  // Modal states
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState('warning');

  useEffect(() => {

    setKorisniciData(filteredData)

  }, [filteredData]);

  const onEditorChange = event => {

    const currentContent = event.getCurrentContent();
    const contentRaw = convertToRaw(currentContent);
    const value = currentContent.hasText() ? draftToHtml(contentRaw) : "";

    const newdata = { ...emailContent }
    newdata["content"] = value
    setEmailContent(newdata)
  }

  function sendEmail() {


    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(emailContent)
    };
    fetch(myConstClass.SERVER_URL + 'sendEmail', requestOptions)
      .then(response => {
        response.json()
        if (response.status == 200) {
          setSnackbarMessage("Email je poslan")
          setSeverity("success")
          setSnackbar(true)

        } else {
          setSnackbarMessage("Doslo je do greske")
          setSeverity("error")
          setSnackbar(true)

        }
      })


  }

  function userStatus(status) {
    if (status) {
      return "Aktivan"
    } else {
      return "Blokiran"
    }
  }

  function snackbarClose(event) {
    setSnackbar(false)
  }

  function calcTime(apiTime) {

    if (apiTime == null)
      return "Nikad"

    var date = new Date(apiTime);
    var today = new Date();

    date.setHours(date.getHours() + 2);

    var time = Math.abs((today.getTime() - date.getTime()) / 1000)


    if (time < 60) {
      return `Prije ${time} sekundi`
    } else if (time < 3600) {
      return `Prije ${Math.ceil(time / 60)} minuta`
    } else if (time < 3600 * 60) {
      return `Prije ${Math.ceil(time / 3600)} sati`
    } else if (time < 3600 * 60 * 24) {
      return `Prije ${Math.ceil(time / 3600 * 60)} dana`
    } else {
      return `Nikad`
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Snackbar
        open={snackbar}
        autoHideDuration={3000}
        onClose={snackbarClose}
      >
        <Alert onClose={snackbarClose} severity={severity == "success" ? "success" : severity == "error" ? "error" : severity == "info" ? "info" : "warning"} sx={{ width: '100%' }}>
          <span> {snackbarMessage} </span>
        </Alert>

      </Snackbar>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead >
            <TableRow >
              <TableCell sx={{ fontWeight: 600, color: '#91A3B0' }}>UID</TableCell>
              <TableCell sx={{ fontWeight: 600, color: '#91A3B0' }} align="left">Ime i prezime</TableCell>
              <TableCell sx={{ fontWeight: 600, color: '#91A3B0' }} align="left">Uloga</TableCell>
              <TableCell sx={{ fontWeight: 600, color: '#91A3B0' }} align="left">Status</TableCell>
              <TableCell sx={{ fontWeight: 600, color: '#91A3B0' }} align="left">Email</TableCell>
              <TableCell sx={{ fontWeight: 600, color: '#91A3B0' }} align="right">Prijavljen</TableCell>
              <TableCell>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center">
                     {user.adminWrite && 
                  <Typography sx={{ mt: 2, fontWeight: 600, color: '#91A3B0' }} variant="body2" display="block" gutterBottom>
                    Aktivacija < br /> Korisnika
                  </Typography>}

                  <Typography sx={{ mt: 2, ml: 4, fontWeight: 600, color: '#91A3B0' }} variant="body2" display="block" gutterBottom>
                    Pošalji < br /> Mail
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((korisnik) => (
                <TableRow
                  key={korisnik.uid}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {korisnik.uid}
                  </TableCell>
                  <TableCell align="left">{korisnik.korisnikime}</TableCell>
                  <TableCell align="left">{korisnik.uloga}</TableCell>
                  <TableCell align="left">{userStatus(korisnik.status)}</TableCell>
                  <TableCell align="left">{korisnik.email}</TableCell>
                  <TableCell align="right">{calcTime(korisnik.logedin)}</TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center">
                      {user.adminWrite &&  <Switch value={korisnik.status} id={korisnik.uid} url="user/activation" chnageState={update} />}
                      <EmailIcon onClick={handleOpen} sx={{ ml: 4 }} color="action" />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Email sadržaj
          </Typography>

          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            sx={{ mt: 2 }}
          >
            <Grid item>
              <ThemeProvider theme={defaultTheme}>
                <MUIRichTextEditor
                  label="Type something here..."
                  // onSave={save}
                  inlineToolbar={true}
                  onChange={onEditorChange}
                />
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Grid item>
                  <Button variant="outlined" onClick={sendEmail} color="success">Pošalji</Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" onClick={handleClose} color="error">Zatvori</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  )
  //}
}