import "./Login.css";
import { useEffect, useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import fondovi from "../../../fondovi_slika.jpg";

import MuiAlert, { AlertProps } from "@mui/material/Alert";
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Snackbar,
} from "@mui/material";
import * as myConstClass from "Constants";
import { useToken } from "auth/useToken";
import FileUpload from "./TestUploadFiles";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login(this: any) {
  const navigate = useNavigate();

  const { token, setToken } = useToken();

  const [redirect, setRedirect] = useState(false);

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  const [data, setData] = useState({
    korisnickoIme: "",
    lozinka: "",
  });

  function snackbarClose(event) {
    setSnackbar(false);
  }

  async function loginSuccess() {
    setRedirect(true);
  }

  useEffect(() => {
    if (redirect) navigate("/", { state: true });
  }, [redirect]);

  function submit() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    fetch(myConstClass.SERVER_URL + "user/login", requestOptions)
      .then(function (response) {
        if (response.status == 200) {
        } else {
          setSnackbarMessage("Doslo je do greske u komunikaciji s serverom");
          setSeverity("error");
          setSnackbar(true);
        }
        return response.json();
      })
      .then(function (response) {
        let token = response.response;

        if (token != null) {
          setSnackbarMessage("Uspješan login");
          setSeverity("success");
          setSnackbar(true);
          setToken(token);
          loginSuccess();
        } else {
          setSnackbarMessage(
            "Unjeli ste neispravno korisničko ime ili lozinku"
          );
          setSeverity("error");
          setSnackbar(true);
        }
      });
  }

  function handleChange(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  return (
    <>
      <Snackbar open={snackbar} autoHideDuration={3000} onClose={snackbarClose}>
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>
      <Container maxWidth="sm" sx={{ mt: 4, pt: 2 }}>
        <Typography variant="h5" component="div" align="center">
          Prijava
        </Typography>
        <Box
          margin="auto"
          display="flex"
          sx={{
            height: 185,
            width: 350,
            maxHeight: { xs: 235, md: 170 },
            maxWidth: { xs: 350, md: 250 },
          }}
        />
        <Box
          component="div"
          sx={{
            "& > :not(style)": { m: 1 },
          }}
          // noValidate
        >
          <TextField
            id="korisnickoIme"
            value={data.korisnickoIme}
            onChange={handleChange}
            label="Korisnicki račun"
            type="username"
            name="username"
            variant="outlined"
            fullWidth={true}
            required
          />
          <TextField
            id="lozinka"
            value={data.lozinka}
            onChange={handleChange}
            label="Lozinka"
            type="password"
            name="password"
            variant="outlined"
            fullWidth={true}
            required
          />

          <br></br>

          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              variant="contained"
              disabled={data.korisnickoIme == "" || data.lozinka == ""}
              onClick={submit}
            >
              Prijava
            </Button>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <img
              src={fondovi}
              alt="fondovi slika"
              style={{ maxWidth: "100%" }}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
}
export default Login;
