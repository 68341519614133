import React from "react";
import { useState, useEffect } from "react";

import { BrowserRouter, Route } from "react-router-dom";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Button,
  Box,
  Card,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as myConstClass from "Constants";

function LinearnoMjerilo({ pitanjeID, answerChange, index }) {
  const [from, setFrom] = React.useState(1);

  const [to, setTo] = React.useState(5);

  const [odgovori, setOdgovori] = useState([
    {
      naziv_odgovora: "start",
    },
    {
      naziv_odgovora: "end",
    },
  ]);

  function onAnswerChange(value) {
    answerChange(index, value);
  }

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "poll/odgovori?pitanje=" + pitanjeID)
      .then((response) => response.json())
      .then((data) => {
        setOdgovori(data);
      });
  }, []);

  const getLinearScale = () => {
    // let content = [];
    const content: any[] = [];

    for (let i = from; i <= to; i++) {
      content.push(
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>{i}</Grid>
            <Grid item>
              <FormControlLabel
                value={i}
                control={<Radio onChange={() => onAnswerChange(i)} />}
                label=""
                sx={{ m: 0 }}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return content;
  };

  return (
    <div className="wrapper">
      <Grid container direction="column">
        <Grid item>
          {/* <FormControl>
                        <Grid container
                            direction="row"
                            alignItems="center">
                            <Grid item >
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={from}
                                    defaultValue={'1'}
                                    onChange={handleChangeFrom}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item sx={{ p: 2 }} >
                                do
                            </Grid>
                            <Grid item alignContent="right" >
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={to}
                                    defaultValue={'2'}
                                    onChange={handleChangeTo}
                                >
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </FormControl> */}
        </Grid>

        <Grid item></Grid>
        <Grid item>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item>{odgovori[0].naziv_odgovora}</Grid>

              {getLinearScale()}
              <Grid item>{odgovori[1].naziv_odgovora}</Grid>
            </Grid>
          </RadioGroup>
        </Grid>
      </Grid>
    </div>
  );
}

export default LinearnoMjerilo;
