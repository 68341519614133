import React, { useState, useEffect } from 'react'
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Color } from 'chart.js';
import * as myConstClass from 'Constants';
import { CategoryScale } from 'chart.js';
import { CardActions, CardContent, TextField, Typography, Button, Box, Card, Grid, Divider, Switch, Container } from '@mui/material';



ChartJS.register(CategoryScale);
// const labels = ["January", "February", "March"];
ChartJS.register(ArcElement, Tooltip, Legend);

// odgovori

// data
// zbrojevi




const pieOptions = {
  plugins: {
    legend: {
      display: true,
      position: 'right' as const,
      labels: {
        font: {
          size: 12,
        },
      },
    },
  },
};


const PieChart = ({ title, pitanje_id }) => {

  //const labels = ["January", "February", "March", "April", "May", "June", "July"];

  const [labels, setLables] = useState(["January", "February", "March", "April", "May", "June", "July"]);
  const [values, setValues] = useState([20, 59, 80, 81, 56, 55, 40]);

  const [numberOfVotes, setNumberOfVotes] = useState(0);

  const [pitanja, setPitanja] = useState([{
    opis_odgovor: ""
  }]);

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + 'poll/results/multipleChoice?pitanje=' + pitanje_id)
      .then((response) => response.json())
      .then(data => {

        setPitanja(data)

        // map naziv_odgovora u labels
        let newLables = data.map((user) => {
          return user.naziv_odgovora.trim();
        });
        setLables(newLables);

        let numVotes = 0;

        let newData = data.map((user) => {
          numVotes += parseInt(user.num)
          return user.num;
        });

        setNumberOfVotes(numVotes)

        setValues(newData)

      })

  }, []);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "My First dataset",
        data: values,
        backgroundColor: [
          'rgba(255, 99, 132, 0.3)',
          'rgba(54, 162, 235, 0.3)',
          'rgba(255, 206, 86, 0.3)',
          'rgba(255, 127, 81, 0.3)',
          'rgba(75, 192, 192, 0.3)',
          'rgba(208, 0, 0, 0.3)',
          'rgba(153, 102, 255, 0.3)',

        ],


      },
    ],
  };

  return (
    <>
      {title}
      <br />
      Broj odgovora: {numberOfVotes}
   
      <Container maxWidth="sm">

        <Pie data={data} options={pieOptions} />
      </Container>

    </>
  );
};
export default PieChart;