import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Grid,
  Box,
  Button,
  InputLabel,
  OutlinedInput,
  FormControl,
  Paper,
  Checkbox,
  styled,
  Snackbar,
  Alert,
} from "@mui/material";
import * as myConstClass from "../../../../Constants";
import { useParams } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

export default function OdgovornostiTable() {
  const { selectedRole } = useParams();

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  function snackbarClose(event) {
    setSnackbar(false);
  }

  const [permission, setPermission] = useState({
    permissionName: "",
  });

  function handleChange(e) {
    const newdata = { ...permission };
    newdata[e.target.id] = e.target.value;
    setPermission(newdata);
  }

  const [permissions, setPermissions] = useState([
    {
      name: "",
      permission: "",
      view: "",
      create: "",
      edit: "",
      deletePrivilage: "",
      add: "",
    },
  ]);

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "permissions?role=" + selectedRole)
      .then((response) => response.json())
      .then(setPermissions);
  }, []);

  function rowCheck(e: React.ChangeEvent<HTMLInputElement>) {
    const newPermissionRow = [...permissions];

    // add control here
    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i].permission == e.target.name) {
        if (e.target.checked === true) {
          newPermissionRow[i].view = "1";
          newPermissionRow[i].create = "1";
          newPermissionRow[i].edit = "1";
          newPermissionRow[i].deletePrivilage = "1";
          newPermissionRow[i].add = "1";
        } else {
          newPermissionRow[i].view = "0";
          newPermissionRow[i].create = "0";
          newPermissionRow[i].edit = "0";
          newPermissionRow[i].deletePrivilage = "0";
          newPermissionRow[i].add = "0";
        }
      }
    }

    setPermissions(newPermissionRow);
  }

  function submit() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(permissions),
    };
    fetch(
      myConstClass.SERVER_URL + "permissions?role=" + selectedRole,
      requestOptions
    ).then((response) => {
      response.json();
      if (response.status == 200) {
        setSnackbarMessage("Odgovornosti su spremljene");
        setSeverity("success");
        setSnackbar(true);

        //  alert("Novi korisnik je dodan")
      } else {
        setSnackbarMessage("Doslo je do greske");
        setSeverity("error");
        setSnackbar(true);
      }
    });
  }

  function add() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(permission),
    };
    fetch(myConstClass.SERVER_URL + "permissions/add", requestOptions).then(
      (response) => {
        response.json();
        if (response.status == 200) {
          setSnackbarMessage("Nova odgovornost je dodana");
          setSeverity("success");
          setSnackbar(true);

          fetch(myConstClass.SERVER_URL + "permissions?role=" + selectedRole)
            .then((response) => response.json())
            .then(setPermissions);
          //  alert("Novi korisnik je dodan")
        } else {
          setSnackbarMessage("Doslo je do greske");
          setSeverity("error");
          setSnackbar(true);
        }
      }
    );
  }

  function columnCheck(e) {
    const newPermissionRow = [...permissions];

    // don't check last in lengths

    for (var i = 0; i < permissions.length; i++) {
      if (e.target.checked == true) {
        newPermissionRow[i][e.target.name] = "1";
      } else {
        // newPermissionRow[e.target.value][property]
        newPermissionRow[i][e.target.name] = "0";
      }
    }
    setPermissions(newPermissionRow);
  }

  function checkboxValue(value) {
    if (value == 0) return false;
    else return true;
  }

  function checkboxChange(e) {
    const newPermissionRow = [...permissions];

    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i].permission == e.target.value) {
        if (e.target.checked == true) {
          newPermissionRow[i][e.target.name] = "1";
        } else {
          // newPermissionRow[e.target.value][property]
          newPermissionRow[i][e.target.name] = "0";
        }
      }
    }

    setPermissions(newPermissionRow);
  }

  return (
    <>
      <Snackbar
        // anchorOrigin={{vertical: 'center', horizontal: 'center'}}
        open={snackbar}
        autoHideDuration={3000}
        onClose={snackbarClose}

        // message = { <span> {snackbarMessage} </span>}
      >
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align="right">Pregled</StyledTableCell>
              <StyledTableCell align="right">Kreiranje</StyledTableCell>
              <StyledTableCell align="right">Uređivanje</StyledTableCell>
              <StyledTableCell align="right">Brisanje</StyledTableCell>
              <StyledTableCell align="right">Dodavanje</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row"></StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
              <StyledTableCell align="right">
                <Checkbox name="view" onChange={columnCheck} />
              </StyledTableCell>
              <StyledTableCell align="right">
                <Checkbox name="create" onChange={columnCheck} />
              </StyledTableCell>
              <StyledTableCell align="right">
                <Checkbox name="edit" onChange={columnCheck} />
              </StyledTableCell>
              <StyledTableCell align="right">
                <Checkbox name="deletePrivilage" onChange={columnCheck} />
              </StyledTableCell>
              <StyledTableCell align="right">
                <Checkbox name="add" onChange={columnCheck} />
              </StyledTableCell>
            </StyledTableRow>

            {permissions.map((row) => (
              <StyledTableRow key={row.permission}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Checkbox name={row.permission} onChange={rowCheck} />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Checkbox
                    value={row.permission}
                    name="view"
                    checked={checkboxValue(row.view)}
                    onChange={checkboxChange}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Checkbox
                    value={row.permission}
                    name="create"
                    checked={checkboxValue(row.create)}
                    onChange={checkboxChange}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Checkbox
                    value={row.permission}
                    name="edit"
                    checked={checkboxValue(row.edit)}
                    onChange={checkboxChange}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Checkbox
                    value={row.permission}
                    name="deletePrivilage"
                    checked={checkboxValue(row.deletePrivilage)}
                    onChange={checkboxChange}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Checkbox
                    value={row.permission}
                    name="add"
                    checked={checkboxValue(row.add)}
                    onChange={checkboxChange}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Item>
            <Box mt={4}>
              <Grid container spacing={3}>
                <Grid item>
                  <FormControl required fullWidth sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Odgovornost
                    </InputLabel>
                    <OutlinedInput
                      id="permissionName"
                      value={permission.permissionName}
                      onChange={handleChange}
                      label="Opis"
                    />
                  </FormControl>
                </Grid>
                <Grid item sx={{ m: 3 }}>
                  <Button variant="contained" onClick={add}>
                    Dodaj
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Item>
        </Grid>
        <Grid item>
          <Item>
            <Button onClick={submit} variant="contained" color="success">
              Spremi
            </Button>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}
