import { useState, useContext, useEffect } from "react";
import { UserContext } from "App";

export const useToken = () => {
  const userContext = useContext(UserContext);

  const [token, setTokenInternal] = useState(() => {
    return localStorage.getItem("token");
  });

  const setToken = (newToken) => {
    localStorage.setItem("token", newToken);
    setTokenInternal(token);
    userContext.setUser(newToken);
  };

  const removeToken = () => {
    localStorage.removeItem("token");
    updateUser(null);
  };

  const updateUser = (token) => {
    if (token == null) {
      userContext.setUser(null);
    } else {
      const encodedPayload = token.split(".")[1];
      userContext.setUser(JSON.parse(atob(encodedPayload)));
    }
  };

  return { token, setToken, removeToken };
};
