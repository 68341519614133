import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "./useUser";
import { useToken } from "./useToken";

const PrivateRoute = (props) => {
  const user = useUser();
  const token = useToken();
  // checks if the JWT token has expiered
  function checkExperation() {
    if (!user) return !user;

    var dateNow = new Date();
    if (user.exp * 1000 < dateNow.getTime()) {
      token.removeToken();
      return true;
    }
  }

  // if the user is not loged in redirect to login
  return checkExperation() ? (
    <Navigate to="/login" />
  ) : (
    // children
    <Outlet />
  );
};

export default PrivateRoute;
