import { useState, useEffect, useContext } from 'react'
import { useToken }  from './useToken'
import { UserContext } from 'App'

export const useUser = () => {

    // const {token} = useToken();
    const userContext = useContext(UserContext);

    const getPayloadFromToken = token => {
        const encodedPayload = token.split('.')[1]
        return JSON.parse(atob(encodedPayload))
    }

    const [user, setUser] = useState(() => {
        if (!userContext.user || userContext.user == null) return null;
        return getPayloadFromToken(userContext.user)
    })

    useEffect(() => {

        if (!userContext.user) {
            setUser(null)
        } else {
            setUser(getPayloadFromToken(userContext.user))
        }
    }, [userContext.user])

    return user;
}