
import { useEffect, useState } from 'react';
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import * as myConstClass from 'Constants';
import { CardActions, CardContent, TextField, Typography, Button, Box, Card, Grid, Divider, Switch, Container } from '@mui/material';

import { LineController, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';


const ShortAnswers = ({ title, pitanje_id }) => {

  const [pitanja, setPitanja] = useState([{
    opis_odgovor: ""
  }]);

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + 'poll/results/shortAnswers?pitanje=' + pitanje_id)
      .then((response) => response.json())
      .then(data => {
        setPitanja(data)
        // dohvati pitanja od te ankete

      })

  }, []);

  return (
    <div>

      {title}
      <br />
      Broj odgovora: {pitanja.length}

      {pitanja.map((pitanje, index) => (

        <Card variant="outlined" sx={{ m: 1, p: 1, backgroundColor: '#F8F8FB', borderRadius: '4px', border: 'none' }}>{pitanje.opis_odgovor}</Card>

      ))}


    </div>
  );
};

export default ShortAnswers;