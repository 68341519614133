import { useState, useEffect } from 'react';
import {TextField, Autocomplete, Box, FormControl} from '@mui/material';
import * as myConstClass from 'Constants';
import './Autocomplete.css';

export default function CustomAutocomplete({name, tableName, zupanija, id, onChange}) {

    const [selectData, setSelectData] = useState<any[]>([])

  const [value, setValue] = useState(null);
  const [idObject, setIdObject] = useState(null);
  const [title, setTitle] = useState(null);
    //myConstClass
  useEffect(() => {
    fetch(myConstClass.SERVER_URL + tableName + `/?zupanija=${zupanija}`)
      .then((response) => response.json())
      .then(setSelectData);
  }, [zupanija]); 
  return (
    <Box >
    <FormControl fullWidth>
    <Autocomplete
      onChange={(event, newValue) => {
        if (newValue) {
          setValue(newValue);
          setIdObject(newValue.id);
          setTitle(newValue.title);
        }
        onChange(id, newValue.id)
      }}
      disablePortal
      id="combo-box-demo"
      options={selectData}
      getOptionLabel={(option) => option.opis || ""}
      renderInput={(params) => <TextField {...params} label={name}  sx={{margin: 0}}/>}
    />
    </FormControl>
    </Box>
  );
}

