import {useState, useEffect} from 'react';
import {Box, InputLabel, MenuItem, FormControl, Select, SelectChangeEvent} from '@mui/material';
import * as myConstClass from 'Constants';

export default function BasicSelectMini({name, tableName, id, onChange, defaultValue}) {
  const [selectedValue, setSelectedValue] = useState('');

  const [selectData, setSelectData] = useState<any[]>([])

  useEffect(() => {

    fetch(myConstClass.SERVER_URL + tableName)
      .then((response) => response.json())
      .then(setSelectData);

  }, []); 

  useEffect(() => {
      setSelectedValue(defaultValue)
  }, [defaultValue]); 

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value as string);
    onChange(id, event)
  };

  return (
    <Box >
      <FormControl fullWidth>
        {/* <InputLabel id="demo-simple-select-label">{name}</InputLabel> */}
        <Select
        //   labelId="demo-simple-select-label"
          id={`select-${name}`}
          value={selectedValue}
        //   label={name}
          size="small"
          onChange={handleChange}>
        {
          selectData.map((selectOption) => (
            <MenuItem key={selectOption.id} value={selectOption.id}>{selectOption.opis}</MenuItem>

          ))}

        </Select>
      </FormControl>
    </Box>
  );
}
