import * as React from 'react';
import { useEffect, useState } from 'react';
import {Box, Button, Grid, Paper, OutlinedInput, InputLabel, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl, FormLabel} from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

// Sbackbar
import { Snackbar } from '@mui/material';

import BasicSelect from '../../BasicSelect/BasicSelect'
import BesicSelectDescription from '../../BasicSelect/BesicSelectZupanija'
import CustomAutocomplete from '../../Autocomplete/Autocomplete'

import GenderSelect from '../../BasicSelect/GenderSelect'
import * as myConstClass from 'Constants';
import BasicDatePicker from '../../BasicDatePicker/BasicDatePicker'

const Item = styled(Paper)(({ theme }) => ({

  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none'
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function NoviKorisnikForm() {

  // const { enqueueSnackbar } = useSnackbar();
  const [snackbar,setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity,setSeverity] = useState('warning');

  function snackbarClose (event) {
    setSnackbar(false)
  }

  const [zupanija, setZupanija] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const [data, setData] = useState({
        ime: "",
        prezime: "",
        mail: "",
        spol: "",
        datumRodenja: "",
        bracniStatus: "",
        obrazovanje: "",
        naselje: "",
        zanimanje: "",
        vjeroispovjest:"",
        status: "TRUE",
        lozinka: ""

  });
  

  function submit(){
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
  };
  fetch(myConstClass.SERVER_URL + 'user', requestOptions)
      .then(response => {response.json()
        if(response.status == 200) {
          setSnackbarMessage("Novi korisnik je dodan")
          setSeverity("success")
          setSnackbar(true)

        //  alert("Novi korisnik je dodan")
        } else {
          setSnackbarMessage("Doslo je do greske")
          setSeverity("error")
          setSnackbar(true)

        }
      }) 
  }

  // const handleClickVariant = (variant: VariantType) => () => {
  //   // variant could be success, error, warning, info, or default
  //   enqueueSnackbar('This is a success message!', { variant });
  // };

  function handleChange(e) {
      const newdata = {... data}
      newdata[e.target.id] = e.target.value
      setData(newdata)
    }

    function handleSelectChange(name, e) {
      const newdata = {... data}
      newdata[name] = e.target.value
      setData(newdata)
    }  

    function handleValueChange(name, value) {
      const newdata = {... data}
      newdata[name] = value
      setData(newdata)
    }


  //
  return (

    <>
    <Snackbar 
    // anchorOrigin={{vertical: 'center', horizontal: 'center'}}
    open = {snackbar}
    autoHideDuration= {3000}
    onClose = {snackbarClose}

    // message = { <span> {snackbarMessage} </span>}
    
    >
       <Alert onClose={snackbarClose} severity={severity  == "success" ? "success" : severity == "error" ? "error" : severity == "info" ? "info" : "warning"} sx={{ width: '100%' }}>
       <span> {snackbarMessage} </span>
        </Alert>

    </Snackbar>

    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1.5, width: '25ch' },
        // '& .MuiBox-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <Grid container spacing={15}>
          <Grid item xs={6}>

            {/* First column */}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Item>
                  <FormControl required fullWidth sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Ime</InputLabel>
                    <OutlinedInput
                      id="ime"
                      value={data.ime}
                      onChange={handleChange}
                      label="Ime"
                    />
                  </FormControl>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <FormControl required fullWidth sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Prezime</InputLabel>
                    <OutlinedInput
                      id="prezime"
                      value={data.prezime}
                      onChange={handleChange}
                      label="Prezime"
                    />
                  </FormControl>
                </Item>
              </Grid>
            </Grid>
            {/* Second column */}
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Item>
                  <FormControl required fullWidth sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
                    <OutlinedInput
                      id="mail"
                      value={data.mail}
                      onChange={handleChange}
                      label="Email"
                    />
                  </FormControl>
                </Item>
              </Grid>
              <Grid item xs={4}>
                <Item>
                  <FormControl required fullWidth sx={{ m: 1 }}>
                    <GenderSelect id="spol" onChange={handleSelectChange}/>
                  </FormControl>
                </Item>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Item>
                <FormControl required fullWidth sx={{ ml: 1 }}>
                  {/* <BasicDatePicker id="datumRodenja" onChange={handleValueChange} /> */}
                </FormControl>
              </Item>
            </Grid>
            {/* Third column */}
            <Grid item xs={8} >
              <Item sx={{ m: 1 }}>

                <BasicSelect id="bracniStatus" onChange={handleSelectChange} name="Bračni status" tableName="maritalStatus"  />

              </Item>
            </Grid>

            <Grid item xs={8} >
              <Item sx={{ m: 1 }}>
                <BasicSelect id="obrazovanje" onChange={handleSelectChange} name="Obrazovanje" tableName="education"  />
              </Item>
            </Grid>
            {/* fifth column */}

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Item sx={{ m: 1 }}>
                  <BesicSelectDescription  name="Županija" tableName="location/region" setZupanija={setZupanija} />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item sx={{ m: 1 }}>
                <CustomAutocomplete id="naselje" onChange={handleValueChange} name="Mjesto stanovanja" tableName="location/settlements" zupanija={zupanija}/>
                  {/* <SelectNaselja id="naselje" onChange={handleSelectChange} name="Mjesto stanovanja" tableName="naselje" zupanija={zupanija} /> */}
                </Item>
              </Grid>
            </Grid>


            <Grid item xs={8} >
              <Item sx={{ m: 1 }}>
                <BasicSelect id="zanimanje" onChange={handleSelectChange} name="Zanimanje" tableName="ocupation"  />
              </Item>
            </Grid>
            <Grid item xs={8} >
              <Item sx={{ m: 1 }}>
                <BasicSelect id="vjeroispovjest" onChange={handleSelectChange} name="Vjeroispovjest" tableName="religion"  />
              </Item>
            </Grid>
          </Grid>
          {/* Right part of the form */}
          <Grid item xs={4}>
            <Item sx={{ m: 1 }} >
              <FormControl fullWidth sx={{ m: 1 }}>

                <FormLabel id="demo-radio-buttons-group-label" sx={{textAlign: "left", m: 1}} >Status Korisnika</FormLabel>
                <RadioGroup
                  sx={{ ml: 4 }}
                  // defaultValue="TRUE"
                  id="status"
                  value={data.status}
                  onChange={handleChange}
                  // value={data.status}
                >
                  <FormControlLabel value="TRUE" control={<Radio id="status" />} label="Aktivan" />
                  <FormControlLabel value="FALSE" control={<Radio id="status"/>} label="Blokiran" />
                </RadioGroup>
              </FormControl>
            </Item>
            <Item sx={{ m: 1 }}>
              <FormControl fullWidth sx={{ m: 1 }}>

                <InputLabel htmlFor="outlined-adornment-amount">Lozinka</InputLabel>
                <OutlinedInput
                  id="lozinka"
                  disabled={isDisabled}
                  value={data.lozinka}
                  onChange={handleChange}
                  label="Lozinka"
                  type="password"
                />
              </FormControl>
            </Item>
            <Item>
              <FormControlLabel control={<Checkbox  onChange={() => setIsDisabled(!isDisabled)}  />} label="Generiraj privremenu lozinku" sx={{align: "left"}} />
            </Item>
            <Item sx={{ m: 3 }}>
              <Button  onClick={submit} variant="contained">Dodaj Korisnika</Button>
            </Item>
          </Grid>

        </Grid>
      </div>

    </Box>
    </>
  );
}


