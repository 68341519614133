import { Breadcrumbs, Container } from '@mui/material';
import OdgovornostiTable from 'componenets/Elements/Tables/OdgovornostiTable/OdgovornostiTable';
import { useParams } from "react-router-dom";

function Odgovornosti() {
  const { selectedRole } = useParams();

  return (
    <div className="wrapper">
      <Container maxWidth="lg">
        <h2>Upravljanje ulogom: {selectedRole}</h2>
        <Breadcrumbs />
        <OdgovornostiTable />
      </Container>
    </div>
  );
}

export default Odgovornosti;