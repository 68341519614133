import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  // event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function TemaBreadcrumbs({ currentPage, themeLocationData }) {
  let navigate = useNavigate();

  const navigateTo = (pageLink: string) => {
    // Replacing " " (space) to "" empty space
    const res = pageLink.replace(/ /g, "");
    navigate("../" + res, {
      replace: false,
      state: {
        urlName: `${themeLocationData.urlName}`,
        subscription: themeLocationData.subscription.bookmark,
        id: themeLocationData.theemID,
        icon: themeLocationData.icon,
      },
    });
  };

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link underline="hover" color="inherit" href="/">
          Početna stranica
        </Link>
        <Link underline="hover" color="inherit" href="/AktivneTeme">
          Teme
        </Link>
        <Link
          underline="hover"
          color="inherit"
          // href="/AktivneTeme"
          onClick={() => navigateTo("Teme/" + themeLocationData.urlName)}
        >
          {themeLocationData.urlName}
        </Link>

        <Typography color="text.primary">{currentPage}</Typography>
      </Breadcrumbs>
    </div>
  );
}
