import React, { createContext, useState } from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App'


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);


root.render(

  <App />

);

reportWebVitals();
