import { useState, useEffect, forwardRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { TextField, IconButton, Grid, Box, Button } from "@mui/material";
import * as myConstClass from "Constants";
import Switch from "componenets/Elements/Switch/Switch";
import {
  FormControl,
  OutlinedInput,
  InputLabel,
  TablePagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Snackbar, styled } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useUser } from "auth/useUser";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

export default function SifarnikLokacijaTable() {
  const user = useUser();

  const [rows, setSifarnici] = useState<any[]>([]);
  const [update, setUpdate] = useState(true);

  // Snack bar states
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  function snackbarClose(event) {
    setSnackbar(false);
  }

  //myConstClass
  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "location")
      .then((response) => response.json())
      .then(setSifarnici);
  }, [update]);

  const [data, setData] = useState({
    id: 0,
    opis: "",
  });

  const [filterData, setFilterData] = useState({
    filteredValue: "",
  });

  function searchFilter() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(filterData),
    };
    fetch(myConstClass.SERVER_URL + "location/filter", requestOptions)
      .then((response) => response.json())
      .then(setSifarnici);
  }

  function submit() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(myConstClass.SERVER_URL + "location", requestOptions).then(
      (response) => {
        response.json();
        if (response.status == 200) {
          setSnackbarMessage("Novi šifarnik je dodan");
          setSeverity("success");
          setUpdate(!update);
          setSnackbar(true);
          //  alert("Novi korisnik je dodan")
        } else {
          setSnackbarMessage("Doslo je do greske");
          setSeverity("error");
          setSnackbar(true);
        }
      }
    );
    // window.location.reload();

    // Refresh table component
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission behavior - reseting the page
      searchFilter();
    }
  };

  const handleInputChange = (e) => {
    const newdata = { ...filterData };
    newdata[e.target.id] = e.target.value;
    setFilterData(newdata);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Snackbar
        // anchorOrigin={{vertical: 'center', horizontal: 'center'}}
        open={snackbar}
        autoHideDuration={3000}
        onClose={snackbarClose}

        // message = { <span> {snackbarMessage} </span>}
      >
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 1 }}
        spacing={2}
      >
        <Grid item>
          <Item></Item>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 1 }}
            spacing={2}
          >
            <Grid item>
              <Item>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1.5, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl required fullWidth sx={{ m: 1 }}>
                    <TextField
                      id="filteredValue"
                      value={filterData.filteredValue}
                      onChange={handleInputChange}
                      label="Traži"
                      size="small"
                      onKeyPress={handleKeyPress}
                    />
                  </FormControl>
                </Box>
              </Item>
            </Grid>
            <IconButton
              sx={{ ml: 1, mt: 2 }}
              aria-label="search"
              onClick={searchFilter}
            >
              <SearchIcon style={{ fill: "black" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 450 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600, color: "#91A3B0" }}>
                  UID
                </TableCell>
                <TableCell
                  sx={{ fontWeight: 600, color: "#91A3B0" }}
                  align="left"
                >
                  Broj
                </TableCell>
                <TableCell
                  sx={{ fontWeight: 600, color: "#91A3B0" }}
                  align="left"
                >
                  Naziv
                </TableCell>
                <TableCell
                  sx={{ fontWeight: 600, color: "#91A3B0" }}
                  align="left"
                >
                  Naselje
                </TableCell>
                <TableCell
                  sx={{ fontWeight: 600, color: "#91A3B0" }}
                  align="left"
                >
                  Županija
                </TableCell>
                <TableCell
                  sx={{ fontWeight: 600, color: "#91A3B0" }}
                  align="left"
                >
                  Status
                </TableCell>

                {user.adminWrite && (
                  <TableCell
                    sx={{ fontWeight: 600, color: "#91A3B0" }}
                    align="center"
                  >
                    Aktiviraj/Deaktiviraj
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows

                .sort(function (a, b) {
                  return a.lokacijaid - b.lokacijaid;
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    key={row.lokacijaid}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{ minWidth: 90 }}>
                      {row.lokacijaid}
                    </TableCell>
                    <TableCell align="left">{row.brojpu}</TableCell>
                    <TableCell align="left">{row.nazivpu}</TableCell>
                    <TableCell align="left">{row.naselje}</TableCell>
                    <TableCell align="left">{row.zupanija}</TableCell>

                    <TableCell align="left">
                      {row.status ? "Aktivan" : "Neaktivan"}{" "}
                    </TableCell>

                    {user.AdminWrite && (
                      <TableCell align="center">
                        {" "}
                        <Switch
                          value={row.status ? true : false}
                          id={row.lokacijaid}
                          url="location/activation"
                          chnageState={update}
                        />{" "}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {/* <Box mt={2}>
    <Grid container spacing={3} >
        <Grid item   >
        <FormControl required fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-adornment-amount">Sifarnik</InputLabel>
            <OutlinedInput
                      id="opis"
                      value={data.opis}
                      onChange={handleChange}
                      label="Opis"
                    />
                  </FormControl>
        </Grid>
        <Grid item sx={{ m: 3 }}>
        <Button variant="contained" onClick={submit} >Dodaj</Button>
        </Grid>
      </Grid>
    </Box> */}
      </Box>
    </>
  );
}
