import { useState, useEffect } from "react";
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import * as myConstClass from "Constants";

export default function BasicSelect({
  name,
  tableName,
  id,
  onChange,
  dedaultValue,
  helperText,
  error,
  onClose,
}) {
  const [sifarnik, setSifarnik] = useState(dedaultValue);
  const [loading, setLoading] = useState(false);

  const [selectData, setSelectData] = useState<any[]>([]);

  useEffect(() => {
    setSifarnik(dedaultValue);
  }, [dedaultValue]);

  useEffect(() => {
    if (loading) onClose(sifarnik, "sifarnik_id");
    else setLoading(true);
  }, [sifarnik]);

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + tableName)
      .then((response) => response.json())
      .then(setSelectData);
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setSifarnik(event.target.value as string);
    onClose(event.target.value as string, "sifarnik_id");
  };

  useEffect(() => {
    setSifarnik(dedaultValue);
  }, [dedaultValue]);

  function closed(e) {
    onClose(sifarnik, "sifarnik_id");
  }

  return (
    <Box>
      <FormControl fullWidth error={error}>
        <InputLabel id="demo-simple-select-label">{name}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="sifarnik_id"
          value={sifarnik}
          label={name}
          onChange={handleChange}
          name={name}
          onClose={closed}
        >
          {selectData.map((selectOption) => (
            <MenuItem
              key={selectOption.id}
              value={selectOption.id}
              onClick={(e) => {
                onChange(selectOption.id, e, selectOption.naziv);
                onClose(selectOption.id, "sifarnik_id");
              }}
            >
              {selectOption.naziv}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText> {helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
}
