import * as React from "react";
import { useEffect } from "react";
import TextField from "@mui/material/TextField";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {parseISO} from 'date-fns'

export default function BasicDatePickerName({
  id,
  onChange,
  name,
  defaultValue,
  error,
  helperText,
  onClose,
}) {
  const [value, setValue] = React.useState<Date | null>(new Date(defaultValue));

  useEffect(() => {
    // setValue(new Date(defaultValue));
   
  }, [defaultValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {/* <DemoContainer components={['DatePicker']}> */}
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          label={name}
          value={value}
          format="dd/MM/yyyy"
          // onClose={onClose(value, id)}
          slotProps={{
            textField: {
              helperText: helperText,
              error: error,

              // onBlur: onClose(value, id)
            },
          }}
          onChange={(newValue) => {
            setValue(newValue);
            onChange(id, newValue);
            onClose(newValue, id);
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
