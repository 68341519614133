import { useState, useEffect, forwardRef } from "react";
import {
  Box,
  CardActions,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import { Theme } from "model/Theme";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import GavelIcon from "@mui/icons-material/Gavel";
import {
  Card,
  ButtonBase,
  Grid,
  Paper,
  TextField,
  CardHeader,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import * as myConstClass from "Constants";
import { useUser } from "auth/useUser";
import { Snackbar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import * as MuiIcons from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
// import FileUpload from "react-mui-fileuploader"
import { Input, FormLabel } from "@mui/material";
import FileUpload from "react-material-file-upload";
import UploadFiles from "../UploadFiles/upload-files.component";
import { Poll } from "model/Poll";

const AlertSnackbar = forwardRef<HTMLDivElement, AlertProps>(
  function AlertSnackbar(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }
);

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "start",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

// Modal style
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 300,
  bgcolor: "background.paper",
  border: "none !important",
  boxShadow: 24,
};

type CardProps = {
  poll: Poll;
};

export default function BasicCard({ poll }: CardProps) {
  // Modal states
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openSecond, setOpenSecond] = useState(false);
  const handleOpenSecond = () => setOpenSecond(true);
  const handleCloseSecond = () => setOpenSecond(false);

  // Snack bar states
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  function snackbarClose(event) {
    setSnackbar(false);
  }

  const user = useUser();
  const { id, korisnickoIme, ulogaID, lozinka_status } = user;

  let navigate = useNavigate();

  const [deleted, setDeleted] = useState(false);

  function isFinished(time) {
    let endTime = new Date(time);
    let currentTime = new Date();

    return endTime > currentTime;
  }

  const navigateTo = (pageLink: string, id: String) => {
    // Replacing " " (space) to "" empty space
    const res = pageLink.replace(/ /g, "");

    navigate("../" + res, {
      replace: false,
      state: { anketaID: `${poll.anketa_id}`, anketaNaslov: poll.naslov },
    });
  };

  function handleDate(date) {
    if (date == null) {
      return "";
    } else {
      let dateFormat = new Date(date);
      return `Trajanje do ${dateFormat.getDate()}-${dateFormat.getMonth()}-${dateFormat.getFullYear()}`;
    }
  }

  const handleStatus = (status, glasao) => {
    if (status == 1) {
      return (
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid>
              <Button
                size="large"
                variant="contained"
                color="error"
                onClick={() => {
                  handleOpen();
                }}
              >
                Obriši
              </Button>
            </Grid>
            <Grid>
              <Button
                size="large"
                variant="contained"
                color="success"
                onClick={() => {
                  navigateTo("../UrediAnketu", `${poll.anketa_id}`);
                }}
              >
                Uredi
              </Button>
            </Grid>
          </Grid>
        </>
      );
    } else if (status == 2 && glasao == "0") {
      return (
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid>
              <Button
                size="large"
                variant="contained"
                color="error"
                hidden={true}
              >
                Obriši
              </Button>
            </Grid>
            <Grid>
              <Button
                size="large"
                variant="contained"
                color="success"
                onClick={() => {
                  navigateTo(`../Anketa`, `${poll.anketa_id}`);
                }}
              >
                Sudjeluj
              </Button>{" "}
            </Grid>
          </Grid>
        </>
      );
    } else if (status == 2 && glasao != "0") {
      return (
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid>
              <Button
                size="large"
                variant="contained"
                color="error"
                hidden={true}
              >
                Obriši
              </Button>
            </Grid>
            <Grid>
              <Button size="small" variant="outlined" color="primary">
                Anketa odgovorena
              </Button>
            </Grid>
          </Grid>
        </>
      );
    } else if (status == 3) {
      return (
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid>
              <Button
                size="large"
                variant="contained"
                color="error"
                hidden={true}
              >
                Obriši
              </Button>
            </Grid>
            <Grid>
              <Button
                size="large"
                variant="contained"
                color="success"
                onClick={() => {
                  navigateTo(`../Rezultati`, `${poll.anketa_id}`);
                }}
              >
                Rezultati
              </Button>{" "}
            </Grid>
          </Grid>
        </>
      );
    }
  };

  function confirmation() {
    // remove from list

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(poll),
    };
    fetch(myConstClass.SERVER_URL + "poll/deletePoll", requestOptions).then(
      (response) => {
        if (response.status != 200) {
          setSnackbarMessage("Doslo je do greske");
          setSeverity("error");
          setSnackbar(true);
          return [];
        } else {
          setDeleted(true);
          // sakri anketu
        }
        handleCloseSecond();

        return response.json();
      }
    );

    // if remove ok hide from list

    // if not send error
  }

  return (
    <>
      <Snackbar
        // anchorOrigin={{vertical: 'center', horizontal: 'center'}}
        open={snackbar}
        autoHideDuration={3000}
        onClose={snackbarClose}

        // message = { <span> {snackbarMessage} </span>}
      >
        <AlertSnackbar
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </AlertSnackbar>
      </Snackbar>

      <Card sx={{ minWidth: 1 }} hidden={deleted}>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h5" component="div">
                    {poll.naslov}
                  </Typography>
                </Grid>
                <Grid item sx={{ ml: 2 }}></Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {handleDate(poll.datum_zavrsetka)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            sx={{ mb: 0 }}
          >
            <Grid item xs={8}>
              <Item sx={{ textAlign: "none" }}>
                <Typography variant="body2">
                  {poll.opis}
                  <br />
                </Typography>
              </Item>
            </Grid>

            <Grid item xs={4} sx={{ mt: 1 }}>
              <Item>{handleStatus(poll.status_id, poll.glasao)}</Item>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions></CardActions>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}> */}
        <Card sx={style}>
          <CardHeader
            sx={{ bgcolor: "#086AE2", color: "white" }}
            title={"Potvrda brisanja ankete"}
          />

          <CardContent>
            <Typography
              id="modal-modal-title"
              variant="body1"
              component="body"
              sx={{ mt: 3, mb: 1, pl: 4, pr: 4 }}
            >
              Klikom na potvrdi anketa će biti trajno obrisana i ne ćete ju više
              moći uređivati niti objaviti.
            </Typography>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
              sx={{ mt: 2, pl: 4, pr: 4 }}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 4 }}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      color="error"
                    >
                      Zatvori
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleClose();
                        handleOpenSecond();
                      }}
                      color="success"
                    >
                      Potvrdi
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* </Box> */}
      </Modal>

      <Modal
        open={openSecond}
        onClose={handleCloseSecond}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}> */}
        <Card sx={style}>
          <CardHeader
            sx={{ bgcolor: "#086AE2", color: "white" }}
            title={"Potvrda trajnog brisanja ankete"}
          />

          <CardContent>
            <Typography
              id="modal-modal-title"
              variant="body1"
              component="body"
              sx={{ mt: 3, mb: 1, pl: 4, pr: 4 }}
            >
              Klikom na potvrdi anketa će biti trajno obrisana i ne ćete ju više
              moći uređivati niti objaviti.
            </Typography>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
              sx={{ mt: 2, pl: 4, pr: 4 }}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 4 }}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleCloseSecond}
                      color="error"
                    >
                      Zatvori
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={confirmation}
                      color="success"
                    >
                      Potvrdi
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* </Box> */}
      </Modal>
    </>
  );
}
