import { useEffect, useState, forwardRef } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Modal, Divider, Avatar, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { PredstavnikZahtjev } from 'model/PredstavnikZahtjev'

import { useUser } from 'auth/useUser';
import * as myConstClass from 'Constants';
import { Buffer } from 'buffer';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { Snackbar, styled } from '@mui/material';

Buffer.from('anything', 'base64');
// Modal style
const style = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '65%',
  height: '65%',
  bgcolor: 'background.paper',
  border: 'none !important',
  boxShadow: 24,
  p: 4,
};

const avatarStyle = {

  width: '150px',
  height: '150px',
};

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type CardProps = {
  representative: PredstavnikZahtjev,
}

function Mojeprijave() {
  const user = useUser();
  const { id, korisnickoIme, ulogaID, lozinka_status } = user

  const [data, setData] = useState({
    id: id,
    value: ""
  });

  // Snack bar states
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState('warning');


  function snackbarClose(event) {
    setSnackbar(false)
  }

  const [representative, setRepresentative] = useState<PredstavnikZahtjev[]>([])

  const [representativeData, setRepresentativeData] = useState({
    userID: id,
    bioId: 0,
    // themeID: theme.tema_id,
    biography: '',
    linkedIn: '',
    imageBase64: ""
  });

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    // add image to the state that will be sent
    //setPostImage({ ...postImage, myFile: base64 });
    const newdata = { ...representativeData }
    newdata["imageBase64"] = base64 + ""
    setRepresentativeData(newdata)
  };

  const handleInputChange = (e) => {
    const newdata = { ...representativeData }
    newdata[e.target.id] = e.target.value
    setRepresentativeData(newdata)

  };

  // Modal states
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleOpen = (zahtjevID, modalType) => {

    setRepresentativeData({
      userID: id,
      bioId: zahtjevID,
      // themeID: theme.tema_id,
      biography: '',
      linkedIn: '',
      imageBase64: ""
    })

    if (modalType == "pregled") {
      setOpen(true)
    } else {
      setOpenEdit(true)
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        broj_zahtjeva: zahtjevID,

      })
    };
    fetch(myConstClass.SERVER_URL + 'representative/getRequestData', requestOptions)
      .then((response) => response.json())
      .then(setRepresentative)

  };
  const handleClose = () => setOpen(false);
  const handleCloseEdit = () => setOpenEdit(false);

  const [tableData, setTableData] = useState<any[]>([])


  useEffect(() => {

  }, [open]);



  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(myConstClass.SERVER_URL + 'representative/getApplications', requestOptions)
      .then((response) => response.json())
      .then(setTableData)
  }, []);

  function handleEditedData() {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(representativeData)
    };
    fetch(myConstClass.SERVER_URL + 'representative/updateApplication', requestOptions)
      .then(response => {
        response.json()
        if (response.status == 200) {
          setSnackbarMessage("Vaše promjene su spremljene")
          setSeverity("success")
          setSnackbar(true)

        } else {
          setSnackbarMessage("Doslo je do greske")
          setSeverity("error")
          setSnackbar(true)

        }
      })
    // dodaj message
  }

  function handleDate(date) {

    if (date == null) {
      return ""
    } else {

      let dateFormat = new Date(date)
      return `${dateFormat.getDate()}-${dateFormat.getMonth()}-${dateFormat.getFullYear()}`
    }
  }

  function handleStatus(status) {

    if (status == null) {
      return "U obradi"
    } else if (status == 1) {
      return "Prihvacen"
    } else if (status == 0) {
      return "Odbijen"
    }
  }

  const getImage = (buffer) => {
    if (buffer != null) {
      const image_base64 = Buffer.from(buffer).toString();
      return image_base64
    } else
      return ''
  }


  return (
    <>
      <Snackbar
        open={snackbar}
        autoHideDuration={3000}
        onClose={snackbarClose}>
        <Alert onClose={snackbarClose} severity={severity == "success" ? "success" : severity == "error" ? "error" : severity == "info" ? "info" : "warning"} sx={{ width: '100%' }}>
          <span> {snackbarMessage} </span>
        </Alert>

      </Snackbar>

      <Container maxWidth="lg">
        <h1>Moje prijave</h1>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead >
              <TableRow >
                <TableCell sx={{ fontWeight: 600, color: '#91A3B0' }} align="left">Prijava</TableCell>
                <TableCell sx={{ fontWeight: 600, color: '#91A3B0' }} align="left">Status</TableCell>
                <TableCell sx={{ fontWeight: 600, color: '#91A3B0' }} align="left">Datum zahtjeva</TableCell>
                <TableCell sx={{ fontWeight: 600, color: '#91A3B0' }} align="left">Datum odbijanja</TableCell>
                <TableCell sx={{ fontWeight: 600, color: '#91A3B0' }} align="right"></TableCell>

                <TableCell>

                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((zahtjev) => (
                <TableRow
                  key={zahtjev.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {zahtjev.id}
                  </TableCell>
                  <TableCell align="left">{handleStatus(zahtjev.status)}</TableCell>
                  <TableCell align="left">{handleDate(zahtjev.datum_kreiranja)}</TableCell>
                  <TableCell align="left">{handleDate(zahtjev.datum_odobrenja)}</TableCell>
                  <TableCell align="right">
                    {zahtjev.datum_odobrenja ? <Button variant="contained" color="success" onClick={() => { handleOpen(zahtjev.id, "pregled") }}>Pregled</Button>
                      : zahtjev.status == null ? <Button variant="contained" color="primary" onClick={() => { handleOpen(zahtjev.id, "edit") }}>Uredi</Button>
                        : <></>}
                  </TableCell>

                  <TableCell>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>


          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 1 }}>
            Pregled predstavnika
          </Typography>
          <Divider />
          {representative[0] ?
            <Grid
              container
              direction="column"
              justifyContent="space-between"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="stretch"
                  sx={{ mt: 2 }}
                >

                  <Grid item sx={{ mb: 3 }} xs={6}>
                    <Avatar sx={avatarStyle} alt="representative image" src={`data:image/png;base64,${getImage(representative[0].slika)}`} />
                    <br />
                    <Typography variant="body1" component="div">
                      {/* {theme.naslov} */}
                      Predstavnik: {representative[0].ime} {representative[0].prezime}

                    </Typography>

                    <Typography sx={{ mb: 3, mt: 2 }}>
                      Biografija:
                      <br />
                      {representative[0].biografija}
                    </Typography>
                  </Grid>

                  <Grid item sx={{ mb: 4 }} xs={5}>
                    Kategorija: {representative[0].sifarnik_naziv}
                    <br />
                    Tema: {representative[0].tema_naslov}
                    <br />
                    <br />

                    Datum odbijanja: 20-10-2022
                    <br />

                    Razlog odbijanja:
                    {representative[0].razlog_odbijanja}
                    <br />

                  </Grid>

                </Grid>
              </Grid>

              <Grid item>
                <Grid>
                  <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mt: 6 }}
                  >
                    <Grid item>
                      <Button variant="outlined" onClick={handleClose} color="error">Zatvori</Button>
                    </Grid>
                    <Grid item>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
            : <></>}
        </Box>
      </Modal>

      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>


          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 1 }}>
            Uređivanje predstavnika
          </Typography>
          <Divider />
          {representative[0] ?
            <Grid
              container
              direction="column"
              justifyContent="space-between"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="stretch"
                  sx={{ mt: 2 }}
                >

                  <Grid item xs={6}>

                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={6} md={6}>

                        {representativeData.imageBase64 ?
                          <Avatar sx={avatarStyle} alt="representative image" src={`${getImage(representativeData.imageBase64)}`} />
                          : <Avatar sx={avatarStyle} alt="representative image" src={`data:image/png;base64,${getImage(representative[0].slika)}`} />
                        }

                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Button
                          variant="contained"
                          component="label"
                        >
                          Upload slike
                          <input
                            type="file"
                            hidden
                            onChange={(e) => handleFileUpload(e)}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                    <br />



                    <Typography sx={{ mb: 1, mt: 2 }}>
                      {/* Biografija:
                      <br />
                      {representative[0].biografija} */}
                      <TextField
                        id="linkedIn"
                        label="LinkedIn"
                        value={representativeData.linkedIn}
                        onChange={handleInputChange}
                        placeholder={representative[0].linkedin_url}

                        fullWidth
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        id="biography"
                        label="Biografija"
                        value={representativeData.biography}
                        onChange={handleInputChange}
                        placeholder={representative[0].biografija}
                        multiline
                        required
                        rows={5}
                        fullWidth
                        sx={{ mb: 2 }}
                      />

                    </Typography>
                  </Grid>

                  <Grid item sx={{ mb: 4 }} xs={5}>

                    <Typography variant="h5" component="div">
                      {/* {theme.naslov} */}
                      Informacije o prijavi
                    </Typography>


                    <br />
                    Predstavnik: {representative[0].ime} {representative[0].prezime}
                    <br />

                    Kategorija: {representative[0].sifarnik_naziv}
                    <br />
                    Tema: {representative[0].tema_naslov}
                    <br />
                    <br />
                    {representative[0].datum_odobrenja ? <> Datum odbijanja:
                      {representative[0].datum_odobrenja}</> : <></>}

                    <br />
                    {representative[0].razlog_odbijanja ? <>Razlog odbijanja:
                      {representative[0].razlog_odbijanja}</> : <></>}


                    <br />

                  </Grid>

                </Grid>
              </Grid>

              <Grid item>
                <Grid>
                  <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item>
                      <Button variant="outlined" onClick={handleCloseEdit} color="error">Zatvori</Button>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" onClick={handleEditedData} color="success">Spremi promjene</Button>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
            : <></>}
        </Box>
      </Modal>


    </>
  );
}

export default Mojeprijave;