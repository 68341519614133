import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import Switch from "@mui/material/Switch";
import "./Switch.css";
import * as myConstClass from "Constants";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    neutral: {
      main: "#357a38",
      contrastText: "#fff",
    },
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Switch" {
  interface SwitchPropsColorOverrides {
    neutral: true;
  }
}

export default function ControlledSwitches({ id, url, value, chnageState }) {
  const [checked, setChecked] = useState(value);
  const notInitialRender = useRef(false);

  //  // Queue of snackbar messages
  //  const { enqueueSnackbar } = useSnackbar();
  //  const handleClickVariant = (variant: VariantType) => () => {
  //    // variant could be success, error, warning, info, or default
  //    enqueueSnackbar('This is a success message!', { variant });
  //  };

  const [data, setData] = useState({
    id: id,
    value: value,
  });

  // add fetch on load which sets the value
  useEffect(() => {
    if (notInitialRender.current) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      fetch(myConstClass.SERVER_URL + url, requestOptions).then((response) =>
        response.json()
      );
    } else {
      notInitialRender.current = true;
    }
  }, [data]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    // TODO You need to set data her
    const newdata = { ...data };
    newdata["id"] = id;
    newdata["value"] = !checked;
    setData(newdata);
    // set the id and the value
    // handleClickVariant('success')
    window.location.reload();
  };

  return (
    <ThemeProvider theme={theme}>
      <Switch
        checked={checked}
        onChange={handleChange}
        color="neutral"
        inputProps={{ "aria-label": "controlled" }}
      />

      {/* <StyledSwitch>

     </StyledSwitch> */}
        <SnackbarProvider maxSnack={3}>
    </SnackbarProvider>
    </ThemeProvider>
  );
}
