import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Card from 'componenets/Elements/Card/Card';
import { Theme } from 'model/Theme'

import { useNavigate } from 'react-router-dom';


type FolderProps = {
  data: Theme[],
  themeLocationData
}

export default function FolderList({ data, themeLocationData }: FolderProps) {

  return (
    <List sx={{ width: '100%', maxWidth: 1, bgcolor: 'background.paper' }}>

      {data.map((theme, index) =>
        <ListItem key={index}>
          <Card theme={theme} themeLocationData={themeLocationData}/>
        </ListItem>
      )}

    </List>
  );
}
