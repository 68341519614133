import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useEffect } from "react";
import * as myConstClass from "Constants";
import { Predstavnik } from "model/Predstavnik";
import { Snackbar, Alert } from "@mui/material";

type FolderProps = {
  data: Predstavnik[];
  setSelectedRepresentive: (representative: Predstavnik) => void;
};

export default function PredstavniciList({
  data,
  setSelectedRepresentive,
}: FolderProps) {
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    row: any
  ) => {
    setSelectedIndex(index);
    setSelectedRepresentive(row);
  };

  useEffect(() => {
    setSelectedIndex(0);
    setSelectedRepresentive(data[0]);
  }, [data]);

  function snackbarClose(event) {
    setSnackbar(false);
  }

  return (
    <>
      <Snackbar open={snackbar} autoHideDuration={3000} onClose={snackbarClose}>
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>

      <Box sx={{ maxHeight: 400 }}>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {data.map((row, index) => (
            <ListItemButton
              alignItems="flex-start"
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index, row)}
            >
              <ListItemText
                // primary={row.ime}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {row.ime} {row.prezime}
                    </Typography>
                    {" — "} {row.mail}
                  </React.Fragment>
                }
              />
              <Divider variant="inset" component="li" />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </>
  );
}
