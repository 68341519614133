import React from 'react'
import {ClipLoader} from "react-spinners";
import { Container } from '@mui/material';

const Loader = ({ loading }) => {
    return loading  ? (
     <>
       <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
         <ClipLoader
            size={40}
            color={"#123abc"}
            loading={loading}
          />  
          </div>
      </>
          
    ) : null
};

export default Loader;