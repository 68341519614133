import { Breadcrumbs, Container, Grid, Box, Link } from '@mui/material';


function Page404(){
  return (
    <div className="wrapper">
      <Container maxWidth="lg">

        <h2>404 this page was lost</h2>

        <Link href="/Administracija" sx={{ fontSize: 20 }}>Go to home</Link>
      
       
      </Container>
    </div>
  );
}

export default Page404;