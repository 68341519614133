import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import * as myConstClass from "Constants";
import { useUser } from "auth/useUser";
import SinglePoll from "componenets/Elements/SinglePoll/SinglePoll";
import Loader from "componenets/Sites/Messages/Loader";
// import {useBackListener}
import { useBackListener } from "auth/useBackListener";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

function AktivneAnkete() {
  let navigate = useNavigate();

  useBackListener(({ location }) => {
    navigate(-1);
  });

  const navigateTo = (pageLink: string) => {
    // Replacing " " (space) to "" empty space
    const res = pageLink.replace(/ /g, "");
    navigate("../" + res);
  };

  const user = useUser();
  const { id, korisnickoIme, ulogaID, pollWrite } = user;

  const [representativeData, setRepresentativeData] = useState({
    userID: id,
  });

  const [poll, setPoll] = useState<any[]>([]);
  const [voters, setVoters] = useState<any[]>([]);

  const [combinedData, setCombinedData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "poll/voters")
      .then((response) => response.json())
      .then(setVoters)
      .then(() => {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(representativeData),
        };
        fetch(myConstClass.SERVER_URL + "poll/categories", requestOptions)
          .then((response) => response.json())
          .then(setPoll)
          .then(() => {
            setLoading(false);
          });
      });
  }, []);

  function dispalyCount(message) {
    if (message != undefined) {
      return message.count;
    }
    return 0;
  }

  return (
    <Container maxWidth="lg">
      <div className="wrapper">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Item>
              <h1> Ankete: </h1>
            </Item>
          </Grid>
          <Grid item>
            <Item>
              {pollWrite && (
                <Button
                  variant="outlined"
                  size="large"
                  startIcon={<ControlPointIcon />}
                  onClick={() => {
                    navigateTo(`../NovaAnketa`);
                  }}
                  color="success"
                  sx={{ borderRadius: 50 }}
                >
                  Dodaj Anketu
                </Button>
              )}
            </Item>
          </Grid>
        </Grid>

        {loading ? (
          <Loader loading="Loading" />
        ) : (
          <Grid container spacing={2}>
            {poll.map((row, index) => (
              <Grid item xs={4} key={index}>
                <Item>
                  <SinglePoll
                    icon={row.icon}
                    themeID={row.id}
                    title={row.naziv}
                    description={row.opis}
                    count={row.count}
                    pretplata={row.pretplata}
                    peopleVote={dispalyCount(voters[index])}
                  />
                </Item>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </Container>
  );
}

export default AktivneAnkete;
