import { useState, useEffect, forwardRef } from "react";
import {
  Box,
  CardActions,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import { Theme } from "model/Theme";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import GavelIcon from "@mui/icons-material/Gavel";
import { Card, ButtonBase, Grid, Paper, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import * as myConstClass from "Constants";
import { useUser } from "auth/useUser";
import { Snackbar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import * as MuiIcons from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
// import FileUpload from "react-mui-fileuploader"
import { Input, FormLabel } from "@mui/material";
import FileUpload from "react-material-file-upload";
import UploadFiles from "../UploadFiles/upload-files.component";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FileUploader from "componenets/Elements/FileUploader/FileUploader";

const AlertSnackbar = forwardRef<HTMLDivElement, AlertProps>(
  function AlertSnackbar(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }
);

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "start",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

// const styles = {
//   modalStyle1:{
//     position:'absolute',
//     top:'10%',
//     left:'10%',
//     overflow:'scroll',
//     height:'100%',
//     display:'block'
  
// };

type CardProps = {
  theme: Theme;
  themeLocationData;
};

// Modal style
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 600,
  bgcolor: "background.paper",
  overflowX: 'hidden !important',
  overflow:'scroll',
  border: "none !important",
  boxShadow: 24,
  p: 4,
};

export default function BasicCard({ theme, themeLocationData }: CardProps) {
  // Modal states
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Snack bar states
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  function snackbarClose(event) {
    setSnackbar(false);
  }

  const user = useUser();
  const { id, korisnickoIme, ulogaID, lozinka_status } = user;

  let navigate = useNavigate();
  const [files, setFiles] = useState<File[]>([]);

  const [representativeData, setRepresentativeData] = useState({
    userID: id,
    themeID: theme.tema_id,
    bibliography: "",
    linkedIn: "",
    imageBase64: "",
  });

  const [responseData, setResponseData] = useState({
    id: "",
  });

  useEffect(() => {}, [files]);

  const navigateTo = (pageLink: string, id: String) => {
    // Replacing " " (space) to "" empty space
    const res = pageLink.replace(/ /g, "");

    navigate("../" + res, {
      replace: false,
      state: { themeID: theme.tema_id, themeLocationData: themeLocationData },
    });
  };

  function handleDate(date) {
    if (date == null) {
      return "";
    } else {
      let dateFormat = new Date(date);

      return `Trajanje do ${dateFormat.getDate()}-${
        dateFormat.getMonth() + 1
      }-${dateFormat.getFullYear()}`;
    }
  }

  function uplodaFiles(type, id) {
    const data = new FormData();
    // adding all files that will be uploaded to server
    for (var x = 0; x < files.length; x++) {
      data.append("myFiles", files[x]);
    }

    const requestOptions = {
      method: "POST",
      body: data,
    };

    // types - representative, theme, poll, question

    fetch(
      myConstClass.SERVER_URL +
        "fileManager/uploadmultiple?type=" +
        type +
        "&id=" +
        id,
      requestOptions
    ).then(function (response) {
      if (response.status == 200) {
        setSnackbarMessage("Vaša prijava je uspješno poslana!");
        setSeverity("success");
        setSnackbar(true);
      } else {
        setSnackbarMessage("Doslo je do greske");
        setSeverity("error");
        setSnackbar(true);
      }
    });

    // ispisi confirmation message
  }

  function addRepresentative() {
    // Wait for fetch response

    if (
      representativeData.bibliography == "" ||
      representativeData.imageBase64.length < 1
    ) {
      setSnackbarMessage("Molim vas unesite biografiju i sliku");
      setSeverity("error");
      setSnackbar(true);
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(representativeData),
      };
      fetch(myConstClass.SERVER_URL + "representative/add", requestOptions)
        .then(function (response) {
          if (response.status != 200) {
            setSnackbarMessage("Doslo je do greske");
            setSeverity("error");
            setSnackbar(true);
          }

          return response.json();
        })
        .then(function (myJson) {
          let id = myJson[0].predstavnik_id;

          uplodaFiles("representative", id);
        });
    }
  }

  const handleInputChange = (e) => {
    const newdata = { ...representativeData };
    newdata[e.target.id] = e.target.value;
    setRepresentativeData(newdata);
  };

  function isFinished(time) {
    let endTime = new Date(time);
    let currentTime = new Date();

    return endTime > currentTime;
  }

  function fileChange(changedFiles) {
    setFiles(changedFiles);
  }

  function representativeStatus(korisnik_id, status, predstavnik_kreiranje) {
    if (korisnik_id != null && status == 1) {
      return (
        <Tooltip title="Predstavnik Prihvaćen">
          <IconButton>
            <CheckIcon />
          </IconButton>
        </Tooltip>
      );
    } else if (korisnik_id != null && status == 0) {
      return (
        <Tooltip title="Prijava u obradi">
          <IconButton>
            <HourglassTopIcon />
          </IconButton>
        </Tooltip>
      );
      // <Button variant="outlined" startIcon={<HourglassTopIcon />} color="secondary" sx={{ borderRadius: 50 }}></Button>
    } else if (korisnik_id != null && predstavnik_kreiranje != null) {
      return (
        <Tooltip title="Prijava u obradi">
          <IconButton>
            <HourglassTopIcon />
          </IconButton>
        </Tooltip>
      );
      // <Button variant="outlined" startIcon={<HourglassTopIcon />} color="secondary" sx={{ borderRadius: 50 }}>Prijava u obradi</Button>
    } else {
      return (
        <Tooltip title="Prijava Predstavnika">
          <IconButton
            onClick={() => {
              handleOpen();
            }}
          >
            <PersonIcon />
          </IconButton>
        </Tooltip>
      );
      // <Button variant="outlined" startIcon={<PersonIcon />} onClick={() => { handleOpen() }} color="primary" sx={{ borderRadius: 50 }}>Prijava Predstavnika</Button>
    }
  }

  const handleFileUploadError = (error) => {
    setSnackbarMessage("Doslo je do greske pri uploadu filova.");
    setSeverity("error");
    setSnackbar(true);
  };

  const handleFilesChange = (files) => {
    // setUploadedFiles(files);
    // Do something...
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    // add image to the state that will be sent
    //setPostImage({ ...postImage, myFile: base64 });
    const newdata = { ...representativeData };
    newdata["imageBase64"] = base64 + "";
    setRepresentativeData(newdata);
  };

  return (
    <>
      <Snackbar
        // anchorOrigin={{vertical: 'center', horizontal: 'center'}}
        open={snackbar}
        autoHideDuration={3000}
        onClose={snackbarClose}

        // message = { <span> {snackbarMessage} </span>}
      >
        <AlertSnackbar
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </AlertSnackbar>
      </Snackbar>

      <Card sx={{ minWidth: 1 }}>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h5" component="div">
                    {theme.naslov}
                  </Typography>
                </Grid>
                <Grid item sx={{ ml: 2 }}>
                  {isFinished(theme.trajanje) ? (
                    representativeStatus(
                      theme.korisnik_id,
                      theme.predstavnik_odobreno,
                      theme.predstavnik_kreiranje
                    )
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {handleDate(theme.trajanje)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            sx={{ mb: 0 }}
          >
            <Grid item xs={8}>
              <Item sx={{ textAlign: "none" }}>
                <Typography variant="body2">
                  {theme.opis}
                  <br />
                </Typography>
              </Item>
            </Grid>

            <Grid item xs={2} sx={{ mt: 1 }}>
              <Item>
                {isFinished(theme.trajanje) ? (
                  <Button
                    size="large"
                    variant="contained"
                    color="success"
                    onClick={() => {
                      navigateTo(`../Tema`, `${theme.tema_id}`);
                    }}
                  >
                    Sudjeluj
                  </Button>
                ) : (
                  <Button
                    size="large"
                    variant="contained"
                    color="success"
                    onClick={() => {
                      navigateTo(`../Tema`, `${theme.tema_id}`);
                    }}
                  >
                    Rezultati
                  </Button>
                )}
              </Item>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions></CardActions>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 1 }}
          >
            Prijava predstavnika
          </Typography>

          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            sx={{ mt: 2 }}
          >
            <Grid item sx={{ mb: 3 }}>
              <Typography sx={{ mb: 3 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                facilisis sagittis interdum. Integer vulputate bibendum
                tincidunt. Mauris ac purus eu mauris luctus malesuada.
              </Typography>
              <TextField
                id="linkedIn"
                label="LinkedIn"
                value={representativeData.linkedIn}
                onChange={handleInputChange}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="bibliography"
                label="Biografija"
                value={representativeData.bibliography}
                onChange={handleInputChange}
                multiline
                required
                rows={5}
                fullWidth
                sx={{ mb: 2 }}
              />
              {/* <FormLabel  label="" /> */}

              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <Item>
                    <FormLabel sx={{ mt: 2 }} component="legend">
                      Slika predstavnika
                    </FormLabel>
                    <br />
                    {/* <Input
                      type="file"
                      // label="Slika predstavnika"

                      name="myFile"
                      //accept=".jpeg, .png, .jpg"
                      onChange={(e) => handleFileUpload(e)}
                    /> */}

                    <Button variant="contained" component="label">
                      Upload slike
                      <input
                        type="file"
                        hidden
                        onChange={(e) => handleFileUpload(e)}
                      />
                    </Button>
                  </Item>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Item>
                    {/* <FileUpload value={files} multiple={true} onChange={setFiles} /> */}
                    <FileUploader
                      fileChange={fileChange}
                      handleFileUploadError={handleFileUploadError}
                    />
                  </Item>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    color="error"
                  >
                    Zatvori
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={addRepresentative}
                    color="success"
                  >
                    Prijavi se
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
