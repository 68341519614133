import React from "react";
import { CDBFooter, CDBFooterLink, CDBBox, CDBBtn, CDBIcon } from "cdbreact";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import EuLogo from "static/Eu_logo.jpg";

export default function Footer() {
  return (
    <Box sx={{ mt: 5, background: "#2F3032", color: "#848484" }}>
      <Container>
        <CDBFooter >
          <CDBBox
            display="flex"
            flex="column"
            className="mx-auto py-5"
            style={{ width: "90%" }}
          >
            <CDBBox
              display="flex"
              justifyContent="between"
              className="flex-wrap"
              style={{
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                paddingBottom: "2em",
              }}
            >
              <CDBBox>
                {/* <a href="/" className="d-flex align-items-center p-0 text-dark">
              <img alt="logo" src="logo" width="30px" /> 
              <span className="ml-3 h5 font-weight-bold">O nama</span>
            </a> */}
                <p
                  className="h5 mb-4"
                  style={{
                    fontWeight: "600",
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  O NAMA
                </p>
                <p
                  className="my-3"
                  style={{ width: "250px", color: "#848484" }}
                >
                  Udruga Prospectus osnovana je 2016. godine u Dubrovniku sa
                  ciljem organiziranja aktivnosti kojima se promiče društvena
                  zajednica te potiče inovativnost i poduzetništvo.
                </p>
              </CDBBox>
              <CDBBox>
                <p className="h5 mb-4" style={{ fontWeight: "600" }}></p>
                <CDBBox
                  flex="column"
                  style={{ cursor: "pointer", padding: "0" }}
                >
                  <p
                    className="my-3"
                    style={{ width: "250px", color: "#848484" }}
                  >
                    Izradu internetske stranica sufinancirala je Europska unija
                    iz Europskog socijalnog fonda.
                  </p>

                  <Card
                    sx={{
                      width: 100,
                      height: 80,
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="194"
                      image={EuLogo}
                      alt="Paella dish"
                      sx={{
                        width: 100,
                        height: 80,
                      }}
                    />
                  </Card>
                </CDBBox>
              </CDBBox>
              <CDBBox>
                <p
                  className="h5 mb-4"
                  style={{
                    fontWeight: "600",
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  KONTAKTIRAJTE NAS
                </p>
                <CDBBox
                  flex="column"
                  style={{ cursor: "pointer", padding: "0", color: "#848484" }}
                >
                  {/* <p className="my-3" style={{ width: '250px' }}>
                Vega Haklička
                </p> */}
                  <p className="my-3" style={{ width: "250px" }}>
                    info@liquid-democracy.info
                  </p>
                  <p className="my-3" style={{ width: "250px" }}>
                    Kontakt broj: 0916210824
                  </p>
                  {/* <CDBFooterLink href="http://strukturnifondovi.hr">Strukturni fondovi</CDBFooterLink> */}
                </CDBBox>
              </CDBBox>
            </CDBBox>
            <small className="text-center mt-5">
              &copy; 2022 Liquid democracy. All Rights Reserved.
            </small>
          </CDBBox>
        </CDBFooter>
      </Container>
    </Box>
  );
}
