import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import * as myConstClass from 'Constants';


export default function AutoCompleteMultiSelect({ disabled, id, onValueChange }) {

    // create an use effect which filters the useres from the db based on teh input

    const [users, setUsers] = useState<any[]>([])
    const [value, setValue] = useState<any[]>([])

    const [filterData, setFilterData] = useState({
        filteredValue: ''
    });

    useEffect(() => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(filterData)
        };

        fetch(myConstClass.SERVER_URL + 'user/filter/email', requestOptions)
            .then((response) => response.json())
            .then(setUsers);

    }, [filterData]);

    useEffect(() => {
        valueChange([])

    }, [disabled]);

    function valueChange(newValue) {
        onValueChange(newValue)
        setValue(newValue)
    }
    return (
        // <Stack >
        <Autocomplete
            multiple
            disabled={disabled}
            fullWidth
            id="tags-outlined"
            // inputValue={(value) => resertInput(value)}
            value={value}
            options={users}
            getOptionLabel={(option) => option.ime}
            defaultValue={users}
            filterSelectedOptions
            onChange={(event, value) => valueChange(value)}
            onInputChange={(event, value) => setFilterData({
                filteredValue: value
            })}

            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Prima"

                />
            )}
            sx={{ m: 1 }}
        />
    );
}

