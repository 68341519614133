import { useState, forwardRef } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import {
  Container,
  FormControl,
  Grid,
  Paper,
  TextField,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import * as myConstClass from "Constants";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import MUIRichTextEditor from "mui-rte";
import { borderRadius } from "@mui/system";
import MultipleSelectChip from "componenets/Elements/MultipleSelectChip/MultipleSelectChip";
import AutoCompleteMultiSelect from "componenets/Elements/AutoCompleteMultiSelect/AutoCompleteMultiSelect";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import { Snackbar } from "@mui/material";
import BasicBreadcrumbs from "componenets/Navigation/BasicBreadcrumbs/BasicBreadcrumbs";

const defaultTheme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        //border: '1px solid rgba(0, 0, 0, 0.2) !important',
        margin: "0.5em",
        marginTop: 2,
        width: "100%",
        // marginBottom: 2,
        backgroundColor: "white",
        // paddingBottom: 24,
      },
      toolbar: {
        border: "1px solid rgba(0, 0, 0, 0.2)",
        marginTop: 2,
        width: "95%",
        marginBottom: 20,
        backgroundColor: "white",
        borderRadius: "4px",
      },
      editor: {
        border: "1px solid rgba(0, 0, 0, 0.2)",
        width: "95%",
        height: 180,
        overflow: "auto",

        borderRadius: "4px",
      },
      editorContainer: {
        paddingLeft: "14px",
        paddingTop: "16.5px",
      },
    },
  },
});

function Obavijesti() {
  const [messageData, setMessageData] = useState({
    sendTo: [],
    subject: "",
    content: "",
    sendToAll: false,
    sendToSubscribed: false,
    sendToCategories: [],
  });

  const [rteCount, setRteCount] = useState(1000);

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  function submit() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(messageData),
    };
    fetch(myConstClass.SERVER_URL + "user/sendMessage", requestOptions).then(
      (response) => {
        response.json();
        if (response.status == 200) {
          setSnackbarMessage("Obavijest je poslana");
          setSeverity("success");
          setSnackbar(true);

          //  alert("Novi korisnik je dodan")
        } else {
          setSnackbarMessage("Doslo je do greske");
          setSeverity("error");
          setSnackbar(true);
        }
      }
    );
  }
  function snackbarClose(event) {
    setSnackbar(false);
  }

  function allUsersCheckbox(event) {
    // setAllUsers(!allUsers)
    const newdata = { ...messageData };
    newdata["sendToAll"] = !messageData.sendToAll;
    if (!messageData.sendToAll) {
      newdata["sendToSubscribed"] = false;
    }
    setMessageData(newdata);
  }

  function subscribedCheckbox(event) {
    // setAllUsers(!allUsers)
    const newdata = { ...messageData };
    newdata["sendToSubscribed"] = !messageData.sendToSubscribed;
    if (!messageData.sendToSubscribed) {
      newdata["sendToAll"] = false;
    }
    setMessageData(newdata);
  }

  function handleChange(e) {
    const newdata = { ...messageData };
    newdata[e.target.id] = e.target.value;
    setMessageData(newdata);
  }

  function multiselectChange(value) {
    const newdata = { ...messageData };
    newdata["sendToCategories"] = value;
    setMessageData(newdata);
  }

  function multiselectMailToChange(e) {
    const newdata = { ...messageData };
    newdata["sendTo"] = e;
    setMessageData(newdata);
  }

  const onEditorChange = (event) => {
    // setRteCount
    const currentContent = event.getCurrentContent();
    const contentRaw = convertToRaw(currentContent);
    const value = currentContent.hasText() ? draftToHtml(contentRaw) : "";

    let numChar = 0;

    for (var i = 0; i < contentRaw.blocks.length; i++) {
      numChar += contentRaw.blocks[i].text.length;
    }

    setRteCount(1000 - numChar);

    const newdata = { ...messageData };
    newdata["content"] = value;
    setMessageData(newdata);
  };

  return (
    <>
      {" "}
      <Snackbar open={snackbar} autoHideDuration={3000} onClose={snackbarClose}>
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>
      <Container maxWidth="lg">
        <div className="wrapper">
          <h1>Sučelje za slanje obavijesti</h1>
          <BasicBreadcrumbs currentPage="Obavijesti" />

          <FormControl fullWidth sx={{ m: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Item>
                  <Box maxWidth={"95%"}>
                    <AutoCompleteMultiSelect
                      id="sendTo"
                      disabled={
                        messageData.sendToAll || messageData.sendToSubscribed
                      }
                      onValueChange={multiselectMailToChange}
                    />

                    {/* <TextField fullWidth id="outlined-basic" label="Prima" variant="outlined" sx={{ m: 1 }} /> */}
                    <TextField
                      fullWidth
                      id="subject"
                      value={messageData.subject}
                      onChange={handleChange}
                      label="Predmet"
                      variant="outlined"
                      sx={{ m: 1 }}
                      helperText="Ovo polje može sadržavati najviše 50 znakova"
                      inputProps={{
                        maxLength: 50,
                      }}
                    />

                    {/* <TextField id="outlined-basic" label="Sadržaj" variant="outlined" sx={{ m: 1 }}/> */}
                  </Box>
                  <ThemeProvider theme={defaultTheme}>
                    <MUIRichTextEditor
                      label="Sadržaj"
                      // onSave={save}
                      //  value={messageData.content}
                      inlineToolbar={true}
                      onChange={onEditorChange}
                      maxLength={1000}
                      // helperText="Ovo polje može sadržavati najviše 200 znakova"
                    />
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ ml: "25px" }}
                    >
                      Ovo polje može sadržavati najviše 1000 znakova. Preostali
                      broj znakova: {rteCount}
                    </Typography>
                  </ThemeProvider>
                </Item>
              </Grid>
              <Grid item xs={4}>
                <Item>
                  <FormLabel component="legend" sx={{ mb: 2 }}>
                    Slanje maila
                  </FormLabel>

                  <FormControlLabel
                    control={
                      <Checkbox
                        id="sendToAll"
                        checked={messageData.sendToAll}
                        onChange={allUsersCheckbox}
                      />
                    }
                    label="Svim korisnicima"
                  />

                  <FormControlLabel
                    sx={{ mb: 3 }}
                    control={
                      <Checkbox
                        id="sendToSubscribed"
                        checked={messageData.sendToSubscribed}
                        onChange={subscribedCheckbox}
                      />
                    }
                    label="Pretplačenim korisnicima"
                  />

                  <FormLabel component="legend">
                    Korisnicima pretplačenim na:
                  </FormLabel>

                  <MultipleSelectChip
                    id="sendToCategories"
                    disabled={
                      messageData.sendToAll || !messageData.sendToSubscribed
                    }
                    onChange={multiselectChange}
                  />

                  <Button
                    variant="contained"
                    size="large"
                    endIcon={<SendIcon />}
                    onClick={submit}
                    sx={{ mt: 4 }}
                  >
                    Pošalji
                  </Button>
                </Item>
              </Grid>
            </Grid>
          </FormControl>
        </div>
      </Container>
    </>
  );
}

export default Obavijesti;
