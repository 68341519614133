import * as React from "react";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "componenets/Elements/Switch/Switch";
import {
  Breadcrumbs,
  Container,
  Grid,
  Box,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import * as myConstClass from "Constants";
import { useState, useEffect } from "react";
import {
  TablePagination,
  FormControl,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useUser } from "auth/useUser";

import { Snackbar, styled } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

export default function SifarnikObrazovanjaTable() {
  const user = useUser();

  const [rows, setSifarnici] = useState<any[]>([]);
  const [update, setUpdate] = useState(true);

  // Snack bar states
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  function snackbarClose(event) {
    setSnackbar(false);
  }

  const [filterData, setFilterData] = useState({
    filteredValue: "",
  });

  const handleInputChange = (e) => {
    const newdata = { ...filterData };
    newdata[e.target.id] = e.target.value;
    setFilterData(newdata);
  };

  //myConstClass
  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "education/all")
      .then((response) => response.json())
      .then(setSifarnici);
  }, [update]);

  const [data, setData] = React.useState({
    id: 0,
    opis: "",
  });

  function submit() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(myConstClass.SERVER_URL + "education/new", requestOptions).then(
      (response) => {
        response.json();
        if (response.status == 200) {
          setSnackbarMessage("Novi šifarnik je dodan");
          setSeverity("success");
          setUpdate(!update);
          setSnackbar(true);
          //  alert("Novi korisnik je dodan")
        } else {
          setSnackbarMessage("Doslo je do greske");
          setSeverity("error");
          setSnackbar(true);
        }
      }
    );
    // window.location.reload();

    // Refresh table component
  }

  function handleChange(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    newdata.id = rows.length + 1;
    setData(newdata);
  }

  function searchFilter() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(filterData),
    };
    fetch(myConstClass.SERVER_URL + "education/filter", requestOptions)
      .then((response) => response.json())
      .then(setSifarnici);
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission behavior - reseting the page
      searchFilter();
    }
  };

  return (
    <>
      <Snackbar
        // anchorOrigin={{vertical: 'center', horizontal: 'center'}}
        open={snackbar}
        autoHideDuration={3000}
        onClose={snackbarClose}

        // message = { <span> {snackbarMessage} </span>}
      >
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 1 }}
        spacing={2}
      >
        <Grid item>
          <Item></Item>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 1 }}
            spacing={2}
          >
            <Grid item>
              <Item>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1.5, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl required fullWidth sx={{ m: 1 }}>
                    <TextField
                      id="filteredValue"
                      value={filterData.filteredValue}
                      onChange={handleInputChange}
                      label="Traži"
                      size="small"
                      onKeyPress={handleKeyPress}
                    />
                  </FormControl>
                </Box>
              </Item>
            </Grid>
            <IconButton
              sx={{ ml: 1, mt: 2 }}
              aria-label="search"
              onClick={searchFilter}
            >
              <SearchIcon style={{ fill: "black" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 450 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600, color: "#91A3B0" }}>
                  UID
                </TableCell>
                <TableCell
                  sx={{ fontWeight: 600, color: "#91A3B0" }}
                  align="left"
                >
                  Naziv
                </TableCell>
                <TableCell
                  sx={{ fontWeight: 600, color: "#91A3B0" }}
                  align="left"
                >
                  Status
                </TableCell>
                {user.adminWrite && (
                  <TableCell
                    sx={{ fontWeight: 600, color: "#91A3B0" }}
                    align="center"
                  >
                    Aktiviraj/Deaktiviraj
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows

                .sort(function (a, b) {
                  return a.obrazovanje_id - b.obrazovanje_id;
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    key={row.obrazovanje_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{ minWidth: 90 }}>
                      {row.obrazovanje_id}
                    </TableCell>
                    <TableCell align="left">{row.opis}</TableCell>

                    <TableCell align="left">
                      {row.status ? "Aktivan" : "Neaktivan"}{" "}
                    </TableCell>
                    {user.adminWrite && (
                      <TableCell align="center">
                        {" "}
                        <Switch
                          value={row.status ? true : false}
                          id={row.obrazovanje_id}
                          url="education/activation"
                          chnageState={update}
                        />{" "}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {user.adminWrite && (
          <Box mt={2}>
            <Grid container spacing={3}>
              <Grid item>
                <FormControl required fullWidth sx={{ m: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Sifarnik
                  </InputLabel>
                  <OutlinedInput
                    id="opis"
                    value={data.opis}
                    onChange={handleChange}
                    label="Opis"
                  />
                </FormControl>
              </Grid>
              <Grid item sx={{ m: 3 }}>
                <Button variant="contained" onClick={submit}>
                  Dodaj
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
}
