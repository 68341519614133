import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import {
  CardActions,
  CardContent,
  TextField,
  Typography,
  Button,
  Box,
  Card,
  Grid,
  Divider,
  Switch,
  Container,
  Tooltip,
} from "@mui/material";
import { FormControl, InputLabel, MenuItem } from "@mui/material";

import { styled } from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";

import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import NotesIcon from "@mui/icons-material/Notes";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import DeleteIcon from "@mui/icons-material/Delete";

import KratkiOdgovor from "componenets/Elements/VrstePitanja/KratkiOdgovor";
import VisestrukiOdgovor from "componenets/Elements/UpdatePitanja/VisestrukiOdgovor";
import PotvrdniOkvir from "componenets/Elements/UpdatePitanja/PotvrdniOkvir";
import LinearnoMjerilo from "componenets/Elements/UpdatePitanja/LinearnoMjerilo";
import * as myConstClass from "Constants";

import { Pitanje } from "model/Pitanje";

import FileUploaderUpdate from "componenets/Elements/FileUploader/FileUploaderUpdate";
import FileUploader from "componenets/Elements/FileUploader/FileUploader";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function UrediPitanja({
  anketaID,
  data,
  updateQuestions,
  tokenizirano,
  setFiles,
  files,
  serverFiles,
}) {
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    setList(data);
  }, []);

  function fileChange(changedFiles, index) {
    if (files[index] != undefined) {
      const newFiles = files;
      newFiles[index]["dokumenti"] = changedFiles;
      setFiles(newFiles);

      let documentNames = "";

      for (var x = 0; x < changedFiles.length; x++) {
        documentNames += changedFiles[x].name + ",";
      }
      const newdata = list;
      newdata[index]["dokumenti"] = documentNames;
      setList(newdata);
    }
  }

  function handleChange(e, index) {
    const newdata = [...list];
    newdata[index][e.target.id] = e.target.value;

    setList(newdata);
    updateQuestions(newdata);
  }

  function handleSwitchChange(e, index) {
    const newdata = [...list];
    newdata[index]["obavezno"] = e.target.checked;

    setList(newdata);
    updateQuestions(newdata);
  }

  function handleRemove(removeIndex) {
    setList(list.filter((item, index) => index !== removeIndex));
  }

  function updateAnswer(answerChange, index) {
    // update the answers for one question

    // index je index pitanja u anketi koje se mijenja
    const updatedList = [...list];

    updatedList[index].odgovori = answerChange;

    setList(updatedList);
    updateQuestions(updatedList);
  }

  // useEffect za povlacnje spremljnih pitanja ako ih ima ako ne NEMIJENJAJ list

  function handleTypeChange(event: SelectChangeEvent, id) {
    const newState = list.map((obj) => {
      if (obj.anketa_pitanja_id == id) {
        return { ...obj, vrsta_pitanja: event.target.value as string };
      }
      return obj;
    });

    setList(newState);
  }

  function addNewItem() {
    const newList = list.concat({
      anketa_pitanja_id: list.length,
      naziv_pitanja: "Texst pitanja",
      opis: "",
      vrsta_pitanja: "1",
      odgovori: [],
      obavezno: false,
      dokumenti: "",
    });

    setList(newList);
  }

  const handleFileUploadError = (error) => {
    // Do something...
  };

  return (
    <Container maxWidth="md">
      {list.map((item, index) => (
        <Card key={index} sx={{ mt: 3 }}>
          <CardHeader sx={{ bgcolor: "#086AE2" }} />

          <CardContent>
            <Accordion sx={{ width: "100%", boxShadow: 0 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item xs={6} md={6}>
                    <TextField
                      id="naziv_pitanja"
                      value={item.naziv_pitanja}
                      label="Pitanje"
                      onChange={(e) => handleChange(e, index)}
                      fullWidth
                      variant="filled"
                      sx={{ pt: 1 }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <FormControl
                      fullWidth
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Vrsta pitanja
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={item.vrsta_pitanja}
                        label="Vrsta pitanja"
                        onChange={(event) =>
                          handleTypeChange(event, item.anketa_pitanja_id)
                        }
                        sx={{ pt: 1 }}
                      >
                        <MenuItem defaultChecked value={1}>
                          <Grid
                            container
                            justifyContent="left"
                            alignContent="center"
                            spacing={2}
                          >
                            <Grid item>
                              <RadioButtonCheckedIcon />
                            </Grid>
                            <Grid item>Višestruki odgovor</Grid>
                          </Grid>
                        </MenuItem>
                        <MenuItem value={2}>
                          <Grid
                            container
                            justifyContent="left"
                            alignContent="center"
                            spacing={2}
                          >
                            <Grid item>
                              <CheckBoxIcon />
                            </Grid>
                            <Grid item>Potvrdni okvir</Grid>
                          </Grid>
                        </MenuItem>

                        {tokenizirano == "1" ? (
                          ""
                        ) : (
                          <MenuItem value={3}>
                            <Grid
                              container
                              justifyContent="left"
                              alignContent="center"
                              spacing={2}
                            >
                              <Grid item>
                                <NotesIcon />
                              </Grid>
                              <Grid item>Kratki odgovor</Grid>
                            </Grid>
                          </MenuItem>
                        )}
                        {tokenizirano == "1" ? (
                          ""
                        ) : (
                          <MenuItem value={4}>
                            <Grid
                              container
                              justifyContent="left"
                              alignContent="center"
                              spacing={2}
                            >
                              <Grid item>
                                <LinearScaleIcon />
                              </Grid>
                              <Grid item>Linearno mjerilo</Grid>
                            </Grid>
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <TextField
                  id="opis"
                  label="Opis pitanja"
                  value={item.opis}
                  variant="standard"
                  onChange={(e) => handleChange(e, index)}
                  fullWidth
                  sx={{ mb: 2 }}
                />

                {serverFiles[item.uid] == undefined ? (
                  <FileUploader
                    fileChange={(e) => fileChange(e, index)}
                    handleFileUploadError={handleFileUploadError}
                  />
                ) : (
                  <FileUploaderUpdate
                    fileChange={(e) => fileChange(e, index)}
                    handleFileUploadError={handleFileUploadError}
                    previousFiles={serverFiles[item.uid].dokumenti}
                  />
                )}

                {/* <FileUploaderUpdate fileChange={(e) => fileChange(e, index)} handleFileUploadError={handleFileUploadError} previousFiles={serverFiles[item.uid].dokumenti} /> */}

                <h2>Odgovori</h2>
                <br />

                {item.vrsta_pitanja == "1" ? (
                  <VisestrukiOdgovor
                    data={item.odgovori}
                    pitanjeID={item.uid}
                    answerChange={updateAnswer}
                    idPitanja={index}
                    tokenizirano={tokenizirano}
                  />
                ) : (
                  <></>
                )}
                {item.vrsta_pitanja == "2" ? (
                  <PotvrdniOkvir
                    data={item.odgovori}
                    pitanjeID={item.uid}
                    answerChange={updateAnswer}
                    idPitanja={index}
                    tokenizirano={tokenizirano}
                  />
                ) : (
                  <></>
                )}
                {item.vrsta_pitanja == "3" ? <KratkiOdgovor /> : <></>}
                {item.vrsta_pitanja == "4" ? (
                  <LinearnoMjerilo data={item.odgovori} pitanjeID={item.uid} />
                ) : (
                  <></>
                )}

                <Typography
                  sx={{ mt: 3 }}
                  variant="body2"
                  color="text.secondary"
                >
                  {/* This impressive paella is a perfect party dish and a fun meal to cook
                            together with your guests. Add 1 cup of frozen peas along with the mussels,
                            if you like. */}
                </Typography>
                <Divider />
                <Grid
                  container
                  direction="row"
                  justifyContent="right"
                  alignItems="center"
                  sx={{ mt: 2 }}
                >
                  <Grid item sx={{ mr: 2 }}>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => handleRemove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Divider
                    orientation="vertical"
                    variant="fullWidth"
                    flexItem
                  />

                  <Grid item sx={{ ml: 2 }}>
                    Requiered:{" "}
                    <Switch
                      id="obavezno"
                      checked={item.obavezno}
                      onChange={(e) => handleSwitchChange(e, index)}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
      ))}

      <Card sx={{ mt: 3 }}>
        <CardHeader sx={{ bgcolor: "#086AE2" }} />

        <CardContent>
          <Typography
            sx={{ m: 1 }}
            align="center"
            variant="h5"
            color="text.secondary"
            onClick={addNewItem}
          >
            + Dodaj novo pitanje
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
}

export default UrediPitanja;
