import { Breadcrumbs, Container, Grid, Box, Link } from '@mui/material';
import SifarnikAnketaIGlasovanjaTable from 'componenets/Elements/Tables/SifarnikAnketaIGlasovanjaTable/SifarnikAnketaIGlasovanjaTable';


function SifarnikAnketaIGlasovanja() {
  return (
    <div className="wrapper">
      <Container maxWidth="lg">

        <h2>Administracija šifarnika anketa i glasovanja</h2>
        <Breadcrumbs/>
        <SifarnikAnketaIGlasovanjaTable/>
       
      </Container>
    </div>
  );
}

export default SifarnikAnketaIGlasovanja;