import { useEffect, useState, forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Box, TextField, Paper, Grid, Alert } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as myConstClass from 'Constants';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const AlertSnackbar = forwardRef<HTMLDivElement, AlertProps>(function AlertSnackbar(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({

  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none'
}));

export default function DialogOldPass({ show, korisnik_id }) {
  const [open, setOpen] = useState(show);

  const [isLoad, setIsLoad] = useState(true)
  const [confirm, setConfirm] = useState(true)
  const [errorMessage, setErrorMessage] = useState("");

  // Snack bar states
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState('warning');

  function snackbarClose(event) {
    setSnackbar(false)
  }




  const [pass, setPass] = useState({
    filed1: "",
    filed2: ""
  })

  function handleChange(e) {
    const newdata = { ...pass }
    newdata[e.target.id] = e.target.value
    setPass(newdata)
}


  const [data, setData] = useState({
    id: korisnik_id,
    value: ""
  });


  useEffect(() => {
    setOpen(show)
    // setData({
    //     id: korisnik_id,
    //     value: ""
    // })
  }, [show]);

  useEffect(() => {

    if (isLoad) {
        // setData

        setIsLoad(false)
    } else {

        var pattern = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
        );
        if (pass.filed1.length >= 7) {

            if (pattern.test(pass.filed1)) {

                if (pass.filed1 === pass.filed2 && pass.filed1 !== "") {
                    setData({
                        id: korisnik_id,
                        value: pass.filed1
                    })

                    setConfirm(false)

                    setErrorMessage("")
                } else {
                    setErrorMessage("Lozinke moraju biti jednake")
                    // message: Lozinke moraju biti jednake
                    // 


                    setConfirm(true)
                }

            } else {
                setErrorMessage("Lozinka ne zadovoljava kompleksnost lozinke")
                // message: Lozinka nije sigurna
                setConfirm(true)
            }
        } else {
            setErrorMessage("Lozinka mora biti duza od 7 znakova")

            // lozinka mora biti duza od 7 znakova
        }
    }

}, [pass]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {

    // posalji post request koji mijenja datum obavijesti

    setOpen(false);

    setData({
      id: korisnik_id,
      value: ""
    })

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(myConstClass.SERVER_URL + 'user/oldPass', requestOptions)
      .then(response => {
        response.json()
        if (response.status == 200) {
          setSnackbarMessage("Vaša lozinka ostaje ne promijenjena.")
          setSeverity("success")
          setSnackbar(true)
          //handle
          //  alert("Novi korisnik je dodan")
        } else {
          setSnackbarMessage("Doslo je do greske")
          setSeverity("error")
          setSnackbar(true)

        }
        //handleClose()
      })
  }

  function submit() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    fetch(myConstClass.SERVER_URL + 'user/pass', requestOptions)
        .then(response => {
            response.json()
            if (response.status == 200) {
                setSnackbarMessage("Lozinka je uspješno promijenjena")
                setSeverity("success")
                setSnackbar(true)
                //handle
                //  alert("Novi korisnik je dodan")
            } else {
                setSnackbarMessage("Doslo je do greske")
                setSeverity("error")
                setSnackbar(true)

            }
            handleClose()
        })
}

  return (
    <>
      <Snackbar
        // anchorOrigin={{vertical: 'center', horizontal: 'center'}}
        open={snackbar}
        autoHideDuration={3000}
        onClose={snackbarClose}

      // message = { <span> {snackbarMessage} </span>}

      >
        <AlertSnackbar onClose={snackbarClose} severity={severity == "success" ? "success" : severity == "error" ? "error" : severity == "info" ? "info" : "warning"} sx={{ width: '100%' }}>
          <span> {snackbarMessage} </span>
        </AlertSnackbar>

      </Snackbar>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Obavijest za lozinku stariju od 5 mjeseci"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Lozinka se koristi kao mjera zaštite od neovlaštenog pristupa podacima koji se nalaze i koriste na web stranici Liquid Democracy za registrirane korisnike.
            Vaša lozinka je starija od 5 mjeseci zbog čega predlažemo da promijenite postojeću lozinku sa novom. <br /> <br />
            Odabirom "Potvrdi" možete odmah promijeniti lozinku u prozoru u kojem se trenutno nalazite. <br />
            Odabirom "Odustani" lozinku možete promijeniti u bilo kojem trenutku ulaskom u "Osobni podaci".
          </DialogContentText>
          <Box sx={{ mt: 2, width: 0.8 }}>
            <TextField id="filed1" type="password" value={pass.filed1} onChange={handleChange} fullWidth label="Nova lozinka" variant="outlined" sx={{ mb: 1 }} />
            <TextField id="filed2" type="password"  value={pass.filed2} onChange={handleChange} fullWidth label="Ponoviti novu lozinku" variant="outlined" sx={{ mb: 2 }} />

          </Box>
          {errorMessage != "" ? <Alert sx={{ m: 1 }} severity="error">{errorMessage}</Alert> : <></>}

          <DialogContentText id="alert-dialog-description">
            {`Kompleksnost lozinke - Lozinka mora sadržavati:`}  <br />
            {`- minimalno 7 znakova,`}  <br />
            {`- velika slova (ABCDEFGH…)`}  <br />
            {`- mala slova (abcdefgh…)`}  <br />
            {`- brojke (123456…)`}  <br />
            {`- simbole (@#$%{ } [ ] ( ) /  ' " , ; : . < >…)`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ width: '100%' }}>
            <Grid container
              direction="row"
              justifyContent="space-between"
              alignItems="center" >
              <Grid item >
                <Item>
                  <Button onClick={handleClose} variant="contained" color="error">Odustani</Button>
                </Item>
              </Grid>
              <Grid item >
                <Item>
                  <Button variant="contained" color="success" onClick={submit} disabled={confirm}>Potvrdi</Button>
                </Item>
              </Grid>

            </Grid>
          </Box>



        </DialogActions>
      </Dialog>
    </>
  );
}
