import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "./useUser";
import { useToken } from "./useToken";

const PrivateRouteAdmin = (props) => {
  const user = useUser();
  const token = useToken();
  // checks if the JWT token has expiered
  function checkExperation() {
    if (!user) return !user;

    var dateNow = new Date();

    if (user.exp * 1000 < dateNow.getTime()) {
      token.removeToken();
      return true;
    }
  }

  function checkAdmin() {
    if (user.adminRead) {
      return true;
    }
    return false;
  }

  // if the user is not loged in redirect to login
  return checkExperation() ? (
    <Navigate to="/login" />
  ) : (
    // children
    <>{checkAdmin() ? <Outlet /> : <Navigate to="/404" />}</>
  );
};

export default PrivateRouteAdmin;
