import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SingleTheme from "componenets/Elements/SingleTheme/SingleTheme";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Loader from "componenets/Sites/Messages/Loader";

import * as myConstClass from "Constants";
import { useUser } from "auth/useUser";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

function AktivneTeme() {
  let navigate = useNavigate();

  const navigateTo = (pageLink: string) => {
    // const res = pageLink.replace(/ /g, '')
    navigate("../" + pageLink);
  };

  const user = useUser();
  const { id, korisnickoIme, ulogaID, themeWrite } = user;

  const [theme, setTheme] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const [voters, setVoters] = useState<any[]>([]);

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "theme/voters")
      .then((response) => response.json())
      .then(setVoters)
      .then(() => {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userID: id,
          }),
        };
        fetch(
          myConstClass.SERVER_URL + "theme/categories?user=" + id,
          requestOptions
        )
          .then((response) => response.json())
          .then(setTheme)
          .then(() => {
            setLoading(false);
          });
      });
  }, []);

  // Function to update state
  // on theme state change update the data in Folder List
  function dispalyCount(message) {
    if (message != undefined) {
      return message.count;
    }
    return 0;
  }

  return (
    <>
      <Container maxWidth="lg">
        <div className="wrapper">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Item>
                <h1> Teme: </h1>
              </Item>
            </Grid>
            <Grid item>
              <Item>
                {themeWrite && (
                  <Button
                    variant="outlined"
                    size="large"
                    startIcon={<ControlPointIcon />}
                    onClick={() => {
                      navigateTo(`../NovaTema`);
                    }}
                    color="success"
                    sx={{ borderRadius: 50 }}
                  >
                    Dodaj Temu
                  </Button>
                )}
              </Item>
            </Grid>
          </Grid>

          {loading ? (
            <Loader loading="Loading" />
          ) : (
            <Grid container spacing={2}>
              {theme.map((row, index) => (
                <Grid key={index} item xs={4}>
                  <Item>
                    <SingleTheme
                      icon={row.icon}
                      themeID={row.id}
                      title={row.naziv}
                      description={row.opis}
                      count={row.count}
                      pretplata={row.pretplata}
                      voters={dispalyCount(voters[index])}
                    />
                  </Item>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </Container>
    </>
  );
}

export default AktivneTeme;
