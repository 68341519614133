import React from "react";
import Button from "@mui/material/Button";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExportCSV = ({ csvData, tokenData, fileName, tokenizirana }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const exportToCSVTokenizirana = (csvData, tokenData, fileName) => {
    fileName = "Test";

    const ws1 = XLSX.utils.json_to_sheet(csvData);
    const ws2 = XLSX.utils.json_to_sheet(tokenData);

    // const ws = XLSX.utils.json_to_sheet(csvData);
    // const wb = { Sheets: { data: ws1, data1 : ws2}, SheetNames: ["Odgovori", "Tokenizirani podaci"] };
    var workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, ws1, "Sheet1");
    XLSX.utils.book_append_sheet(workbook, ws2, "Sheet2");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    // var wb = XLSX.utils.book_new();

    // XLSX.utils.book_append_sheet(wb, ws1, "Data");
    // XLSX.utils.book_append_sheet(wb, ws2, "Tokenizirani podaci");

    // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // const data = new Blob([excelBuffer], { type: fileType });
    // FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      {tokenizirana == 1 ? (
        <Button color="success" onClick={(e) => exportToCSV(csvData, fileName)}>
          Export
        </Button>
      ) : (
        <Button
          color="success"
          onClick={(e) => exportToCSVTokenizirana(csvData, tokenData, fileName)}
        >
          Export
        </Button>
      )}
    </>
  );
};
