import { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Button,
  Box,
  Card,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

function LinearnoMjerilo({ answerChange, idPitanja }) {
  const [id, setID] = useState(idPitanja);

  const [fromText, setFromText] = useState("Pocetak");

  const [toText, setToText] = useState("Kraj");

  const [from, setFrom] = useState("1");

  const [to, setTo] = useState("2");

  const handleChangeFrom = (event: SelectChangeEvent) => {
    setFrom(event.target.value as string);
  };

  const handleChangeTo = (event: SelectChangeEvent) => {
    setTo(event.target.value as string);
  };

  useEffect(() => {
    answerChange(
      [
        { naziv_odgovora: fromText, vrijednost_odgovora: from },
        { naziv_odgovora: toText, vrijednost_odgovora: to },
      ],
      id
    );
  }, [fromText, toText, from, to]);

  return (
    <div className="wrapper">
      <Grid container direction="column">
        <Grid item>
          <FormControl>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={from}
                  defaultValue={"1"}
                  onChange={handleChangeFrom}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                </Select>
              </Grid>
              <Grid item sx={{ p: 2 }}>
                do
              </Grid>
              <Grid item alignContent="right">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={to}
                  defaultValue={"2"}
                  onChange={handleChangeTo}
                >
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>

        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Grid item>{from}</Grid>
            <Grid item xs={3} sx={{ p: 2 }}>
              <TextField
                value={fromText}
                onChange={(e) => setFromText(e.target.value)}
                id="from Text"
                placeholder="Prvi label"
                fullWidth
                variant="standard"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Grid item>{to}</Grid>
            <Grid item xs={3} sx={{ p: 2 }}>
              <TextField
                value={toText}
                onChange={(e) => setToText(e.target.value)}
                id="to text"
                placeholder="Drugi Label"
                fullWidth
                variant="standard"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default LinearnoMjerilo;
