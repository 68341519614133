import * as React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  CardHeader,
  Modal,
  CardContent,
  Paper,
  Typography,
  OutlinedInput,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  TextField,
  FormHelperText,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Moment from "moment";
import { Snackbar } from "@mui/material";
import * as myConstClass from "Constants";
import BasicDatePickerName from "componenets/Elements/BasicDatePicker/BasicDatePickerName";
import BasicSelectSifarnik from "componenets/Elements/BasicSelect/BasicSelectSifarnik";
import UrediPitanja from "componenets/Elements/Pitanja/UrediPitanja";

import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useUser } from "auth/useUser";
import { useNavigate } from "react-router-dom";
import Loader from "componenets/Sites/Messages/Loader";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Modal style
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 300,
  bgcolor: "background.paper",
  border: "none !important",
  boxShadow: 24,
};

export default function UrediAnketuForm({ idankete }) {
  // Modal states
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(true);

  let navigate = useNavigate();

  const user = useUser();
  const { id, korisnickoIme, ulogaID } = user;

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  function snackbarClose(event) {
    setSnackbar(false);
  }

  function spremiPotvrdaOpen(event) {
    if (checkRequiered()) {
      handleOpen();
    }
  }

  const [inputValidation, setInputValidation] = useState({
    naslov: "",
    sifarnik_id: "",
    datum_zavrsetka: "",
    tokenizirana: "",
  });

  function validateRequieredDatePicker(value, id) {
    if (value == "" || value == "Invalid Date" || value == null) {
      const newdata = { ...inputValidation };
      newdata[id] = "Ovo polje je obavezno";
      setInputValidation(newdata);
    } else {
      const newdata = { ...inputValidation };
      newdata[id] = "";
      setInputValidation(newdata);
    }
  }

  function validateRequieredSelect(value, id) {
    if (value == "" || value == null) {
      const newdata = { ...inputValidation };
      newdata[id] = "Ovo polje je obavezno";
      setInputValidation(newdata);
    } else {
      const newdata = { ...inputValidation };
      newdata[id] = "";
      setInputValidation(newdata);
    }
  }

  function validateRequieredText(e) {
    if (e.target.value == "") {
      const newdata = { ...inputValidation };
      newdata[e.target.id] = "Ovo polje je obavezno";
      setInputValidation(newdata);
    } else {
      const newdata = { ...inputValidation };
      newdata[e.target.id] = "";
      setInputValidation(newdata);
    }
  }

  function checkRequiered() {
    let allGood = true;

    const newdata = { ...inputValidation };

    if (data.sifarnik_id == "") {
      allGood = false;
      newdata["sifarnik_id"] = "Ovo polje je obavezno";
    }
    if (data.naslov == "") {
      allGood = false;
      newdata["naslov"] = "Ovo polje je obavezno";
    }
    if (data.tokenizirana == "") {
      allGood = false;
      newdata["tokenizirana"] = "Ovo polje je obavezno";
    }
    if (data.datum_zavrsetka == "" || data.datum_zavrsetka == null) {
      allGood = false;
      newdata["datum_zavrsetka"] = "Ovo polje je obavezno";
    }
    setInputValidation(newdata);
    return allGood;
  }

  const [files, setFiles] = useState<any[]>([
    {
      pitanje_id: "0",
      dokumenti: [],
    },
  ]);

  const [serverFiles, setServerFiles] = useState<any[]>([
    {
      pitanje_id: "0",
      dokumenti: [],
    },
  ]);

  const [disabledKrediti, setIsDisabledKrediti] = useState(false);

  const [iconName, setIconName] = useState("home");
  const [isDisabled, setIsDisabled] = useState(false);

  const [data, setData] = useState({
    anketa_id: idankete,
    naslov: "",
    opis: "",
    datum_kreiranja: "",
    datum_pocetka: "",
    datum_zavrsetka: "",
    sifarnik_id: "",
    korisnik_id: id,
    status: 1,
    tokenizirana: "",
    pitanja: [
      {
        uid: 0,
        naziv_pitanja: "Prvo pitanje defauly",
        vrsta_pitanja: "1",
        dokumenti: "",
        odgovori: [
          {
            naziv: "prvi prazan odgovor",
            vrijednost: 0,
          },
        ],
      },
    ],
  });

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "fileManager?anketaid=" + idankete)
      .then((response) => response.json())
      .then((dataJSON) => {
        setData(dataJSON);

        dataJSON.pitanja.forEach((pitanje, index) => {
          let dokumenti = pitanje.dokumenti.split(",");
          dokumenti.pop();

          dokumenti.forEach((fileName, fileIndex) => {
            fetch(
              myConstClass.SERVER_URL +
                `fileManager/download?url=uploads/temp/anketaDraft_` +
                idankete +
                `/pitanje_${index}/${fileName}`
            ).then((response) => {
              response.blob().then((blob) => {
                var file = new File([blob], fileName);

                const newdata = { ...serverFiles };
                newdata[index].dokumenti =
                  newdata[index].dokumenti.concat(file);
                setServerFiles(newdata);
              });
            });
          });
        });
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  function updateQuestions(updatedPitanja) {
    const newdata = { ...data };
    newdata["pitanja"] = updatedPitanja;
    setData(newdata);
  }

  function submit() {
    data.datum_kreiranja = Moment().format("YYYY-MM-DD");
    if (checkRequiered()) {
      // DELETE OLD FILES
      fetch(
        myConstClass.SERVER_URL + "fileManager/removeTemp?anketaid=" + idankete
      ).then(function (response) {
        if (response.status == 200) {
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          };
          fetch(
            myConstClass.SERVER_URL + "poll/updatePoll?anketaid=" + idankete,
            requestOptions
          )
            .then((response) => {
              if (response.status != 200) {
                setSnackbarMessage("Doslo je do greske");
                setSeverity("error");
                setSnackbar(true);
                return [];
              }
              return response.json();
            })
            .then((data) => {
              if (data != null) {
                uplodaFilesTemp(data.uploadUrl);
              }
            });
        } else {
          // Wait for 2 seconds
          return false;
          //navigate to new theme
          // navi
        }
      });
    }
  }

  function uplodaFilesTemp(tempUrl) {
    // adding all files that will be uploaded to server

    for (var x = 0; x < files.length; x++) {
      const data = new FormData();
      for (var i = 0; i < files[x].dokumenti.length; i++) {
        data.append("myFiles", files[x].dokumenti[i]);
      }

      let questionUrl = tempUrl + `/pitanje_` + x;

      let response = sendFilesTemp(data, questionUrl);

      if (!response) {
        setSnackbarMessage("Doslo je do greske");
        setSeverity("error");
        setSnackbar(true);

        return;
      }
      //
    }
    // navigate na novu anketu

    setSnackbarMessage("Nova anketa je dodana");
    setSeverity("success");
    setSnackbar(true);

    setTimeout(() => {
      navigateTo(`../MojeAnkete`, "", "");
    }, 2000);
  }

  function sendFilesTemp(filesData, pitanjeUrl) {
    const requestOptions = {
      method: "POST",
      body: filesData,
    };

    fetch(
      myConstClass.SERVER_URL +
        "fileManager/uploadmultipleTemp?url=" +
        pitanjeUrl,
      requestOptions
    ).then(function (response) {
      if (response.status == 200) {
      } else {
        // Wait for 2 seconds
        return false;
        //navigate to new theme
        // navi
      }
    });
    return true;
  }

  function publish() {
    if (checkRequiered()) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      fetch(
        myConstClass.SERVER_URL +
          "poll/publishUpdatedPoll?anketaid=" +
          idankete,
        requestOptions
      )
        .then((response) => {
          if (response.status != 200) {
            setSnackbarMessage("Doslo je do greske");
            setSeverity("error");
            setSnackbar(true);
            return [];
          }
          return response.json();
        })
        .then((data) => {

          // TODO implement delete files

          if (data.length > 0) {
            uplodaFiles("pitanje", data, "objavi");
          }
        });
    }
  }

  function uplodaFiles(type, listOfIDs, pollType) {
    // adding all files that will be uploaded to server

    for (var x = 0; x < files.length; x++) {
      const data = new FormData();

      for (var i = 0; i < files[x].dokumenti.length; i++) {
        data.append("myFiles", files[x].dokumenti[i]);
      }

      let response = sendFiles(type, data, listOfIDs[x].anketa_pitanja_id);

      if (!response) {
        // someting
        setSnackbarMessage("Doslo je do greske");
        setSeverity("error");
        setSnackbar(true);

        return;
      }
      //
    }
    // navigate na novu anketu

    setSnackbarMessage("Nova anketa je dodana");
    setSeverity("success");
    setSnackbar(true);
  

    setTimeout(() => {
      navigateTo(`../Anketa`, `${idankete}`, data.naslov);
    }, 2000);

    //TODO: Navigate na temu
  }

  function sendFiles(type, filesData, id) {
    const requestOptions = {
      method: "POST",
      body: filesData,
    };

    fetch(
      myConstClass.SERVER_URL +
        "fileManager/uploadmultiple?type=" +
        type +
        "&id=" +
        id,
      requestOptions
    ).then(function (response) {
      if (response.status == 200) {
      } else {
        // Wait for 2 seconds
        return false;
        //navigate to new theme
        // navi
      }
    });
    return true;
  }

  const navigateTo = (
    pageLink: string,
    anketa_id: String,
    anketa_naslov: String
  ) => {
    // Replacing " " (space) to "" empty space
    const res = pageLink.replace(/ /g, "");

    navigate("../" + res, {
      replace: true,
      state: { anketaID: anketa_id, anketaNaslov: anketa_naslov },
    });
  };

  const handleChangeSelectTokenizirana = (event: SelectChangeEvent) => {
    const newdata = { ...data };
    newdata["tokenizirana"] = event.target.value;
    setData(newdata);
    validateRequieredSelect(event.target.value, "tokenizirana");

    if (data.tokenizirana == "netokenizirana") {
      setIsDisabledKrediti(true);
    } else {
      setIsDisabledKrediti(false);
    }
  };

  function handleChange(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  function handleSelectChange(name, e) {
    const newdata = { ...data };
    newdata[name] = e.target.value;
    setData(newdata);
  }

  function handleValueChange1(name, value) {
    const newdata = { ...data };
    newdata[name] = value;
    newdata.datum_pocetka = Moment(newdata.datum_pocetka).format(
      "YYYY-MM-DD HH:MM:SS"
    );
    setData(newdata);
  }

  function handleValueChange2(name, value) {
    const newdata = { ...data };
    newdata[name] = value;
    newdata.datum_zavrsetka = Moment(newdata.datum_zavrsetka).format(
      "YYYY-MM-DD HH:MM:SS"
    );
    setData(newdata);
  }

  return (
    <>
      <Snackbar
        // anchorOrigin={{vertical: 'center', horizontal: 'center'}}
        open={snackbar}
        autoHideDuration={3000}
        onClose={snackbarClose}

        // message = { <span> {snackbarMessage} </span>}
      >
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>

      {loading ? (
        <Loader loading="Loading" />
      ) : (
        <>
          <Card
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1.5 },
              // '& .MuiBox-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <CardHeader
              sx={{ bgcolor: "#086AE2", color: "white" }}
              title={"Uređivanje ankete"}
            />

            <div>
              <Grid container spacing={1}>
                <Accordion sx={{ width: "100%" }} defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={7}>
                          <Item sx={{ m: 1 }}>
                            <FormControl
                              required
                              fullWidth
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <TextField
                                fullWidth
                                id="naslov"
                                label="Naslov"
                                type="text"
                                helperText={inputValidation.naslov}
                                error={inputValidation.naslov != ""}
                                value={data.naslov}
                                onChange={(e) => {
                                  handleChange(e);
                                  validateRequieredText(e);
                                }}
                                onBlur={validateRequieredText}
                              />
                            </FormControl>
                          </Item>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        {/* Third column */}
                        <Grid item xs={12}>
                          <Item sx={{ m: 1 }}>
                            <BasicSelectSifarnik
                              id="sifarnik_id"
                              onChange={handleSelectChange}
                              name="Kategorija *"
                              tableName="codebook/name"
                              dedaultValue={data.sifarnik_id}
                              helperText={inputValidation.sifarnik_id}
                              error={inputValidation.sifarnik_id != ""}
                              onClose={validateRequieredSelect}
                            />{" "}
                          </Item>
                        </Grid>

                        <Grid item xs={12}>
                          <Item sx={{ m: 1 }}>
                            <FormControl required fullWidth>
                              <InputLabel htmlFor="outlined-adornment-amount">
                                Opis
                              </InputLabel>
                              <OutlinedInput
                                id="opis"
                                multiline
                                rows={5}
                                value={data.opis}
                                onChange={handleChange}
                                label="Naslov"
                              />
                            </FormControl>
                          </Item>
                        </Grid>
                      </Grid>
                      {/* Right part of the form */}
                      <Grid item xs={5}>
                        <Grid container spacing={1} sx={{ m: 1 }}>
                          <Grid container spacing={1}>
                            <Grid item>
                              <FormControl required fullWidth>
                                <BasicDatePickerName
                                  id="datum_zavrsetka"
                                  onChange={handleValueChange2}
                                  defaultValue={data.datum_zavrsetka}
                                  name="aktivan do *"
                                  error={inputValidation.datum_zavrsetka != ""}
                                  helperText={inputValidation.datum_zavrsetka}
                                  onClose={validateRequieredDatePicker}
                                />
                                {/* <BasicDatePickerName
                                  id="datum_zavrsetka"
                                  onChange={handleValueChange2}
                                  name="aktivan do *"
                                  defaultValue={data.datum_zavrsetka}
                                 
                                /> */}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container spacing={1} sx={{ m: 1 }}>
                          <Grid container spacing={1}>
                            <Grid item xs={7}>
                              <Item>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid item xs={9}>
                                    <FormControl
                                      required
                                      fullWidth
                                      error={inputValidation.tokenizirana != ""}
                                      sx={{ ml: "12px" }}
                                    >
                                      <InputLabel id="tokenizirana">
                                        Tokenizirana
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="tokenizirana"
                                        value={data.tokenizirana}
                                        label="Tokenizirana"
                                        onChange={
                                          handleChangeSelectTokenizirana
                                        }
                                      >
                                        <MenuItem key="Tokenizirana" value="0">
                                          Netokenizirana
                                        </MenuItem>
                                        <MenuItem
                                          key="Netokenizirana"
                                          value="1"
                                        >
                                          Tokenizirana
                                        </MenuItem>
                                      </Select>
                                      <FormHelperText>
                                        {" "}
                                        {inputValidation.tokenizirana}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>

                                  <Grid item>
                                    <Tooltip title="Za svako kreirano pitanje sustav automatski dodjeljuje 10 bodova. Ovisno o ukupno broju pitanja, sustav automatski definira ukupan broj bodova (10 bodova x broj pitanja) koje će na raspolaganju imati anketirana osoba. Anketiranoj osobi može definirati za svako pitanje određenu vrijednost bodova. Anketirana osoba samostalno određuje vrijedno pitanja na način da u polju pored naslova pitanja upisuje vrijednost boda koju dodjeljuje pitanju. Broj bodova može ravnomjerno rasporediti na sva pitanja ili jednom pitanju može dodijeliti sve bodove.">
                                      <IconButton>
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Item>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Item sx={{ m: 3 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Item>
                                <Button
                                  onClick={publish}
                                  variant="contained"
                                  color="success"
                                >
                                  Objavi Anketu
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={6}>
                              <Item>
                                <Button
                                  onClick={spremiPotvrdaOpen}
                                  variant="contained"
                                >
                                  Spremi Anketu
                                </Button>
                              </Item>
                            </Grid>
                          </Grid>
                        </Item>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </div>
          </Card>

          <UrediPitanja
            anketaID={idankete}
            data={data.pitanja}
            updateQuestions={updateQuestions}
            tokenizirano={data.tokenizirana}
            setFiles={setFiles}
            files={files}
            serverFiles={serverFiles}
          />
        </>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}> */}
        <Card sx={style}>
          <CardHeader
            sx={{ bgcolor: "#086AE2", color: "white" }}
            title={"Potvrda"}
          />

          <CardContent>
            <Typography
              id="modal-modal-title"
              variant="body1"
              component="body"
              sx={{ mt: 3, mb: 1, pl: 4, pr: 4 }}
            >
              Klikom na potvrdi anketa će biti spremljena kao draft, što znači
              da čete ju samo vi vidjeti i moći uređivati.
            </Typography>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
              sx={{ mt: 2, pl: 4, pr: 4 }}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 4 }}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      color="error"
                    >
                      Zatvori
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" onClick={submit} color="success">
                      Potvrdi
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* </Box> */}
      </Modal>
    </>
  );
}
