
import { CardContent, Typography, Card, Grid, Container } from '@mui/material';

import { styled } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { red } from '@mui/material/colors';



interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function Pitanje() {

    return (

        <Container maxWidth="md">

            <Card sx={{ mt: 3 }}>
                <CardHeader
                    sx={{ bgcolor: red[500] }}
                />

                <CardContent>

                    <Grid container
                        justifyContent="space-between"
                        spacing={2}>
                        <Grid item xs={6} md={6}>
                            <Typography variant="h5" component="h2">
                                Tekst pitanja
                            </Typography>

                        </Grid>
                        <Grid item xs={6} md={4}>
                        </Grid>

                    </Grid>

                    <br />
                    {/* <KratkiOdgovor /> */}

                </CardContent>
            </Card>

            <Card sx={{ mt: 3 }}>
                <CardHeader
                    sx={{ bgcolor: red[500] }}
                />

                <CardContent>

                    <Grid container
                        justifyContent="space-between"
                        spacing={2}>
                        <Grid item xs={6} md={6}>
                            <Typography variant="h5" component="h2">
                                Tekst pitanja
                            </Typography>

                        </Grid>
                        <Grid item xs={6} md={4}>
                        </Grid>

                    </Grid>

                    <br />
                    {/* <LinearnoMjerilo /> */}

                </CardContent>
            </Card>


            <Card sx={{ mt: 3 }}>
                <CardHeader
                    sx={{ bgcolor: red[500] }}
                />

                <CardContent>

                    <Grid container
                        justifyContent="space-between"
                        spacing={2}>
                        <Grid item xs={6} md={6}>
                            <Typography variant="h5" component="h2">
                                Tekst pitanja
                            </Typography>

                        </Grid>
                        <Grid item xs={6} md={4}>
                        </Grid>

                    </Grid>

                    <br />
                    {/* <PotvrdniOkvir /> */}

                </CardContent>
            </Card>

            <Card sx={{ mt: 3 }}>
                <CardHeader
                    sx={{ bgcolor: red[500] }}
                />

                <CardContent>

                    <Grid container
                        justifyContent="space-between"
                        spacing={2}>
                        <Grid item xs={6} md={6}>
                            <Typography variant="h5" component="h2">
                                Tekst pitanja
                            </Typography>

                        </Grid>
                        <Grid item xs={6} md={4}>
                        </Grid>

                    </Grid>

                    <br />
                    {/* <VisestrukiOdgovor /> */}

                </CardContent>
            </Card>
        </ Container >
    );
}

export default Pitanje;