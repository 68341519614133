import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { OutlinedInput, InputLabel, SelectChangeEvent, Select, MenuItem } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Muški',
    'Ženski',
    'Ostalo'
];

function getStyles(name: string, personName: string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function GenderSelectMini({ id, onChange, defaultValue, disabled }) {
    const theme = useTheme();
    const [personName, setPersonName] = React.useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<typeof personName>) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        onChange(id, event)
    };

    return (
        <>
            {/* <InputLabel id="demo-multiple-name-label">Spol</InputLabel> */}
            <Select
                defaultValue={defaultValue}
                //   labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={defaultValue}
                onChange={handleChange}
                //   input={<OutlinedInput label="Name" />}
                size="small"
                disabled={disabled}
                MenuProps={MenuProps}>
                {names.map((name) => (
                    <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, personName, theme)}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
}