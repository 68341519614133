import { useContext, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom';
import Navbar from '../componenets/Navigation/Navbar/Navbar';
import Footer from '../componenets/Elements/Footer/Footer';

import Login from '../componenets/Sites/Login/Login';
import Home from '../componenets/Sites/Home/Home';

import AktivneAnkete from '../componenets/Sites/AktivneAnkete/AktivneAnkete';
import AktivneTeme from '../componenets/Sites/AktivneTeme/AktivneTeme';
import NoviKorisnik from '../componenets/Sites/Administracija/NoviKorisnik/NoviKorisnik';
import Administracija from '../componenets/Sites/Administracija/Administracija';
import Korisnici from '../componenets/Sites/Administracija/Korisnici/Korisnici';
import SifarnikAnketaIGlasovanja from '../componenets/Sites/Administracija/SifarnikAnketaIGlasovanja/SifarnikAnketaIGlasovanja';
import Page404 from '../componenets/Sites/Messages/Page404';
import UpravljanjeUlogama from '../componenets/Sites/Administracija/UpravljanjeUlogama/UpravljanjeUlogama';
import Odgovornosti from '../componenets/Sites/Administracija/Odgovornosti/Odgovornosti';
import Loader from 'componenets/Sites/Messages/Loader';
import SifarnikLokacija from 'componenets/Sites/Administracija/SifarnikLokacija/SifarnikLokacija';
import BlockchainVerifikacija from 'componenets/Sites/Administracija/BlockchainVerifikacija/BlockchainVerifikacija';
import SifarnikObrazovanja from 'componenets/Sites/Administracija/SifarnikObrazovanja/SifarnikObrazovanja';
import SifarnikZanimanja from 'componenets/Sites/Administracija/SifarnikZanimanja/SIfarnikZanimanja';
import Obavijesti from 'componenets/Sites/Administracija/Obavijesti/Obavijesti';
import OdobravanjePredstavnika from 'componenets/Sites/Administracija/OdobravanjePredstavnika/OdobravanjePredstavnika';
import KonfiguracijaMaila from 'componenets/Sites/Administracija/KonfiguracijaMaila/KonfiguracijaMaila';
import Pitanje from 'componenets/Elements/Pitanje/Pitanje';


import Teme from 'componenets/Sites/Teme/Teme';
import Tema from 'componenets/Sites/Tema/Tema';
import Anketa from 'componenets/Sites/Anketa/Anketa'
import Results from 'componenets/Sites/Results/Results'

import DodajTemu from 'componenets/Sites/Tema/DodajTemu/DodajTemu';
import DodajAnketu from 'componenets/Sites/Anketa/DodajAnketu'
import PrivateRoute from 'auth/PrivateRoute';
import PrivateRouteAdmin from 'auth/PrivateRouteAdmin'
import UserPage from 'componenets/Sites/UserPage/UserPage';
import Mojeprijave from 'componenets/Sites/UserPage/Mojeprijave';
import Ankete from 'componenets/Sites/Ankete/Ankete';
import MojeAnkete from 'componenets/Sites/Ankete/MojeAnkete';
import UrediAnketu from 'componenets/Sites/Anketa/UrediAnketu';

import { UNSAFE_NavigationContext } from "react-router-dom";

const useBackListener = (callback) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
    const listener = ({ location, action }) => {
      if (action === "POP") {
        callback({ location, action });
      }
    };

    const unlisten = navigator.listen(listener);
    return unlisten;
  }, [callback, navigator]);
};

export default function Router() {

  return (<>
    <Navbar />
    <Routes>

    {/* <BackButtonListener /> */}
      <Route exact path="/" element={<PrivateRoute />} >
        <Route exact path='/' element={<Home />} />
      </Route>
      <Route exact path="/login" element={< Login />} />

      <Route exact path="/AktivneAnkete" element={<PrivateRoute />} >
        <Route exact path='/AktivneAnkete' element={<AktivneAnkete />} />
      </Route>
      <Route exact path="/AktivneTeme" element={<PrivateRoute />} >
        <Route exact path='/AktivneTeme' element={<AktivneTeme />} />
      </Route>
      <Route exact path="/Teme/:selectedTopic" element={<PrivateRoute />} >
        <Route exact path='/Teme/:selectedTopic' element={<Teme />} />
      </Route>
      <Route exact path="/Administracija" element={<PrivateRouteAdmin />} >
        <Route exact path='/Administracija' element={<Administracija />} />
      </Route>
      <Route exact path="/Korisnici" element={<PrivateRouteAdmin />} >
        <Route exact path='/Korisnici' element={<Korisnici />} />
      </Route>
      <Route exact path="/SifarnikAnketa" element={<PrivateRouteAdmin />} >
        <Route exact path='/SifarnikAnketa' element={<SifarnikAnketaIGlasovanja />} />
      </Route>
      <Route exact path="/NoviKorisnik" element={<PrivateRouteAdmin />} >
        <Route exact path='/NoviKorisnik' element={<NoviKorisnik />} />
      </Route>
      <Route exact path="/Uloge" element={<PrivateRouteAdmin />} >
        <Route exact path='/Uloge' element={<UpravljanjeUlogama />} />
      </Route>
      <Route exact path="/Odgovornosti/:selectedRole" element={<PrivateRouteAdmin />} >
        <Route exact path='/Odgovornosti/:selectedRole' element={<Odgovornosti />} />
      </Route>
      <Route exact path="/SifarnikLokacije" element={<PrivateRouteAdmin />} >
        <Route exact path='/SifarnikLokacije' element={<SifarnikLokacija />} />
      </Route>
      <Route exact path="/BlockchainVerifikacija" element={<PrivateRouteAdmin />} >
        <Route exact path='/BlockchainVerifikacija' element={<BlockchainVerifikacija />} />
      </Route>      
      <Route exact path="/SifarnikObrazovanja" element={<PrivateRouteAdmin />} >
        <Route exact path='/SifarnikObrazovanja' element={<SifarnikObrazovanja />} />
      </Route>
      <Route exact path="/SifarnikZanimanja" element={<PrivateRouteAdmin />} >
        <Route exact path='/SifarnikZanimanja' element={<SifarnikZanimanja />} />
      </Route>
      <Route exact path="/Home" element={<PrivateRoute />} >
        <Route exact path='/Home' element={<Home />} />
      </Route>
      <Route exact path="/Home/:selectedRole" element={<PrivateRoute />} >
        <Route exact path='/Home/:selectedRole' element={<Home />} />
      </Route>
     
      <Route exact path="/Tema" element={<PrivateRoute />} >
        <Route exact path='/Tema' element={<Tema />} />
      </Route>
      <Route exact path="/Obavijesti" element={<PrivateRouteAdmin />} >
        <Route exact path='/Obavijesti' element={<Obavijesti />} />
      </Route>
      <Route exact path="/NovaTema" element={<PrivateRoute />} >
        <Route exact path='/NovaTema' element={<DodajTemu />} />
      </Route>
      <Route exact path="/OdobravanjePredstavnika" element={<PrivateRoute />} >
        <Route exact path='/OdobravanjePredstavnika' element={<OdobravanjePredstavnika />} />
      </Route>
      <Route exact path="/KonfiguracijaMaila" element={<PrivateRouteAdmin />} >
        <Route exact path='/KonfiguracijaMaila' element={<KonfiguracijaMaila />} />
      </Route>
      <Route exact path="/Mojeprijave" element={<PrivateRoute />} >
        <Route exact path='/Mojeprijave' element={<Mojeprijave />} />
      </Route>
      <Route exact path="/Pitanja" element={<PrivateRoute />} >
        <Route exact path='/Pitanja' element={<Pitanje />} />
      </Route>
      <Route exact path="/NovaAnketa" element={<PrivateRoute />} >
        <Route exact path='/NovaAnketa' element={<DodajAnketu />} />
      </Route>
      <Route exact path="/Anketa" element={<PrivateRoute />} >
        <Route exact path='/Anketa' element={<Anketa />} />
      </Route>
      <Route exact path="/Rezultati" element={<PrivateRoute />} >
        <Route exact path='/Rezultati' element={<Results />} />
      </Route>
      <Route exact path="/MojeAnkete" element={<PrivateRoute />} >
        <Route exact path='/MojeAnkete' element={<MojeAnkete />} />
      </Route>
      <Route exact path="/UrediAnketu" element={<PrivateRoute />} >
        <Route exact path='/UrediAnketu' element={<UrediAnketu />} />
      </Route>
      <Route exact path="/Ankete/:selectedTopic" element={<PrivateRoute />} >
        <Route exact path='/Ankete/:selectedTopic' element={<Ankete />} />
      </Route>
      <Route exact path="/Profil" element={<PrivateRoute />} >
        <Route exact path='/Profil' element={<UserPage />} />
      </Route>
      <Route path="*" element={<Page404 />} />
      <Route path="/404" element={<Page404 />} />
      <Route exact path="/Loader" element={<Loader loading="Loading" />} />

    </Routes>
    <Footer />

  </>
  );
};