import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { TextField, CardContent, Typography, Button, Box, Card, Grid, Snackbar, Alert } from '@mui/material';


function KratkiOdgovor() {
  return (
    <div className="wrapper">
      <TextField id="standard-basic" label="Text odgovora" disabled variant="standard" />
    </div>
  );
}

export default KratkiOdgovor;