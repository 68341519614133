import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import './SingleTheme.css';
import { Card, ButtonBase, Grid, Paper, Box, Tooltip } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GavelIcon from '@mui/icons-material/Gavel';
import PersonIcon from '@mui/icons-material/Person';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import IconButton from '@mui/material/IconButton';
import PollIcon from '@mui/icons-material/Poll';
import Avatar from '@mui/material/Avatar';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import * as myConstClass from 'Constants';
import { useNavigate } from 'react-router-dom';
import * as MuiIcons from '@mui/icons-material';
import { createSvgIcon } from '@mui/material/utils';
import Icon from '@mui/material/Icon';
import { Add } from '@material-ui/icons'
import HowToVoteIcon from '@mui/icons-material/HowToVote';

import { useUser } from 'auth/useUser';

const Item = styled(Paper)(({ theme }) => ({

    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none'
}));

const DinamicIcon = ({ name, ...rest }) => {

    if (name != null) {
        let cleanName = name.split(" ")
        const IconComponent = MuiIcons[cleanName[0]];
        return IconComponent ? <IconComponent fontSize="large"  {...rest} /> : <HowToVoteIcon />;
    } else {
        return <HowToVoteIcon fontSize="large" />
    }
};

export default function SingleTheme({ icon, themeID, title, description, count, pretplata, voters }) {

    const [bookmark, setBookmark] = useState(pretplata);

    let navigate = useNavigate();

    const user = useUser();
    const { id, korisnickoIme, ulogaID, lozinka_status } = user

    const navigateTo = (pageLink: string) => {
        // Replacing " " (space) to "" empty space
        const res = pageLink.replace(/ /g, '')
        navigate("../" + res, { replace: false, state: { urlName: `${title}`, subscription: { bookmark }, id: themeID, icon: icon } },);

    };

    const [subscribeData, setSubscribeData] = useState({
        themeID: themeID,
        userID: id,
        status: pretplata
    });


    function handleClick() {
        setBookmark(!bookmark)

        if (pretplata == null && subscribeData.status == null) {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(subscribeData)
            };
            fetch(myConstClass.SERVER_URL + 'subscription/newSubscription', requestOptions)
                .then((response) => response.json()
                ).then((result) => {
                    setBookmark(result.status); //3
                    setSubscribeData({
                        themeID: id,
                        userID: user.id,
                        status: result.status
                    })
                    pretplata = bookmark;
                })
        } else {
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(subscribeData)
            };
            fetch(myConstClass.SERVER_URL + 'subscription/updateSubscription', requestOptions)
                .then((response) => response.json()
                ).then((result) => {
                    setBookmark(result.status); //3
                    setSubscribeData({
                        themeID: id,
                        userID: user.id,
                        status: result.status
                    })
                })
        }
    }

    return (

        <Card sx={{ maxWidth: 345 }} style={{cursor: 'pointer'}}>

            <Grid container
                justifyContent="space-between"
                alignItems="baseline">
                <Grid item>
                    <Item></Item>
                </Grid>
                <Grid item >
                    <Item sx={{ p: 1 }}>
                        {bookmark == false || bookmark == null ?

                            <Tooltip title="Pretplati me">
                                <BookmarkBorderIcon fontSize="large" onClick={handleClick} />
                            </Tooltip> :
                            <Tooltip title="Odjavi pretplatu">
                                <BookmarkIcon fontSize="large" onClick={handleClick} />
                            </Tooltip>}
                    </Item>
                </Grid>
            </Grid>
            <Box onClick={() => { navigateTo(`../Teme/${title}`); }}>
                <Divider>
                    <Avatar sx={{ p: 2 }}>
                        <DinamicIcon name={icon} />
                    </Avatar>
                </Divider>



                <CardContent sx={{ mt: 1 }}>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
                <Divider flexItem />

                <CardActions>

                    <Avatar sx={{ m: 1, ml: 2, mr: 1 }}>
                        <PersonIcon />
                    </Avatar>    {voters}

                    <Divider sx={{ ml: 9, mr: 2 }} orientation="vertical" flexItem />

                    <Avatar sx={{ m: 1 }}>
                        <PollIcon />
                    </Avatar>    {count}
                </CardActions>
            </Box>
        </Card>

    );
}
