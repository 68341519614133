import { Breadcrumbs } from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import BarChart from "componenets/Elements/Charts/BarChart";
import HorizontalBarChart from "componenets/Elements/Charts/HorizontalBarChart";
import VerticalBarChart from "componenets/Elements/Charts/VerticalBarChart";

import ShortAnswers from "componenets/Elements/Charts/ShortAnswers";
import PieChart from "componenets/Elements/Charts/PieChart";

import { useParams } from "react-router-dom";
import {
  CardHeader,
  CardContent,
  TextField,
  Typography,
  Button,
  Box,
  Card,
  Grid,
  Divider,
  Switch,
  Container,
  Tooltip,
} from "@mui/material";
import * as myConstClass from "Constants";
import { ExportCSV } from "services/ExportCSV";

type LocationState = { anketaID: string; anketaNaslov: any };

function Results() {
  // gets the list of questions for this poll by id

  // pitanje id, naslov, broj odgovora, vrsta pitanja
  const [poll, setPoll] = useState({
    tokenizirana: 3,
  });
  const [pollResults, setPollResults] = useState([]);
  const [tokenData, setTokenData] = useState([]);

  const [pitanja, setPitanja] = useState([
    {
      anketa_pitanja_id: "",
      naziv_pitanja: "naslov",
      vrstapitanja_id: "",
      brojOdgovora: "",
    },
  ]);

  const location = useLocation();

  const { anketaID, anketaNaslov } = location.state as LocationState;

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "poll/results?anketa=" + anketaID)
      .then((response) => response.json())
      .then((data) => {
        setPitanja(data);
        // dohvati pitanja od te ankete
        fetch(myConstClass.SERVER_URL + "poll?anketa=" + anketaID)
          .then((response) => response.json())
          .then((pollData) => {
            setPoll(pollData);

            if (pollData[0].tokenizirana == 1)
              fetch(
                myConstClass.SERVER_URL +
                  "poll/getPollDataTonekizirana?anketa=" +
                  anketaID
              )
                .then((response) => response.json())
                .then((data) => {
                  setTokenData(data);
                });
          });
      });
  }, []);

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "poll/getPollData?anketa=" + anketaID)
      .then((response) => response.json())
      .then((data) => {
        setPollResults(data);
      });
  }, []);

  // pozove stvaranje grafova ovisno o vrsiti pitanja i proslijedi pitanje id

  return (
    <div className="wrapper">
      <Container maxWidth="md">
        <Card sx={{ mt: 5, p: 3 }}>
          <Typography variant="h4" gutterBottom>
            {anketaNaslov}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            <ExportCSV
              csvData={pollResults}
              fileName={`${anketaNaslov}-rezultati`}
              tokenizirana={poll.tokenizirana}
              tokenData={tokenData}
            />
          </Typography>
        </Card>

        {pitanja.map((pitanje, index) => (
          <Card sx={{ mt: 3 }}>
            <CardHeader sx={{ bgcolor: "#086AE2" }} />

            <CardContent>
              {pitanje.vrstapitanja_id == "1" ? (
                <PieChart
                  title={pitanje.naziv_pitanja}
                  pitanje_id={pitanje.anketa_pitanja_id}
                />
              ) : (
                <></>
              )}
              {pitanje.vrstapitanja_id == "2" ? (
                <>
                  <VerticalBarChart
                    title={pitanje.naziv_pitanja}
                    pitanje_id={pitanje.anketa_pitanja_id}
                  />
                  {/* <HorizontalBarChart title={pitanje.naziv_pitanja} pitanje_id={pitanje.anketa_pitanja_id} />  */}
                </>
              ) : (
                <></>
              )}
              {pitanje.vrstapitanja_id == "3" ? (
                <ShortAnswers
                  title={pitanje.naziv_pitanja}
                  pitanje_id={pitanje.anketa_pitanja_id}
                />
              ) : (
                <></>
              )}
              {pitanje.vrstapitanja_id == "4" ? (
                <BarChart
                  title={pitanje.naziv_pitanja}
                  pitanje_id={pitanje.anketa_pitanja_id}
                />
              ) : (
                <></>
              )}
            </CardContent>
          </Card>
        ))}
      </Container>
    </div>
  );
}

export default Results;
