import React, { useState, useEffect } from "react";
import FolderList from "componenets/Elements/FolderList/FolderList";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  Box,
  IconButton,
  TextField,
  FormControl,
  Paper,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import * as myConstClass from "Constants";
import ThemeBreadcrumbs from "componenets/Navigation/ThemeBreadcrumbs/ThemeBreadcrumbs";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useUser } from "auth/useUser";
import CustomTabs from "componenets/Elements/Tabs/CustomTabs";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import * as MuiIcons from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

type LocationState = {
  urlName: string;
  subscription: any;
  id: string;
  icon: string;
};

function Teme() {
  // const { state } : { state: LocationState } = useLocation();
  // // const { title } = state;
  // const {urlName, subscription} = state
  const user = useUser();

  const location = useLocation();
  const { urlName, subscription, id, icon } = location.state as LocationState;

  const [themeLocationData, setThemeLocationData] = useState({
    urlName: urlName,
    subscription: subscription,
    theemID: id,
    icon: icon,
  });

  const [name, setName] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const [theme, setTheme] = useState("Sve");
  const [data, setData] = useState<any[]>([]);

  const [filterData, setFilterData] = useState({
    filteredValue: "",
    theme: urlName,
    status: tabValue,
    korisnik_id: user.id,
  });
  const [subscribed, setSubscribed] = useState(subscription.bookmark);

  const [subscribeData, setSubscribeData] = useState({
    themeID: id,
    userID: user.id,
    status: subscribed,
  });

  const DinamicIcon = ({ name, ...rest }) => {
    if (name != null) {
      let cleanName = name.split(" ");
      const IconComponent = MuiIcons[cleanName[0]];
      return IconComponent ? (
        <IconComponent fontSize="large" {...rest} />
      ) : (
        <HowToVoteIcon />
      );
    } else {
      return <HowToVoteIcon fontSize="large" />;
    }
  };

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(filterData),
    };
    fetch(myConstClass.SERVER_URL + "theme/filter", requestOptions)
      .then((response) => response.json())
      .then(setData);

  }, [tabValue]);

  function searchFilter() {

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(filterData),
    };
    fetch(myConstClass.SERVER_URL + "theme/filter", requestOptions)
      .then((response) => response.json())
      .then(setData);
  }

  // on theme state change update the data in Folder List
  const handleInputChange = (e) => {
    const newdata = { ...filterData };
    newdata[e.target.id] = e.target.value;
    setFilterData(newdata);
  };

  function handleTabChange(newValue) {

    // if newValue == 0 pozovi aktivne ankete

    // if newaValue == 1 pozovi neaktinve ankete
    const newdata = { ...filterData };
    newdata["status"] = newValue;
    setFilterData(newdata);

    setTabValue(newValue);
  }

  function subscribeOnClick() {
    // setSubscribed(!subscribed)

    if (subscribed == null) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(subscribeData),
      };
      fetch(
        myConstClass.SERVER_URL + "subscription/newSubscription",
        requestOptions
      )
        .then((response) => {
          response.json();
       
        })
        .then(setSubscribed);
    } else {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(subscribeData),
      };
      fetch(
        myConstClass.SERVER_URL + "subscription/updateSubscription",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setSubscribed(result.status); //3
          setSubscribeData({
            themeID: id,
            userID: user.id,
            status: result.status,
          });
        });
      // .then(setSubscribed);
    }
  }

  return (
    <Container maxWidth="lg">
      <div className="wrapper">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} sx={{ m: 2 }}>
            <Grid item xs={6}>
              <h1>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <Avatar sx={{ p: 0.5, mr: 1 }}>
                      <DinamicIcon name={icon} />
                    </Avatar>
                  </Grid>
                  <Grid item>{urlName} - Teme</Grid>
                </Grid>
              </h1>
              <ThemeBreadcrumbs currentPage={urlName} />
            </Grid>
            <Grid item xs={2} sx={{ mt: 1.5 }}>
              {subscribed ? (
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<RemoveCircleIcon />}
                  onClick={subscribeOnClick}
                >
                  Ukloni pretplatu
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="success"
                  startIcon={<CheckCircleIcon />}
                  onClick={subscribeOnClick}
                >
                  Pretplati me
                </Button>
              )}
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
                spacing={2}
              >
                <Grid item>
                  <Item>
                    <Box
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1.5, width: "25ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <FormControl required fullWidth sx={{ m: 1 }}>
                        <TextField
                          id="filteredValue"
                          value={filterData.filteredValue}
                          onChange={handleInputChange}
                          label="Traži"
                          size="small"
                        />
                      </FormControl>
                    </Box>
                  </Item>
                </Grid>

                <IconButton
                  sx={{ ml: 1, mt: 2 }}
                  aria-label="search"
                  onClick={searchFilter}
                >
                  <SearchIcon style={{ fill: "black" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Container maxWidth="md">
          <CustomTabs tabChange={handleTabChange} />

          <FolderList data={data} themeLocationData={themeLocationData} />
        </Container>
      </div>
    </Container>
  );
}

export default Teme;
