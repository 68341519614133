import React from 'react';
import { useState, useEffect } from 'react';

import { BrowserRouter, Route } from 'react-router-dom';
import { TextField, Checkbox, Typography, Button, Box, Card, Grid, Snackbar, Alert } from '@mui/material';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CloseIcon from '@mui/icons-material/Close';

function PotvrdniOkvir({ answerChange, idPitanja, tokenizirano }) {
    const [id, setID] = useState(idPitanja);

    const [list, setList] = useState<any[]>(["Prvi odgovor"]);

    const handleAdd = () => {
        const items = list;
        setList([...items, `Opcija odgovora`]);
    };

    function handleRemove(selectedItem) {
        const items = list;
        if (items.length > 0) {
            const lastIndex = selectedItem;
            setList(items.filter((item, index) => index !== lastIndex));
        }
    };
    function handleAnswerChange(value, index) {
        // get item by index 
        // update value
        const newdata = [...list]
        newdata[index] = value

        setList(newdata)
        answerChange(newdata, id);

    }

    useEffect(() => {

        answerChange(list, id);
    }, [list]);

    return (
        <div className="wrapper">

            <Grid
                container
                direction="column"
            >
                {list.map((item, index) => (

                    <Grid item >
                        <Grid container
                            direction="row"
                            alignItems="center">
                            <Grid item >
                                <Checkbox />
                            </Grid>
                            <Grid item xs={7} sx={{ p: 2 }} >
                                <TextField id="standard-basic" value={item} fullWidth onChange={(event) => handleAnswerChange(event.target.value, index)} variant="standard" />
                            </Grid>

                            {tokenizirano == "tokenizirana" ? <>

                                <Grid item sx={{ pl: 2 }} >
                                    <Typography variant='body2'> Broj tokena: </Typography >
                                </Grid>

                                <Grid item xs={2} sx={{ pl: 2, pr: 2 }} >
                                    <TextField id="standard-basic" type="number" value={item.vrijednost_odgovora} onChange={(event) => handleAnswerChange(event.target.value, index)} variant="standard" />

                                </Grid>
                            </ >
                                : <></>}




                            <Grid item alignContent="right" >
                                <CloseIcon onClick={() => handleRemove(index)} />
                            </Grid>
                        </Grid>
                    </Grid>
                ))}

                <Grid item >
                    <Grid container
                        direction="row"
                        alignItems="center">

                        <Grid item >
                            <Checkbox />
                        </Grid>
                        <Grid item xs={3} sx={{ p: 2 }} onClick={() => handleAdd()}>
                            <TextField id="standard-basic" defaultValue="Dodaj opciju" disabled fullWidth variant="standard" />
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
}

export default PotvrdniOkvir;