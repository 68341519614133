import React from 'react';
import { Container } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import UrediAnketuForm from 'componenets/Elements/Forms/NoviKorisnikForm/UrediAnketuForm';
import { useLocation } from 'react-router-dom';

type LocationState = { anketaID: string, anketaNaslov: any};


function UrediAnketu() {


const location = useLocation();

  const { anketaID, anketaNaslov} = location.state as LocationState;


  return (
    <div className="wrapper">
      <Container maxWidth="lg">

        {/* <h2>Uredi anketu</h2> */}
       <UrediAnketuForm idankete={anketaID} />
     
      </Container>

      
    </div>
  );
}

export default UrediAnketu;