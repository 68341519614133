import React, { useState, createContext } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./domain/router";
import logo from "./logo.svg";
import "./App.css";

export const UserContext = createContext({
  user: localStorage.getItem("token"),
  setUser: (updatedUser) => {},
});

function App() {
  const [user, setUser] = useState(localStorage.getItem("token"));
  function setUserState(updatedUser) {
    setUser(updatedUser);
  }

  return (
    <BrowserRouter>
      <UserContext.Provider value={{ user: user, setUser: setUserState }}>
        <Router />
      </UserContext.Provider>
    </BrowserRouter>
  );
}

export default App;
