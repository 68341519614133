import { Breadcrumbs, Container } from '@mui/material';
import UlogeTable from 'componenets/Elements/Tables/UlogeTable';


function UpravljanjeUlogama() {
  return (
    <div className="wrapper">
      <Container maxWidth="lg">

        <h2>Upravljanje Ulogama</h2>
        <Breadcrumbs/>
        
        <UlogeTable/>

      </Container>
    </div>
  );
}

export default UpravljanjeUlogama;