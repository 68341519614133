import { Breadcrumbs, Container, Grid, Box, Link } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useUser } from "auth/useUser";

function Administracija() {
  const user = useUser();

  return (
    <div className="wrapper">
      <Container maxWidth="lg">
        <h2 style={{ fontSize: 40 }}>Administracija</h2>
        <Breadcrumbs />

        <Divider />

        <Box sx={{ flexGrow: 1 }} style={{ marginTop: 40 }}>
          <Grid container spacing={3}>
            <Grid item xs={11}>
              <Link
                href="/SifarnikAnketa"
                fontWeight={300}
                color="black"
                underline="hover"
                sx={{ fontSize: 20 }}
              >
                Šifarnik anketa i glasovanja
              </Link>
            </Grid>

            <Grid item xs={11}>
              <Link
                href="/SifarnikLokacije"
                fontWeight={300}
                color="black"
                underline="hover"
                sx={{ fontSize: 20 }}
              >
                Šifarnik lokacija
              </Link>
            </Grid>

            <Grid item xs={11}>
              <Link
                href="/SifarnikObrazovanja"
                fontWeight={300}
                color="black"
                underline="hover"
                sx={{ fontSize: 20 }}
              >
                Šifarnik obrazovanja
              </Link>
            </Grid>

            <Grid item xs={11}>
              <Link
                href="/SifarnikZanimanja"
                fontWeight={300}
                color="black"
                underline="hover"
                sx={{ fontSize: 20 }}
              >
                Šifarnik zanimanja
              </Link>
            </Grid>

            <Grid item xs={11}>
              <Link
                href="/Korisnici"
                fontWeight={300}
                color="black"
                underline="hover"
                sx={{ fontSize: 20 }}
              >
                Korisnici
              </Link>
            </Grid>
            <Grid item xs={11}>
              <Link
                href="/Uloge"
                fontWeight={300}
                color="black"
                underline="hover"
                sx={{ fontSize: 20 }}
              >
                Upravljanje ulogama
              </Link>
            </Grid>

            {user.adminWrite && (
              <>
                <Grid item xs={11}>
                  <Link
                    href="/Obavijesti"
                    fontWeight={300}
                    color="black"
                    underline="hover"
                    sx={{ fontSize: 20 }}
                  >
                    Sučelje za Obavijesti
                  </Link>
                </Grid>
                <Grid item xs={11}>
                  <Link
                    href="/KonfiguracijaMaila"
                    fontWeight={300}
                    color="black"
                    underline="hover"
                    sx={{ fontSize: 20 }}
                  >
                    Konfiguracija maila za slanje obavijesti
                  </Link>
                </Grid>
              </>
            )}

            <Grid item xs={11}>
              <Link
                href="/OdobravanjePredstavnika"
                fontWeight={300}
                color="black"
                underline="hover"
                sx={{ fontSize: 20 }}
              >
                Sučelje za odobravanje predstavnika
              </Link>
            </Grid>
            <Grid item xs={11}>
              <Link
                href="/BlockchainVerifikacija"
                fontWeight={300}
                color="black"
                underline="hover"
                sx={{ fontSize: 20 }}
              >
                Blockchain verifikacija
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default Administracija;
