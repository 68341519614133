import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import * as myConstClass from "Constants";
import {
  CardActions,
  CardContent,
  Typography,
  Button,
  Box,
  Card,
  Grid,
  Snackbar,
  Alert,
  Container,
} from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

function DynamicChart({ tema_id, update, updateRecal }) {
  const [resultData, setResultData] = useState<any>([]);

  const data = {
    labels: ["ZA", "PROTIV", "SUZDRŽAN"],
    datasets: [
      {
        label: "# of Votes",
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: ["rgba(255, 255, 255, 1)"],
        data: resultData,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
              padding: 20,
            },
          },
        },
      },
    ],
  };

  useEffect(() => {
    fetch(myConstClass.SERVER_URL + "results?tema_id=" + tema_id)
      .then((response) => response.json())
      .then((dataResults) => {
        let za = 0;
        let protiv = 0;
        let suzdrzan = 0;

        dataResults.map((glas) => {
          if (glas.odgovor.trim() == "1") {
            za += 1;
          }
          if (glas.odgovor.trim() == "2") {
            protiv += 1;
          }
          if (glas.odgovor.trim() == "3") {
            suzdrzan += 1;
          }
        });

        setResultData([za, protiv, suzdrzan]);
      });
  }, [update, updateRecal]);

  return (
    <>
      {resultData[0] == 0 && resultData[1] == 0 && resultData[2] == 0 ? (
        <>
          <Typography variant="body1" sx={{mt: 10}}>Tema nema dovoljno odgovora za prikaz rezultata.</Typography>
        </>
      ) : (
        <Box>
          <Typography variant="body2">Rezultati</Typography>

          <Pie data={data} />
        </Box>
      )}
    </>
  );
}

export default DynamicChart;
