import React from "react";
import { useEffect, useState, forwardRef } from "react";

import { BrowserRouter, Route } from "react-router-dom";
import {
  Breadcrumbs,
  Container,
  Grid,
  Box,
  Link,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import * as myConstClass from "Constants";
import { Snackbar, styled } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function KonfiguracijaMaila() {
  // Snack bar states
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("OK");
  const [severity, setSeverity] = useState("warning");

  function snackbarClose(event) {
    setSnackbar(false);
  }

  const [saveButton, setSaveButton] = useState(true);

  const [data, setData] = useState({
    host_server: "",
    port: "",
    secure: false,
    username: "",
    pass: "",
    from: "",
    to: "",
  });

  function handleChange(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  function handleChangeCheckBox(e) {
    const newdata = { ...data };
    newdata["secure"] = !data.secure;
    setData(newdata);
  }

  useEffect(() => {
    setSaveButton(true);
  }, [data]);

  function submit() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(myConstClass.SERVER_URL + "email/saveEmailData", requestOptions).then(
      (response) => {
        response.json();
        if (response.status == 200) {
          setSnackbarMessage("Podaci o konfiguraciji su spremljeni");
          setSeverity("success");
          setSnackbar(true);
        } else {
          setSnackbarMessage("Doslo je do greske");
          setSeverity("error");
          setSnackbar(true);
        }
      }
    );
  }
  // Posalji ove podatke na server i spremi u environment file
  function sendTest() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(myConstClass.SERVER_URL + "email/testEmail", requestOptions).then(
      (response) => {
        response.json();
        if (response.status == 200) {
          setSnackbarMessage("Konfiguracija je ispravna");
          setSeverity("success");
          setSnackbar(true);
          setSaveButton(false);
        } else {
          setSnackbarMessage(
            "Doslo je do greske, molim vas provjerite podatke"
          );
          setSeverity("error");
          setSnackbar(true);
        }
      }
    );
  }

  function sendTestEmail() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(myConstClass.SERVER_URL + "email/sendTestEmail", requestOptions).then(
      (response) => {
        response.json();
        if (response.status == 200) {
          setSnackbarMessage("Testni mail je poslan");
          setSeverity("success");
          setSnackbar(true);
        } else {
          setSnackbarMessage("Doslo je do greske");
          setSeverity("error");
          setSnackbar(true);
        }
      }
    );
  }
  // dodaj opciju za testiranje konfiguracije

  return (
    <div className="wrapper">
      <Snackbar
        // anchorOrigin={{vertical: 'center', horizontal: 'center'}}
        open={snackbar}
        autoHideDuration={3000}
        onClose={snackbarClose}

        // message = { <span> {snackbarMessage} </span>}
      >
        <Alert
          onClose={snackbarClose}
          severity={
            severity == "success"
              ? "success"
              : severity == "error"
              ? "error"
              : severity == "info"
              ? "info"
              : "warning"
          }
          sx={{ width: "100%" }}
        >
          <span> {snackbarMessage} </span>
        </Alert>
      </Snackbar>

      <Container maxWidth="lg">
        <h1>Konfiguracija maila za slanje obavijesti</h1>

        <Grid container justifyContent="space-around" spacing={2}>
          <Grid item xs={6} sx={{ mr: 3 }}>
            <TextField
              id="host_server"
              value={data.host_server}
              fullWidth
              label="Host server"
              variant="outlined"
              onChange={handleChange}
              sx={{ m: 1 }}
            />
            <br />
            <TextField
              id="port"
              value={data.port}
              label="Port"
              variant="outlined"
              onChange={handleChange}
              sx={{ m: 1 }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  id="secure"
                  value={data.secure}
                  onChange={handleChangeCheckBox}
                />
              }
              label="Secure"
              sx={{ m: 1 }}
            />

            <br />

            <TextField
              id="username"
              value={data.username}
              fullWidth
              label="Korisničko ime"
              variant="outlined"
              onChange={handleChange}
              sx={{ m: 1 }}
            />
            <br />

            <TextField
              id="pass"
              value={data.pass}
              fullWidth
              label="Lozinka"
              variant="outlined"
              onChange={handleChange}
              sx={{ m: 1 }}
            />

            <br />
            <TextField
              id="from"
              value={data.from}
              fullWidth
              label="Slanje od"
              variant="outlined"
              onChange={handleChange}
              sx={{ m: 1 }}
            />
            <br />

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Button variant="contained" onClick={sendTest} sx={{ m: 1 }}>
                  Provjeri konfiguraciju
                </Button>
              </Grid>
              <Grid item></Grid>
              <Button
                variant="contained"
                color="success"
                disabled={saveButton}
                onClick={submit}
                sx={{ m: 1 }}
              >
                Spremi promjene
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Box
              sx={{
                border: 1,
                borderRadius: "4px",
                borderColor: "rgba(0, 0, 0, 0.27)",
                padding: 3,
                pl: 4,
                pr: 4,
              }}
            >
              <h4>Pošalji testni mail</h4>

              <TextField
                id="to"
                value={data.to}
                label="Slanje od"
                variant="outlined"
                onChange={handleChange}
                fullWidth
                sx={{ m: 1 }}
              />
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="success"
                  onClick={sendTestEmail}
                >
                  Pošalji testni mail
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default KonfiguracijaMaila;
